import React from 'react'
import {Modal, Button} from 'semantic-ui-react'

export default function TimeoutModal ({showModal, handleContinue, handleLogout, remainingTime}){
  return (
        <Modal open={showModal} onClose={handleLogout}>
            <Modal.Header>
            Idle
            </Modal.Header>
            <Modal.Content>We are not detecting any activity from you, logging you out now</Modal.Content>
            <Modal.Actions >
            {/* <Button variant="danger" onClick={handleLogout}>
                Logout
            </Button> */}
            <Button secondary onClick={handleLogout}>
                OK
            </Button>
            </Modal.Actions>
        </Modal>
  )
}
