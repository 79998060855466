import { apiGatewayClientWithCredentials as getApiGatewayClient, apiGatewayClient } from 'services/api'

const url = ([first, ...rest], ...parts) =>
  first + parts.map((p, i) => encodeURIComponent(p) + rest[i]).join('')


const fetch = (method, target, params = {}) =>
  getApiGatewayClient()
    .then(apiGatewayClient => apiGatewayClient[method](target, {}, params, {}))
    .then(response => response.data)
    .catch(error => { throw error && (error.data || error) })

const fetchAnon = (method, target, params = {}) =>
  apiGatewayClient()
    .then(apiGatewayClient => apiGatewayClient[method](target, {}, params, {}))
    .then(response => response)
    .catch(error => { throw error })

    
export const getPagesConfig = (pagetype='custom') => fetch('get', url`/mdPages/config/${pagetype}`);
export const syncManagedPages = (reset=false) => fetch('get', url`/mdPages/sync/${reset}`)
export const getManagedPageByS3 = (pagename) => fetch('get', url`/mdPages/${pagename}/managed`)
export const getPageByS3 = (pagename) => fetch('get', url`/mdPages/${pagename}/custom`)
export const deletePage = (pagename) => fetch('delete', url`/mdPages/${pagename}`);


export const getPage = (pagename) => fetch('get', url`/mdPages/${pagename}`);
export const getPages = () => fetch('get', url`/mdPages/pages.json`);
export const getPageAnon = (pagename) => fetchAnon('get', url`/mdPages/${pagename}`);
export const getDevPageList = () => fetch('get', url`/mdPages/dev.json`);
export const getPageList = () => fetch('get', url`/mdPages`);
export const getPageListAnon = () => fetchAnon('get', url`/mdPages`);
