// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState, useEffect} from 'react'
import {
    useNavigate,
    useParams
  } from 'react-router-dom';

// semantic-ui
import { Loader, Card, List, CardHeader, CardContent } from 'semantic-ui-react'

// store
import { getApi } from 'services/api-catalog'

// utilities
import _ from 'lodash'
import { observer } from 'mobx-react';

//components

export const ApiJSON = observer(()=> {
    const { apiId, stage } = useParams();
    const navigate = useNavigate();
    const [swaggerObj, setSwaggerObj] = useState([]);
    useEffect(()=>{
        async function getAPI(){
            let api = await getApi(apiId || 'ANY', true, stage, true)
            setSwaggerObj(api)
        }
        getAPI();

    }, [])
    return (
        <>
            <div className='btn ml-5'>
                <button onClick={()=>navigate(-1)} className='float-left bg-gray-300 py-2 px-5 rounded-md'>Back</button>
            </div>
            <Card className='w-full mx-5'>
                <CardHeader>
                    {swaggerObj.swagger? swaggerObj.swagger.info.title: null}
                </CardHeader>
                <CardContent>
                    {JSON.stringify(swaggerObj, null, 2)}
                </CardContent>
            </Card>
        </>
    )
})

export default ApiJSON
