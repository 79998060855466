import React from 'react'

import { Button, Loader, Table, Modal, Form, Message, Popup, Icon, Segment, Dimmer, Input, Header } from 'semantic-ui-react'

import { apiGatewayClientWithCredentials } from 'services/api'
import { getApi, updateUsagePlansAndApisList } from 'services/api-catalog'
import { store } from 'services/state'

import * as YAML from 'js-yaml'

import hash from 'object-hash'
import { toJS, runInAction } from 'mobx'
import { observer } from 'mobx-react'

import { Link, useNavigate } from 'react-router-dom'
import MenuLink from 'components/MenuLink'
import SampleFlows from 'components/SampleFlows'
import InvalidationButton from 'components/InvalidationButton'
import _ from 'lodash'
import * as AuditService from 'services/auditing'
import * as ImageService from 'services/images'
import LoaderBar from 'components/Loader'



function getUsagePlanVisibility (usagePlan) {
  let hasHidden = false
  let hasVisible = false

  for (const api of usagePlan.apis) {
    if (api.visibility) {
      if (hasHidden) return null
      hasVisible = true
    } else {
      if (hasVisible) return null
      hasHidden = true
    }
  }

  return hasVisible
}

function getApisWithStages () {
  const apiList = [].concat(_.get(store, 'apiList.generic', []), _.get(store, 'apiList.apiGateway', [])).map(api => ({
    group: api.apiId || api.id,
    title: api.swagger.info.title,
    lastUpdatedSwagger: api.swagger.lastUpdatedSwagger
  }))

  return _.toPairs(_.groupBy(apiList, 'group'))
    .map(([group, apis]) => ({ group, apis, active: _.some(apis, 'active'), title: apis[0].title, lastUpdatedSwagger: apis[0].lastUpdatedSwagger}))
}

function removeFirst (array, item) {
  const index = array.indexOf(item)
  const result = array.slice()
  result.splice(index, 1)
  return result
}



const formatDate = isoDateString =>
new Intl.DateTimeFormat('en-SG', {
  dateStyle: 'medium',
  timeStyle: 'short'
}).format(new Date(isoDateString))

export const ApiManagement = observer(class ApiManagement extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      modalOpen: false,
      errors: [],
      // Simpler than implementing a multiset, and probably also faster.
      // TODO: abstract this out. It's getting a bit out of hand.
      plansDisplayToggling: [],
      apisDisplayToggling: [],
      apisUpdating: [],
      apisDeleting: [],
      apisTogglingSdks: [],
      updateModalOpen: false,
      deleteModalOpen: false,
      swaggers: getApisWithStages(),
      updating: false,
      adding: false,
      query: [],
    }

    this.fileInput = React.createRef()

    this.tableSort = (first, second) => {
      if (first.name !== second.name) {
        return first.name.localeCompare(second.name)
      } else {
        return first.stage.localeCompare(second.stage)
      }
    }

    this.genericTableSort = (firstIndex, secondIndex) => {
      const list = store.visibility.generic

      if (list[firstIndex].name !== list[secondIndex].name) {
        return list[firstIndex].name.localeCompare(list[secondIndex].name)
      } else {
        // compare by their index, which happens to be their id
        return firstIndex.localeCompare(secondIndex)
      }
    }

    this.usagePlanSort = (first, second) => {
      if (first.name !== second.name) {
        return first.name.localeCompare(second.name)
      } else {
        return first.id.localeCompare(second.id)
      }
    }
  }

  componentDidMount () {
    this.getApiVisibility()
  }

  uploadAPISpec (event) {
    event.preventDefault()
    this.setState(prev => ({...prev, adding: true, updateModalOpen: false}))

    const files = this.fileInput.current.files
    let swagger, swaggerObject, anyFailures

    if (files.length > 0) {
      this.setState(prev => ({ ...prev, errors: [] }))
      ;[].forEach.call(files, file => {
        const reader = new window.FileReader()

        reader.onload = (e) => {
          if (file.name.includes('yaml')) {
            swaggerObject = YAML.load(e.target.result)
            swagger = JSON.stringify(swaggerObject)
          } else {
            swaggerObject = JSON.parse(e.target.result)
            swagger = JSON.stringify(swaggerObject)
          }

          if (!(swaggerObject.info && swaggerObject.info.title)) {
            anyFailures = true
            this.setState(prev => ({ ...prev, errors: [...prev.errors, file.name], adding: false }))
            return
          }

          if (anyFailures) {
            return
          }

          apiGatewayClientWithCredentials()
            .then((app) => app.post('/admin/catalog/visibility', {}, { swagger }, {}))
            .then((res) => {
              if (res.status === 200) {
                this.getApiVisibility()
                this.setState(prev => ({ ...prev, adding: false, errors: anyFailures ? prev.errors : [] }))
                updateUsagePlansAndApisList(true)
              }
            })
            try {
              AuditService.createAuditLog(`Uploaded Swagger: ${swaggerObject.info.title}`)
            } catch (error) {
                console.error(error);
            }
        }
        reader.readAsText(file)
      })
    }
  }
  updateAPISpec (event) {
    event.preventDefault()
    this.setState(prev => ({ ...prev, updating: true, modalOpen: false}))

    const files = this.fileInput.current.files
    const apiId = event.target.apiID.value
    let swagger, swaggerObject, anyFailures
    
    if (files.length > 0) {
      this.setState(prev => ({ ...prev, errors: [] }))
      ;[].forEach.call(files, file => {
        const reader = new window.FileReader()
        
        reader.onload = (e) => {
          if (file.name.includes('yaml')) {
            swaggerObject = YAML.load(e.target.result)
            swagger = JSON.stringify(swaggerObject)
          } else {
            swaggerObject = JSON.parse(e.target.result)
            swagger = JSON.stringify(swaggerObject)
          }
          
          if (!(swaggerObject.info && swaggerObject.info.title)) {
            anyFailures = true
            this.setState(prev => ({ ...prev, errors: [...prev.errors, file.name], updating: false }))
            return
          }
          
          if (anyFailures) {
            return
          }
          
          // console.log(apiId)
          getApi(apiId || 'ANY', true, null, true)
          .then(async(api) => {
            api.swagger = swaggerObject//set swagger object
            apiGatewayClientWithCredentials()
            .then((app) => app.post('/admin/catalog/updateGenericApi', {}, { id: apiId, swagger:JSON.stringify(api.swagger), fullfile: JSON.stringify(api) }, {}))
            .then((res) => {
              if (res.status === 200) {
                this.setState(prev => ({ ...prev, modalOpen: Boolean(anyFailures), errors: anyFailures ? prev.errors : [], updating: false }))
                updateUsagePlansAndApisList(true)
                try {
                  AuditService.createAuditLog(`Updated Swagger: ${swaggerObject.info.title}`)
                  this.setState(prev=> ({...prev, swaggers: getApisWithStages()}))
                } catch (error) {
                    console.error(error);
                }
              }
            })
          })
        }
        reader.readAsText(file)
      })
    }
  }

  deleteAPISpec (apiId) {
    this.setState(({ apisDeleting }) => ({ apisDeleting: [...apisDeleting, apiId] }))
    getApi(apiId, false, undefined, true).then(api => {
      const _api = toJS(api)
      const key = _api.stage ? `${_api.id}_${_api.stage}` : hash(_api.swagger)

      apiGatewayClientWithCredentials()
        .then(app => app.delete(`/admin/catalog/visibility/generic/${key}`, {}, {}, {}))
        .then((res) => {
          if (res.status === 200) this.getApiVisibility(); updateUsagePlansAndApisList(true)
        })
        .then(() => this.setState(({ apisDeleting }) => ({ apisDeleting: removeFirst(apisDeleting, apiId) })))
        try {
          AuditService.createAuditLog(`Deleted Swagger: ${_api.swagger.info.title}`)
        } catch (error) {
            console.error(error);
        }
    })
  }

  getApiVisibility () {
    // console.log("called get visibility");
    apiGatewayClientWithCredentials()
      .then(app => app.get('/admin/catalog/visibility', {}, {}, {}))
      .then(res => {
        if (res.status === 200) {
          // console.log(`visibility: ${JSON.stringify(res.data, null, 2)}`)
          //console.log(res)
          const apiGateway = res.data.apiGateway
          const generic = res.data.generic && Object.keys(res.data.generic)

          // console.log(`generic: ${JSON.stringify(generic, null, 2)}`)
          // console.log(`api gateway: ${JSON.stringify(apiGateway, null, 2)}`)

          apiGateway.forEach(api => {
            if (generic) {
              generic.forEach(genApi => {
                if (res.data.generic[`${genApi}`]) {
                  if (
                    res.data.generic[`${genApi}`].apiId === api.id &&
                    res.data.generic[`${genApi}`].stage === api.stage
                  ) {
                    api.visibility = true
                    delete res.data.generic[`${genApi}`]
                  }
                }
              })
            }
          })
          runInAction(() => {
            store.visibility = res.data
            // console.log(store.visibility);
          })
        }
      })
  }

  updateLocalApiGatewayApis (apisList, updatedApi, parity) {
    const updatedApis = apisList.map(stateApi => {
      if (stateApi.id === updatedApi.id && stateApi.stage === updatedApi.stage) {
        if (parity !== undefined && (parity === true || parity === false)) {
          stateApi.visibility = parity
        } else {
          stateApi.visibility = !stateApi.visibility
        }
      }
      return stateApi
    })

    store.visibility = { generic: store.visibility.generic, apiGateway: updatedApis }
  }

  showApiGatewayApi (api) {
    const apiId = api.stage ? `${api.id}_${api.stage}` : api.id
    this.setState(({ apisDisplayToggling }) => ({ apisDisplayToggling: [...apisDisplayToggling, apiId] }))
    apiGatewayClientWithCredentials()
      .then(app => app.post('/admin/catalog/visibility', {}, { apiKey: `${api.id}_${api.stage}`, subscribable: `${api.subscribable}` }, {}))
      .then((res) => {
        this.setState(({ apisDisplayToggling }) => ({ apisDisplayToggling: removeFirst(apisDisplayToggling, apiId) }))
        if (res.status === 200) {
          this.updateLocalApiGatewayApis(store.visibility.apiGateway, api)
        }
      })
  }

  hideApiGatewayApi (api) {
    if (!api.subscribable && !api.id && !api.stage) {
      this.deleteAPISpec(api.genericId)
    } else {
      const apiId = api.stage ? `${api.id}_${api.stage}` : api.id
      this.setState(({ apisDisplayToggling }) => ({ apisDisplayToggling: [...apisDisplayToggling, apiId] }))
      apiGatewayClientWithCredentials()
        .then(app => app.delete(`/admin/catalog/visibility/${api.id}_${api.stage}`, {}, {}, {}))
        .then((res) => {
          this.setState(({ apisDisplayToggling }) => ({ apisDisplayToggling: removeFirst(apisDisplayToggling, apiId) }))
          if (res.status === 200) {
            this.updateLocalApiGatewayApis(store.visibility.apiGateway, api)
          }
        })
    }
  }

  showAllApiGatewayApis (usagePlan) {
    // Only toggle APIs that aren't already shown.
    const apiIds = usagePlan.apis.filter(api => !api.visibility).map(api => `${api.id}_${api.stage}`)
    this.setState(({ plansDisplayToggling, apisDisplayToggling }) => ({
      plansDisplayToggling: [...plansDisplayToggling, usagePlan.id],
      apisDisplayToggling: [...apisDisplayToggling, ...apiIds]
    }))
    Promise.all(usagePlan.apis.map((api) =>
      apiGatewayClientWithCredentials()
        .then(app => app.post('/admin/catalog/visibility', {}, {
          apiKey: `${api.id}_${api.stage}`,
          subscribable: `${api.subscribable}`
        }, {}))
        .then(res => { res.api = api; return res })
    )).then((promises) => {
      this.setState(({ plansDisplayToggling, apisDisplayToggling }) => ({
        plansDisplayToggling: removeFirst(plansDisplayToggling, usagePlan.id),
        apisDisplayToggling: apiIds.reduce(removeFirst, apisDisplayToggling)
      }))
      promises.forEach((result) => {
        if (result.status === 200) {
          this.updateLocalApiGatewayApis(store.visibility.apiGateway, result.api, true)
        }
      })
    })
  }

  hideAllApiGatewayApis (usagePlan) {
    // Only toggle APIs that aren't already hidden.
    const apiIds = usagePlan.apis.filter(api => api.visibility).map(api => `${api.id}_${api.stage}`)
    this.setState(({ plansDisplayToggling, apisDisplayToggling }) => ({
      plansDisplayToggling: [...plansDisplayToggling, usagePlan.id],
      apisDisplayToggling: [...apisDisplayToggling, ...apiIds]
    }))
    Promise.all(usagePlan.apis.map((api) =>
      apiGatewayClientWithCredentials()
        .then(app => app.delete(`/admin/catalog/visibility/${api.id}_${api.stage}`, {}, {}, {}))
        .then(res => { res.api = api; return res })
    )).then((promises) => {
      this.setState(({ plansDisplayToggling, apisDisplayToggling }) => ({
        plansDisplayToggling: removeFirst(plansDisplayToggling, usagePlan.id),
        apisDisplayToggling: apiIds.reduce(removeFirst, apisDisplayToggling)
      }))
      promises.forEach((result) => {
        if (result.status === 200) {
          this.updateLocalApiGatewayApis(store.visibility.apiGateway, result.api, false)
        }
      })
    })
  }

  isTogglingPlanDisplay (usagePlan) {
    return this.state.plansDisplayToggling.includes(usagePlan.id)
  }

  isTogglingApiDisplay (api) {
    return this.state.apisDisplayToggling.includes(api.stage ? `${api.id}_${api.stage}` : api.id)
  }

  isUpdatingApiGatewayApi (api) {
    return this.state.apisUpdating.includes(`${api.id}_${api.stage}`)
  }

  isRemovingUnmanagedApi (apiId) {
    return this.state.apisDeleting.includes(apiId)
  }

  updateApiGatewayApi (api) {
    this.setState(({ apisUpdating }) => ({
      apisUpdating: [...apisUpdating, `${api.id}_${api.stage}`]
    }))
    apiGatewayClientWithCredentials()
      .then(app => app.post('/admin/catalog/visibility', {}, { apiKey: `${api.id}_${api.stage}`, subscribable: `${api.subscribable}` }, {}))
      .then(() => this.setState(({ apisUpdating }) => ({ apisUpdating: removeFirst(apisUpdating, `${api.id}_${api.stage}`) })))
  }

  isSdkGenerationConfigurable (api) {
    return api.visibility
  }

  isTogglingSdkGeneration (api) {
    return this.state.apisTogglingSdks.includes(`${api.id}_${api.stage}`)
  }

  toggleSdkGeneration (apisList, updatedApi) {
    this.setState(({ apisTogglingSdks }) => ({
      apisTogglingSdks: [...apisTogglingSdks, `${updatedApi.id}_${updatedApi.stage}`]
    }))
    apiGatewayClientWithCredentials()
      .then(app => {
        if (updatedApi.sdkGeneration) {
          return app.delete(`/admin/catalog/${updatedApi.id}_${updatedApi.stage}/sdkGeneration`, {}, {}, {})
        } else {
          return app.put(`/admin/catalog/${updatedApi.id}_${updatedApi.stage}/sdkGeneration`, {}, {}, {})
        }
      })
      .then(res => {
        this.setState(({ apisTogglingSdks }) => ({ apisTogglingSdks: removeFirst(apisTogglingSdks, `${updatedApi.id}_${updatedApi.stage}`) }))
        if (res.status === 200) {
          const updatedApis = apisList.map(stateApi => {
            if (stateApi.id === updatedApi.id && stateApi.stage === updatedApi.stage) {
              stateApi.sdkGeneration = !stateApi.sdkGeneration
            }
            return stateApi
          })

          store.visibility.apiGateway = updatedApis
        }
      })
  }

  renderHeaderVisibilityButton (usagePlan) {
    const usagePlanVisibility = getUsagePlanVisibility(usagePlan)

    // Some APIs are visible, some are hidden. Show the current state (Partial, with a warning) and enable all on click
    if (usagePlanVisibility == null) {
      return (
        <Popup
          content='Users subscribed to any of the APIs in this usage plan will have a valid API key for all APIs in this usage plan, even those that are not visible!'
          trigger={
            <Button
              basic
              color='yellow'
              style={{ backgroundColor: 'white', width: '100%', paddingLeft: '1em', paddingRight: '1em', minWidth: '88px' }}
              onClick={() => this.showAllApiGatewayApis(usagePlan)}
            >
              {this.isTogglingPlanDisplay(usagePlan) ? <Loader active inline size='mini' /> : <>Partial <Icon name='warning sign' style={{ paddingLeft: '5px' }} /></>}
            </Button>
          }
        />
      )
    }

    // Either all APIs are visible or none are visible. Toggle this state on click.
    return (
      <Button
        basic
        color={usagePlanVisibility ? 'green' : 'red'}
        style={{ backgroundColor: 'white', width: '100%' }}
        onClick={() => {
          if (usagePlanVisibility) this.hideAllApiGatewayApis(usagePlan)
          else this.showAllApiGatewayApis(usagePlan)
        }}
      >
        {this.isTogglingPlanDisplay(usagePlan) ? <Loader active inline size='mini' /> : usagePlanVisibility ? 'True' : 'False'}
      </Button>
    )
  }

  sortByUsagePlan () {
    if (!store.visibility.apiGateway) { return this.renderNoApis() }

    const usagePlans =
      store.visibility.apiGateway
        .filter((api) => api.usagePlanId)
        .reduce((accumulator, api) => {
          if (!accumulator.find((usagePlan) => api.usagePlanId === usagePlan.id)) {
            accumulator.push({ id: api.usagePlanId, name: api.usagePlanName })
          }
          return accumulator
        }, [])
        .sort(this.usagePlanSort)
        .map((usagePlan) => {
          return { ...usagePlan, apis: store.visibility.apiGateway.filter((api) => api.usagePlanId === usagePlan.id).sort(this.tableSort) }
        })
    const unsubscribable =
      store.visibility.apiGateway
        .filter((api) => !api.usagePlanId)
        .sort(this.tableSort)

    return (
      <>
        {usagePlans.map(usagePlan => {
          return (
            <>
              {this.renderHeader(usagePlan)}
              {this.renderApiList(usagePlan.apis)}
            </>
          )
        })}
        <Table.Row style={{ backgroundColor: '#1678c2', color: 'white' }}>
          <Table.Cell colSpan='6'>
            <b>Not Subscribable</b> <i>No Usage Plan</i>
          </Table.Cell>
        </Table.Row>
        {this.renderApiList(unsubscribable)}
      </>
    )
  }

  renderNoApis () {
    return (
      <Table.Row>
        <Table.Cell colSpan='4'>
          No APIs found
        </Table.Cell>
      </Table.Row>
    )
  }

  renderHeader (usagePlan) {
    return (
      <Table.Row key={usagePlan.id} style={{ backgroundColor: '#1678c2', color: 'white' }}>
        <Table.Cell colSpan='3'>
          <b>{usagePlan && usagePlan.name}</b> <i>Usage Plan</i>
        </Table.Cell>
        <Table.Cell>
          {this.renderHeaderVisibilityButton(usagePlan)}
        </Table.Cell>
        <Table.Cell colSpan='2'>
          {/* Intentionally empty */}
        </Table.Cell>
      </Table.Row>
    )
  }

  renderApiList (apis) {
    return <>
      {apis.filter(api => api.id !== window.config.restApiId).map(api => (
        <React.Fragment key={api.stage ? `${api.id}_${api.stage}` : api.id}>
          <Table.Row>
            <Table.Cell collapsing>{api.name}</Table.Cell>
            <Table.Cell>{api.stage}</Table.Cell>
            <Table.Cell>{api.subscribable ? 'Subscribable' : 'Not Subscribable'}</Table.Cell>
            <Table.Cell>
              <Button
                basic
                color={api.visibility ? 'green' : 'red'}
                style={{ width: '100%' }}
                onClick={() => api.visibility ? this.hideApiGatewayApi(api) : this.showApiGatewayApi(api)}
              >
                {this.isTogglingApiDisplay(api) ? <Loader active inline size='mini' /> : api.visibility ? 'True' : 'False'}
              </Button>
            </Table.Cell>
            <Table.Cell>
              <Button
                basic
                color='blue'
                disabled={!api.visibility}
                style={{ width: '100%' }}
                onClick={() => this.updateApiGatewayApi(api)}
              >
                {this.isUpdatingApiGatewayApi(api) ? <Loader active inline size='mini' /> : 'Update'}
              </Button>
            </Table.Cell>
            <Table.Cell>
              <Button
                basic
                // color={api.sdkGeneration ? 'green' : 'red'}
                color='blue'
                style={{ width: '100%' }}
                disabled={!api.visibility || !this.isSdkGenerationConfigurable(api)}
                onClick={() => this.toggleSdkGeneration(store.visibility.apiGateway, api)}
              >
                {this.isTogglingSdkGeneration(api) ? <Loader active inline size='mini' /> : api.sdkGeneration ? 'Enabled' : 'Disabled'}
              </Button>
            </Table.Cell>
          </Table.Row>
        </React.Fragment>
      ))}
    </>
  }

  queryApiList(query) {
    return query ? 
    Object.keys(store.visibility.generic).filter(apiId => store.visibility.generic[apiId].name.includes(query)) :
    Object.keys(store.visibility.generic)
  }

  render () {
    return <>
      <Dimmer active={this.state.adding}>
        {/* <Loader>Adding API... DO NOT CLOSE THIS PAGE</Loader> */}
        <LoaderBar active={this.state.adding} message={"Adding API... DO NOT CLOSE THIS PAGE"} value='1'/>
      </Dimmer>
      <Dimmer active={this.state.updating}>
        {/* <Loader>Updating API... DO NOT CLOSE THIS PAGE</Loader> */}
        <LoaderBar active={this.state.updating} message={"Updating API... DO NOT CLOSE THIS PAGE"} value='1'/>
      </Dimmer>
      <div className='gap-4 place-items-center'>
        <div className='p-8 2xl:px-20'>
          <Header as='h1'>APIs</Header>
          <div className='mb-4 flex space-x-4'>
            <Input icon='search' placeholder='Search...' iconPosition='left' onChange={(e) => this.setState(prev => ({...prev, query:e.target.value}))}/>
            <div className="grow">
                
            </div>
            <InvalidationButton/>
            <Button as={Link} to='/admin/images' basic color='blue' floated='right'>Sample Flows</Button>
            <Modal
              closeIcon
              closeOnEscape
              closeOnDimmerClick
              onClose={() => this.setState((prev) => ({ ...prev, updateModalOpen: false }))}
              trigger={
                <Button basic color='blue' floated='right' onClick={() => this.setState((prev) => ({ ...prev, updateModalOpen: true }))}>
                  Add API
                </Button>
              }
              open={this.state.updateModalOpen}
            >
              <Modal.Header>Select .JSON, .YAML, or .YML files</Modal.Header>
              <Modal.Content>
                <>
                  <Form onSubmit={(e) => this.uploadAPISpec(e)}>
                    <Form.Field>
                      <label htmlFor='files'>Select Files:</label>
                      <input type='file' id='files' name='files' accept='.json,.yaml,.yml' multiple ref={this.fileInput} />
                    </Form.Field>
                    {!!this.state.errors.length &&
                      <Message size='tiny' color='red' list={this.state.errors} header='These files are not parseable or do not contain an api title:' />}
                    <br />
                    <Button type='submit'>Upload</Button>
                  </Form>
                </>
              </Modal.Content>
            </Modal>          
          </div>
          <Table celled collapsing className='w-full'>
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell sorted='ascending'>API Name</Table.HeaderCell>
                <Table.HeaderCell collapsing>Last Updated</Table.HeaderCell>
                <Table.HeaderCell collapsing>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {store.visibility.generic
                ? this.queryApiList(this.state.query).sort(this.genericTableSort).map(apiId => (
                  <Table.Row key={apiId}>
                    <Table.Cell >{store.visibility.generic[apiId].name}</Table.Cell>
                    <Table.Cell collapsing>{this.state.swaggers.filter(x=>x.group===apiId).map(x=> x.lastUpdatedSwagger?formatDate(x.lastUpdatedSwagger): '-')}</Table.Cell>
                    <Table.Cell collapsing>
                      <Button.Group fluid>
                        <Button color='green' as={Link} to={'/admin/updateapi/'+apiId} className=''>Edit Description</Button>
                        <Button color='yellow' as={Link} to={'/admin/editaccess/'+apiId} className=''>API Access Level</Button>
                        {/* <MenuLink to={'/admin/updateapi/'+apiId} className='border-green-500 border-2 p-2.5 rounded-md text-green-500 mr-1'>View JSON</MenuLink> */}
                        {/* <MenuLink to={'/admin/editaccess/'+apiId} className='border-amber-500 border-2 p-2.5 rounded-md text-amber-500 mr-1'>Edit Access</MenuLink> */}
                        <Modal
                          closeIcon
                          closeOnEscape
                          closeOnDimmerClick
                          onClose={() => this.setState((prev) => ({ ...prev, modalOpen: false }))}
                          trigger={
                            <Button color='blue' onClick={() => this.setState((prev) => ({ ...prev, modalOpen: apiId, apiId }))} className=''>
                              Update
                            </Button>
                          }
                          open={this.state.modalOpen === apiId}
                          id={apiId}
                          >
                          <Modal.Header>{store.visibility.generic[apiId].name}<br/><br/>Select .JSON, .YAML, or .YML files</Modal.Header>
                          <Modal.Content>
                            <>
                              <Form onSubmit={(e) => this.updateAPISpec(e)}>
                                <Form.Field>
                                  <label htmlFor='files'>Select Files:</label>
                                  <input type='file' id='files' name='files' accept='.json,.yaml,.yml' multiple ref={this.fileInput} />
                                  <input type='hidden' value={this.state.apiId} name='apiID'></input>
                                </Form.Field>
                                {!!this.state.errors.length &&
                                  <Message size='tiny' color='red' list={this.state.errors} header='These files are not parseable or do not contain an api title:' />}
                                <br />
                                <Button type='submit'>Update</Button>
                              </Form>
                            </>
                          </Modal.Content>
                        </Modal>
                        <Modal
                          closeIcon
                          closeOnEscape
                          closeOnDimmerClick
                          onClose={() => this.setState((prev) => ({ ...prev, deleteModalOpen: false }))}
                          trigger={
                            <Button color='red' floated='right' onClick={() => this.setState((prev) => ({ ...prev, deleteModalOpen: apiId, apiId }))} className=''>
                              Delete
                            </Button>
                          }
                          open={this.state.deleteModalOpen === apiId}
                          id={apiId}
                        >
                          <Modal.Header>Delete API: {store.visibility.generic[apiId].name}</Modal.Header>
                          <Modal.Content>
                            <>
                              <Form onSubmit={(e) => this.deleteAPISpec(apiId)}>
                                <p><strong>Are you sure you want to delete ?</strong></p>
                                <Button type='button' onClick={()=>{this.setState((prev)=>({...prev, deleteModalOpen: false}))}}>Cancel</Button>
                                <Button type='submit' color='red'>Delete</Button>
                              </Form>
                            </>
                          </Modal.Content>
                        </Modal>
                        {/* <Button
                          className='md:block hidden'
                          color='red'
                          disabled={this.isRemovingUnmanagedApi(apiId)}
                          onClick={() => this.deleteAPISpec(apiId)}
                          >
                          {this.isRemovingUnmanagedApi(apiId) ? <Loader active inline size='mini' /> : 'Delete'}
                        </Button> */}
                      </Button.Group>
                    </Table.Cell>
                  </Table.Row>
                ))
                : (
                  <Table.Row>
                    <Table.Cell colSpan='4'>
                      No APIs found
                    </Table.Cell>
                  </Table.Row>
                )}
            </Table.Body>
          </Table>
        </div>
      </div>
    </>
  }
})
