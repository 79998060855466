// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState } from 'react'

// swagger-ui
import "swagger-ui-react/swagger-ui.css";

// semantic-ui
import { Message } from 'semantic-ui-react'

// services
import { getPasswordRequirements, verifyPassword } from 'services/self'

export default function PasswordRequirements({password}) {
    const [result, errRequirements] = verifyPassword(password)
    return (
        <Message error={!result} positive={result} visible>
            Password must contain the following:
            <Message.List className='list-image-[url(checkmark-32.png)]'>
                { errRequirements && errRequirements.map( requirement =>
                    requirement.valid ?
                    <Message.Item className="text-green-500" content={requirement.name}/> :
                    <Message.Item content={requirement.name}/> 
                )}
            </Message.List>
        </Message>
    )
}