import React, {useState, useEffect, useRef} from 'react'

//react utils
import { useParams, useNavigate, Link } from 'react-router-dom'
import { Card, Table, Button, Header, Dropdown, Form, Segment, Message, Popup, Modal, Checkbox, Input, Placeholder, Label, Divider } from 'semantic-ui-react'

//components
import InvalidationButton from 'components/InvalidationButton'
import MarkdownEditor from 'components/MarkdownEditor'
import * as PagesService from 'services/pages'
import { apiGatewayClientWithCredentials } from 'services/api'

import _ from 'lodash'
import * as AuditService from 'services/auditing'
import {reloadPagesConfig} from 'services/get-fragments'

const formatDate = isoDateString => {
    try {
        const date = new Date(isoDateString)
        return new Intl.DateTimeFormat('en-SG', {
            dateStyle: 'medium',
            timeStyle: 'short'
        }).format(date)
    } catch {
        return isoDateString
    }
}

export const Pages = () => {
    const ref = useRef(null)

    const [pages, setPages] = useState([]) // Array of PageConfig
    const [devpages, setDevPages] = useState([]) // Array of Managed PageConfig

    const [queriedPages, setQueriedPages] = useState([]) // Array of PageConfig based on search

    const [updatedPages, setUpdatedPages] = useState([]) // Array of edited PageConfig
    const [selectedPage, setSelectedPage] = useState() // Currently selected PageConfig for editing
    const [loading, setLoading] = useState(false) // Loading Pages Table

    const [cachedContents, setCachedContents] = useState({}) // Array of original markdown content
    const [editedContent, setEditedContent] = useState('') // Edited markdown content

    const [uploadModalOpen, setUploadModalOpen] = useState(false)
    const [addModalOpen, setAddModalOpen] = useState(false)

    const [addErrors, setAddErrors] = useState([])
    const [uploadErrors, setUploadErrors] = useState([])
    const [message, setMessage] = useState('')

    const [query, setQuery] = useState('')

    const { pagetype } = useParams()
    const navigate = useNavigate();

    let pageMD = useRef(null)

    const getPagesConfig = () => {
        setLoading(true)
        PagesService.getPagesConfig(pagetype)
        .then(({ result }) => {
            if (pagetype === 'managed') {
                setDevPages([...result])
                setQueriedPages([...result])
            } else {
                setPages([...result])
                setUpdatedPages([...result])
            }
            setLoading(false)
            setSelectedPage(undefined)
            setMessage('')
            setCachedContents({})
        }).catch (error => {
            console.error(error)
        })
    }

    useEffect(()=>{
        if (pages.length == 0 && pagetype === 'custom') {
            getPagesConfig()
        } 
        if (devpages.length == 0 && pagetype === 'managed') {
            getPagesConfig()
        } 
        // setQueriedPages(pagetype === 'managed' ? devpages : q)
        setSelectedPage(undefined)
    }, [pagetype])

    const ChangesWarning = () => {
        let x = (selectedPage ? !_.isEqual(editedContent, cachedContents[selectedPage.PageURL]): false) || !_.isEqual(pages, updatedPages)
        return <Message warning hidden={!x} className='m-0 px-6 py-[11px] text-base leading-4' size='mini'>There are pending changes. Please save them! Your changes will not be saved otherwise.</Message>
    }
    
    const ToggleButton = ({ page, label }) => {
        const key = label.toLowerCase()
        const onClickHandler = () => {
            const updatedPos = {...page.Position}
            updatedPos[key] = !page.Position[key]
            const updatedPage = {...page, Position: updatedPos}
            setUpdatedPages(updatedPages.map(p => {
                if (p.PageURL === updatedPage.PageURL) {
                  return updatedPage;
                } else {
                  return p;
                }
            }))
        }
        if (!page.Visible) {
            return (
                <>
                    <Checkbox toggle disabled checked={false} className='md:block hidden'></Checkbox>
                    <Checkbox disabled checked={false} className='md:hidden block ml-3'></Checkbox>
                </>
            )
        }
        return (
            <>
            <Checkbox toggle onChange={onClickHandler} checked={page.Position[key]} className='md:block hidden'/>
            <Checkbox onChange={onClickHandler} checked={page.Position[key]} className='  md:hidden block ml-3'/>
            </>
        )
    }

    const VisiblityToggleButton = ({ page }) => {
        const onClickHandler = () => {
            const updatedPage = {...page, Visible: !page.Visible}
            setUpdatedPages(updatedPages.map(p => {
                if (p.PageURL === updatedPage.PageURL) {
                  return updatedPage;
                } else {
                  return p;
                }
            }))
        }
        return (
            <>
                <Checkbox toggle checked={page.Visible} onClick={onClickHandler} className='block'></Checkbox>
                {/* <Checkbox checked={page.Visible} onClick={onClickHandler} className='  md:hidden block ml-3'></Checkbox> */}
            </>
            )
    }

    const AuthToggleButton = ({ page }) => {
        const onClickHandler = () => {
            const updatedPage = {...page, Authenticated: !page.Authenticated}
            setUpdatedPages(updatedPages.map(p => {
                if (p.PageURL === updatedPage.PageURL) {
                  return updatedPage;
                } else {
                  return p;
                }
            }))
        }
        return (
            <>
                <Checkbox toggle checked={page.Authenticated} onClick={onClickHandler} className='md:block hidden'></Checkbox>
                <Checkbox checked={page.Authenticated} onClick={onClickHandler} className='  md:hidden block ml-3'></Checkbox>
            </>
        )
    }

    const DeleteButton = ({ page }) => {
        const onClickHandler = () => {
            PagesService.deletePage(page.PageURL)
            .then((res) => {
                getPagesConfig()
                setMessage(`Successfully deleted page: /${page.PageURL}`)
                AuditService.createAuditLog(`Deleted page: ${page.PageURL}`)
            })
        }
        return <Button compact negative onClick={onClickHandler}>Delete</Button>
    }

    const EditButton = ({ page }) => {
        const onClickHandler = () => {
            if (_.has(cachedContents, page.PageURL)) {
                setSelectedPage({...page})
                setEditedContent(cachedContents[page.PageURL].toString())
                ref.current?.scrollIntoView({behavior: 'smooth'})
                return
            }

            const getPageFunction = page.PageType == 'managed' ? PagesService.getManagedPageByS3 : PagesService.getPageByS3
            getPageFunction(page.PageURL)
            .then(({ result }) => {
                setSelectedPage({...page})
                setCachedContents({...cachedContents, [page.PageURL]:result})
                setEditedContent(result.toString())
                ref.current?.scrollIntoView({behavior: 'smooth'})
            })
            .catch(e => console.error(e)) 
            
        }
        
        return <Button compact onClick={onClickHandler} disabled={selectedPage && selectedPage.PageURL === page.PageURL} icon='edit' color='black'></Button>
    }

    const addPage = (e) => {
        e.preventDefault() 
        let error = [];
        // setAddErrors([])
        const form = e.target
        const formData = new FormData(form)
        const formJson = Object.fromEntries(formData.entries())
        let anyErrors
        if (!formJson.name){anyErrors = true; error.push("Page name is required")}
        if (!formJson.url){anyErrors = true; error.push("Page URL is required")}
        if (formJson.url && pages.some(p => p.PageURL === formJson.url.toLowerCase())) {anyErrors = true; error.push(`The url /${formJson.url.toLowerCase()} already exists. Please change the url.`)}
        setAddErrors(error)

        if (anyErrors) return

        const config = {
            "name": formJson.name,
            "url": formJson.url.toLowerCase(),
            "authenticated": false, 
            "visible": false,
            "position": {"footer": false, "navbar": true}
        }
        
        const content = ''
        
        apiGatewayClientWithCredentials()
        .then((app) => app.post('/mdPages/upload', {}, { config, content }, {}))
        .then((res) => {
            if (res.status === 200) {
                setAddModalOpen(false)
                getPagesConfig()
                setMessage(`Successfully added page: /${formJson.url.toLowerCase()}`)
                AuditService.createAuditLog(`Created new markdown page: ${formJson.name}`)
            }
        })
        .catch(e => console.error(e)) 
    }

    const savePagesChanges = () => {
        const pageConfigs = updatedPages.filter(newConfig => {
            const currentConfig = _.find(pages, { 'PageURL': newConfig.PageURL })
            return !_.isEqual(newConfig, currentConfig)
        })
        apiGatewayClientWithCredentials()
            .then((app) => app.put('/mdPages/config', {}, { pageConfigs }, {}))
            .then((res) => {
                if (res.status === 200) {
                    reloadPagesConfig()
                    getPagesConfig()
                    setMessage(`Saved page configs`)
                    AuditService.createAuditLog(`Updated page configs: [${pageConfigs.map(x => {return '/'+x.PageURL }).join(', ')}]`)
                }
        })
    }
    const saveMdChanges = () => {
        const content = editedContent.trim()
        apiGatewayClientWithCredentials()
            .then((app) => app.put(`/mdPages/${selectedPage.PageURL}/${selectedPage.PageType}`, {}, { content }, {}))
            .then((res) => {
                if (res.status === 200) {
                    getPagesConfig()
                    setEditedContent(content)
                    setCachedContents({...cachedContents, [selectedPage.PageURL]:content})
                    setMessage(`Saved Markdown Content`)
                    AuditService.createAuditLog(`Updated page content: /${selectedPage.PageURL}`)
                }
            })
            .catch(e => console.error(e)) 
    }

    const uploadPage = (e) => {
        e.preventDefault() 
        let error = [];
        setUploadErrors([])
        const form = e.target
        const formData = new FormData(form)
        const formJson = Object.fromEntries(formData.entries())
        
        const files = pageMD.current.files

        let anyErrors
        if (!formJson.name){ anyErrors = true; error.push( "Page name is required")}
        if (files.length < 1) {anyErrors = true; error.push( "You did not select a file")}
        if (files.length > 1) {anyErrors = true; error.push( "You you can only upload 1 file")}
        
        if (anyErrors) return setUploadErrors(error)


        ;[].forEach.call(files, file => {
            const reader = new window.FileReader()
            reader.onload = (e) => {
                const config = {
                    "name": formJson.name,
                    "url": file.name.split('.')[0].toLowerCase(),
                    "authenticated": false, 
                    "visible": false,
                    "position": {"footer": false, "navbar": true}
                }

                if (!file.name.endsWith(".md") && !file.name.endsWith('.txt')){anyErrors = true; error.push("Only .md and .txt files are allowed")}
                if (pages.some(p => p.PageURL === config.url)) {anyErrors = true; error.push(`The url /${config.url} already exists. Please change the filename.`)}
                
                if (anyErrors) return setUploadErrors(error)

                const content = e.target.result.toString()
                apiGatewayClientWithCredentials()
                .then((app) => app.post('/mdPages/upload', {}, { config, content }, {}))
                .then((res) => {
                    if (res.status === 200) {
                        AuditService.createAuditLog(`Uploaded markdown page: ${config.url}`)
                        setUploadModalOpen(false)
                        getPagesConfig()
                        setMessage(`Successfully uploaded page: /${config.url}`)
                    }
                })
                .catch(e => setUploadErrors([e.data.message])) 


            }
            reader.readAsText(file)
        })
    }

    const SyncButtons = () => {
        const [loading, setLoading] = useState(false) 
        const syncManagedPages = async (reset) => {
            setLoading(true)
            PagesService.syncManagedPages(reset)
            .then(({ result }) => {
                setDevPages([...result])
            })
            .finally(() => {
                setSelectedPage(undefined) 
                setLoading(false)
            })
        }

        return (
            <Popup
                flowing
                trigger={
                    <Button icon='refresh' content='Sync S3 With Database' className='order-2'/>
                }
                hoverable
                position='right center'
            >
                <div className='grid grid-cols-2' >
                    <div className='col-span-2 font-bold text-center'>FOR DEVELOPER USE ONLY</div>
                    <Divider className='col-span-2 m-0'/>
                    <div className='flex flex-col justify-between text-center'>
                        <p className='w-64'>
                            Delete pages from database that are not in S3 as well as revert all pages to their defaults
                        </p>
                        <Button color='red' icon='refresh' content='Reset' loading={loading} onClick={() => syncManagedPages(true)} floated='right'/>
                    </div>
                    <div className='flex flex-col justify-between text-center'>
                        <p className='w-64'>
                            Retrieve uploaded pages in Default and add them to the database
                        </p>
                        <Button color='black' icon='refresh' content="Sync" loading={loading} onClick={() => syncManagedPages()} floated='right'/>
                    </div>
                </div>
            </Popup>
        )
    }

    const search = (value) => {
        const managedPagesFilter = (page) => {
            return page.PageURL.toLowerCase().includes(value.toLowerCase())
        }

        const customPagesFilter = (page) => {
            return page.PageURL.toLowerCase().includes(value.toLowerCase())
        }

        const filterFn = pagetype == 'managed' ? managedPagesFilter : customPagesFilter
        let toQuery = pagetype == 'managed' ? devpages : updatedPages
        if (value) {
            setQueriedPages(toQuery.filter(filterFn))
        } else {
            setQueriedPages(toQuery.filter(filterFn))
        }
    }

    useEffect(()=> {
        search(query)
    }, [updatedPages, devpages, pages, query, pagetype])
    return(
        <>
            <div className='md:div div-cols-12 gap-x-4 p-8 lg:px-20 px-12'>
                <div className='col-span-12'>
                </div>
                <div className='col-span-12'>
                    <Header as='h1'><Dropdown inline options={[{text: 'Custom Pages', key: 1, value: 'custom'}, {text: 'Managed Pages', key: 2, value: 'managed'}]} onChange={(e, data) => {navigate(`/admin/pages/${data.value}`)}} value={pagetype}/></Header>
                    <div className='mb-4 flex space-x-4'>
                        <Input icon='search' placeholder='Search...' iconPosition='left' onChange={(e) => {setQuery(e.target.value)}}/>
                        <div className="flex grow">
                            <ChangesWarning/>
                            <Message className='m-0 px-6 py-[11px] text-base leading-4' size='mini' positive hidden={!message} content={message}/>
                        </div>
                        <InvalidationButton></InvalidationButton>
                        {pagetype != 'managed' ?
                        <>
                            <Modal
                                closeIcon
                                closeOnEscape
                                closeOnDimmerClick                                    
                                open={uploadModalOpen}
                                onOpen={() => {setUploadModalOpen(true)}}
                                onClose={() => {setUploadModalOpen(false)}}
                                trigger={
                                <Button color='blue' floated='right'>
                                    Upload
                                </Button>
                                }
                            >
                                <Modal.Header>Select .txt or .md files</Modal.Header>
                                <Modal.Content className='px-0'>
                                    <div className='px-8'>
                                    <Message info>
                                        NOTE: The name of your file will be used for the url of the page<br />
                                        eg. FAQ.md = /FAQ
                                    </Message>
                                    <Form onSubmit={uploadPage} error={uploadErrors.length > 0}>
                                        <Form.Field>
                                            <label htmlFor='name'>Page Name</label>
                                            <input type='text' id='name' name='name' />
                                        </Form.Field>
                                        <Form.Field >
                                            <label htmlFor='files'>Select Files:</label>
                                            <input type='file' id='files' name='files' accept='.md,.txt' ref={pageMD}/>
                                        </Form.Field>
                                        <Message error list={uploadErrors}>
                                        </Message>
                                        <Button type='submit' fluid>Upload</Button>
                                    </Form>
                                    </div>
                                </Modal.Content>
                            </Modal>
                            <Modal
                                closeIcon
                                closeOnEscape
                                closeOnDimmerClick
                                onOpen={() => {setAddModalOpen(true); setAddErrors([])}}
                                onClose={() => {setAddModalOpen(false)}}
                                open={addModalOpen}
                                trigger={
                                <Button color='blue' floated='right'>
                                    Add Page
                                </Button>
                                }
                            >
                                <Modal.Header>Create blank page</Modal.Header>
                                <Modal.Content className='px-0'>
                                    <div className='px-8'>
                                        <Form onSubmit={addPage} error={addErrors && addErrors.length > 0}>
                                        <Form.Input type='text' id='name' name='name' label="Page Name"></Form.Input>
                                        <Form.Field>
                                            <label htmlFor='url'>URL</label>
                                            <Input id='url' name='url' label='/' placeholder='mypage'/>
                                        </Form.Field>
                                        <Message error list={addErrors && addErrors}>
                                        </Message>
                                        <Button type='submit' fluid>Add Page</Button>
                                        </Form>
                                    </div>
                                </Modal.Content>
                            </Modal>
                        </> :
                        <>
                            {window.location.hostname==='localhost' && <SyncButtons/>}
                        </>
                        }
                        <Button content="Refresh" onClick={() => {getPagesConfig()}}/>
                    </div>
                    <Table className='' >
                        { pagetype == "custom" ?
                            <>
                                <Table.Header className='relative' fullWidth>
                                    {!_.isEqual(pages, updatedPages) && 
                                        <Label attached='top right' color='yellow' colSpan={0}>Unsaved Changes Made</Label>
                                    }
                                    <Table.Row>
                                        <Table.HeaderCell collapsing sorted='ascending'>Visible</Table.HeaderCell>
                                        <Table.HeaderCell collapsing sorted='ascending'>Require Login</Table.HeaderCell>
                                        <Table.HeaderCell>Page Name</Table.HeaderCell>
                                        <Table.HeaderCell>URL</Table.HeaderCell>
                                        <Table.HeaderCell>Header</Table.HeaderCell>
                                        <Table.HeaderCell>Footer</Table.HeaderCell>
                                        <Table.HeaderCell>Created</Table.HeaderCell>
                                        <Table.HeaderCell>Updated</Table.HeaderCell>
                                        <Table.HeaderCell>Actions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    { !loading && (queriedPages.length > 0 && queriedPages.every(p => p.PageType === 'custom') ? queriedPages.map((page, index) => 
                                        Object.keys(page).length ?
                                        <Table.Row key={index}>
                                            <Table.Cell collapsing className={_.find(pages, {PageURL: page.PageURL}).Visible !== page.Visible ? 'bg-yellow-100' : ''}>
                                                <VisiblityToggleButton page={page}/>
                                            </Table.Cell>
                                            <Table.Cell collapsing className={_.find(pages, {PageURL: page.PageURL}).Authenticated !== page.Authenticated? 'bg-yellow-100' : ''}>
                                                <AuthToggleButton page={page}/>
                                            </Table.Cell>
                                            <Table.Cell collapsing>{page.Name}</Table.Cell>
                                            <Table.Cell collapsing>
                                                <Link to={`/${page.PageURL}`} className='text-blue-500 underline'>{`/${page.PageURL}`}</Link>    
                                            </Table.Cell>
                                            <Table.Cell collapsing className={_.find(pages, {PageURL: page.PageURL}).Position.navbar !== page.Position.navbar? 'bg-yellow-100' : ''}>
                                                <ToggleButton page={page} label='NavBar'/>
                                            </Table.Cell>
                                            <Table.Cell collapsing className={_.find(pages, {PageURL: page.PageURL}).Position.footer !== page.Position.footer? 'bg-yellow-100' : ''}>
                                                <ToggleButton page={page} label='Footer'/>
                                            </Table.Cell>
                                            <Table.Cell collapsing>{formatDate(page.CreatedAt)}</Table.Cell>
                                            <Table.Cell collapsing>{formatDate(page.UpdatedAt)}</Table.Cell>
                                            <Table.Cell collapsing>
                                                <Button.Group fluid compact>
                                                    <EditButton page={page}></EditButton>
                                                    <Modal
                                                        closeIcon
                                                        closeOnEscape
                                                        closeOnDimmerClick
                                                        size='mini'
                                                        trigger={
                                                            <Button negative icon='trash alternate'></Button>
                                                        }
                                                    >
                                                        <Modal.Header>Confirm Delete?</Modal.Header>
                                                        <Modal.Content>
                                                            <p className='text-xl font-bold'>Are you sure you want to delete this page?</p>
                                                        </Modal.Content>
                                                        <Modal.Actions>
                                                            <DeleteButton page={page}>Delete</DeleteButton>
                                                        </Modal.Actions>
                                                    </Modal>
                                                </Button.Group>
                                            </Table.Cell>
                                        </Table.Row> :
                                        <Table.Row>
                                            <Table.Cell>&nbsp;</Table.Cell>
                                            <Table.Cell>&nbsp;</Table.Cell>
                                            <Table.Cell>&nbsp;</Table.Cell>
                                            <Table.Cell>&nbsp;</Table.Cell>
                                            <Table.Cell>&nbsp;</Table.Cell>
                                            <Table.Cell>&nbsp;</Table.Cell>
                                            <Table.Cell>&nbsp;</Table.Cell>
                                            <Table.Cell>&nbsp;</Table.Cell>
                                            <Table.Cell>&nbsp;</Table.Cell>
                                        </Table.Row>
                                    ) :
                                    <Table.Row>
                                        <Table.Cell colSpan='9' className='text-center'>
                                        No pages yet
                                        </Table.Cell>
                                    </Table.Row>) } 
                                    { loading && 
                                    <Table.Row>
                                        <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                        <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                        <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                        <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                        <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                        <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                        <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                        <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                        <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                    </Table.Row>}
                                </Table.Body>
                                { pagetype =='custom' && 
                                <Table.Footer fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan='9'>
                                            <Button basic color='green' fluid onClick={savePagesChanges} disabled={_.isEqual(pages, updatedPages)}>
                                                Save Configuration
                                            </Button>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>
                                }
                            </> :
                            <>
                                <Table.Header fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell>URL</Table.HeaderCell>
                                        <Table.HeaderCell>CreatedAt</Table.HeaderCell>
                                        <Table.HeaderCell>UpdatedAt</Table.HeaderCell>
                                        <Table.HeaderCell>Actions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    { !loading && (queriedPages.length > 0  ? queriedPages.map(page => 
                                        <Table.Row key={page.PageURL} active={selectedPage && selectedPage.PageURL === page.PageURL}>
                                            <Table.Cell collapsing>
                                                <Link to={page.PageURL === 'index' ? '/' : `/${page.PageURL}` } className='text-blue-500 underline'>{`/${page.PageURL}`}</Link>    
                                            </Table.Cell>
                                            <Table.Cell collapsing>{formatDate(page.CreatedAt)}</Table.Cell>
                                            <Table.Cell collapsing>{formatDate(page.UpdatedAt)}</Table.Cell>
                                            <Table.Cell collapsing>
                                                <Button.Group fluid>
                                                        <EditButton page={page}></EditButton>
                                                </Button.Group>
                                            </Table.Cell>
                                        </Table.Row>
                                    ) :
                                    <Table.Row>
                                        <Table.Cell colSpan='4' className='text-center'>
                                        No pages yet
                                        </Table.Cell>
                                    </Table.Row>) } 
                                    { loading && 
                                    <Table.Row>
                                        <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                        <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                        <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                        <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                    </Table.Row>}
                                </Table.Body>
                                
                            </>
                        }
                    </Table>
                </div>
                <div className='col-span-12 mt-8 ' ref={ref}>
                    <Card fluid className='w-full'>
                        <Card.Content extra>
                            <Card.Header className='p-2 text-center'>
                                {selectedPage ? 'Currently editing: ': 'Markdown Editor'}
                                {selectedPage && <Link className='font-lg text-blue-500 underline' to={`/${selectedPage.PageURL}`}>/{selectedPage.PageURL}</Link> }   
                                {selectedPage &&  !_.isEqual(editedContent, cachedContents[selectedPage.PageURL]) && 
                                    <Label attached='top right' color='yellow'>Unsaved Changes Made</Label>
                                }
                            </Card.Header>
                        </Card.Content>
                        <Card.Content className='p-0 min-h-[50vh]'>
                            {selectedPage ?
                            <div className='h-full'>
                                <MarkdownEditor value={editedContent} onChange={setEditedContent} />
                            </div>
                            :
                            <Segment placeholder className='border-0 min-h-[50vh]'>
                                <div className="h-full font-bold text-2xl text-center">
                                    Select 'Edit' on a Page to start editing
                                </div>
                            </Segment>

                            }
                        </Card.Content>
                        <Card.Content extra>
                            <Button basic color='green' fluid onClick={saveMdChanges} disabled={ selectedPage ? _.isEqual(editedContent, cachedContents[selectedPage.PageURL]): true}>
                                Save Markdown Content
                            </Button>
                        </Card.Content>
                    </Card>
                </div>
            </div>
        </>
    )

}

export default Pages
