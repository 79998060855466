import React, { useCallback, useEffect, useState } from 'react'
import { Button, Container, Header, Message, Modal, Grid, Table, Menu, Dropdown, Checkbox } from 'semantic-ui-react'
import { fetchUsageMetrics, mapUsageByDate, subscribe, unsubscribe, updateSwaggerAccess } from 'services/api-catalog'

import * as MessageList from 'components/MessageList'
import * as AccountService from 'services/accounts'
import * as AccountsTable from 'components/Admin/Accounts/AccountsTable'
import * as AccountsTableColumns from 'components/Admin/Accounts/AccountsTableColumns'
import { store } from 'services/state'
import _ from 'lodash'
import Chart from 'chart.js'
import { observer } from 'mobx-react'
import { validRange } from 'semver'
import * as AuditService from 'services/auditing'


const RegisteredAccounts = () => {
  const [accounts, setAccounts] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedAccount, setSelectedAccount] = useState(undefined)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [promoteModalOpen, setPromoteModalOpen] = useState(false)
  const [messages, sendMessage] = MessageList.useMessages()
  const [changeStatusModal, setchangeStatusModal] = useState(false)
  // const [openUserPoolMFAModal, setopenUserPoolMFAModal] = useState(false)
  // const [UserPoolMFAoption, setUserPoolMFAoption] = useState("undefined")
  // const [selectedUserPoolMFAoption, setSelectedUserPoolMFAoption] = useState('undefined')
  // const [openMFAModal, setopenMFAModal] = useState(false)
  // const [selectedAccountMFAoption, setSelectedAccountMFAoption] = useState('undefined')
  // const [newSelectedAccountMFAoption, setnewSelectedAccountMFAoption] = useState('undefined')
  const [accountsSubscriptions, setAccountsSubscriptions] = useState([])
  const [selectedAccountSubscriptions, setSelectedAccountSubscriptions] = useState(undefined)
  const [editSubscriptionModal, setEditSubscriptionModal] = useState(false)
  const [editSwaggerAccess, setEditSwaggerAccess] = useState(false)
  const [selectedSwaggerAccess, setSelectedSwaggerAccess] = useState("undefined")
  const [viewUsageMetricsModal, setViewUsageMetricsModal] = useState(false)
  const [privateModalOpen, setPrivateModalOpen] = useState(false)
  const [openAccountModal, setAccountModal] = useState(false)
  const [selectedAccountMobile, setSelectedAccountMobile] = useState(undefined)

  const refreshAccounts = async () => {
    const [allT, admins, allUsersSubscriptions] = await Promise.all([
      AccountService.fetchRegisteredAccounts(),
      AccountService.fetchAdminAccounts(),
      AccountService.fetchAllUserSubscriptions()
    ])

    // setUserPoolMFAoption((await AccountService.getUserPoolMFAConfig()).result.MfaConfiguration)
    let allMap = {} // for storing every user's info to be displayed
    let temp = {} // for mapping every user's MFA config 
    let t = {}
    let all = []

    allT.forEach(user => {
      if (user.IdentityId){
        all.push(user)
      }
    })

    all.forEach(user => { allMap[user.UserId] = user })
    all.forEach(user => t[user.UserId] = allUsersSubscriptions[user.IdentityId].items)
    // Get MFA settings of every user
    await Promise.all(all.map(async (user) => {
      temp[user.UserId] = await AccountService.fetchSingleUserInfo(user.UserId)
    }))

    admins.forEach(admin => {
      admin.IsAdmin = true
      if (admin.EmailAddress === store.user.email) {
        admin.EmailAddress += ' (you)'
      }
      allMap[admin.UserId] = admin
    })


    // for (let key in temp) {
    //   if (temp[key]["UserMFASettingList"]) {
    //     allMap[key].MFASettings = temp[key]["UserMFASettingList"][0]
    //   }
    //   allMap[key].Enabled = temp[key]['Enabled']

    // }
    setAccountsSubscriptions(t)
    return setAccounts(Object.values(allMap))
  }

  const isYou = (user) => {
    return (_.get(store, 'user.email') + ' (you)') === user.EmailAddress
  }

  function getApisWithStages () {
    const apiList = [].concat(_.get(store, 'apiList.generic', []), _.get(store, 'apiList.apiGateway', [])).map(api => ({
      group: api.apiId || api.id,
      id: api.apiStage || api.id,
      title: api.swagger.info.title,
      route: `/apis/${api.id}` + (api.apiStage ? '/' + api.apiStage : ''),
      
      stage: api.apiStage,
      desc: api.swagger.tags ? api.swagger.tags[0].description: null,
      info: api.swagger.info ? api.swagger.info : null,
      options: api.swagger.paths? api.swagger.paths: null
    }))
  
    const ApisWithStages = _.toPairs(_.groupBy(apiList, 'group'))
      .map(([group, apis]) => ({ group, apis, active: _.some(apis, 'active'), title: apis[0].title, desc: apis[0].desc, info: apis[0].info, options: apis[0].options}))
  
    
    return ApisWithStages
  }

  const APIs = getApisWithStages()

  const getAPISWithUsagePlans = () => {
    const usagePlans =
      store.visibility.apiGateway
        .filter((api) => api.usagePlanId)
        .reduce((accumulator, api) => {
          if (!accumulator.find((usagePlan) => api.usagePlanId === usagePlan.id)) {
            accumulator.push({ UsagePlanId: api.usagePlanId, UsageName: api.usagePlanName, APIName: api.name, APIId: api.id })
          }
          return accumulator
        }, [])
    return _.cloneDeep(usagePlans)
  }
  const APISWithusagePlans = getAPISWithUsagePlans()

  const openSubscriptions = () => {
    setEditSubscriptionModal(true)
    setSelectedAccountSubscriptions(accountsSubscriptions[selectedAccount.UserId])
  }

  const openAccess = () => {
    setEditSwaggerAccess(true)
    // setSelectedSwaggerAccess(APIs)
  }

  const openViewMetrics = () => {
    setViewUsageMetricsModal(true)
    setSelectedAccountSubscriptions(accountsSubscriptions[selectedAccount.UserId])
  }

  // Initial load
  useEffect(() => {
    refreshAccounts().finally(() => setLoading(false))
  }, [])

  const onSelectAccount = useCallback(account => setSelectedAccount(account), [
    setSelectedAccount
  ])



  const onSelectAccountMobile = useCallback(account => {
    setSelectedAccountMobile(account)
    setAccountModal(true)
  }, [setSelectedAccountMobile])

  const onConfirmDelete = useCallback(async () => {
    setLoading(true)
    setDeleteModalOpen(false)
    try {
      await AccountService.deleteAccountByUserId(selectedAccount.UserId)
      sendMessage(dismiss => (
        <DeleteSuccessMessage account={selectedAccount} dismiss={dismiss} />
      ))
      try {
        AuditService.createAuditLog(`Deleted user: ${selectedAccount.EmailAddress.replace(' (you)', '')}`)
      } catch (error) {
          console.log(error);
      }
      await refreshAccounts()
    } catch (error) {
      sendMessage(dismiss => (
        <DeleteFailureMessage
          account={selectedAccount}
          dismiss={dismiss}
          errorMessage={error.message}
        />
      ))
    } finally {
      setLoading(false)
    }
  }, [sendMessage, selectedAccount])

  const onConfirmPrivateAccess = useCallback(async () => {
    setLoading(true)
    setPrivateModalOpen(false)
    try {
      await AccountService.updatePrivateAccessForUser(selectedAccount.UserId, !selectedAccount.PrivateAccess)
      sendMessage(dismiss => (
        <PrivateSuccessMessage account={selectedAccount} dismiss={dismiss} />
      ))
      try {
        AuditService.createAuditLog(`Changed Private Access: ${selectedAccount.EmailAddress.replace(' (you)', '')}`)
    } catch (error) {
        console.log(error);
    }
      await refreshAccounts()
    } catch (error) {
      sendMessage(dismiss => (
        <PrivateFailureMessage
          account={selectedAccount}
          dismiss={dismiss}
          errorMessage={error.message}
        />
      ))
    } finally {
      setLoading(false)
    }
  }, [sendMessage, selectedAccount])


  const onConfirmPromote = useCallback(async () => {
    setLoading(true)
    setPromoteModalOpen(false)
    try {
      await AccountService.promoteAccountByUserId(selectedAccount.UserId)
      sendMessage(dismiss => (
        <PromoteSuccessMessage account={selectedAccount} dismiss={dismiss} />
      ))
      try {
        AuditService.createAuditLog(`promoted user: ${selectedAccount.EmailAddress.replace(' (you)', '')}`)
      } catch (error) {
          console.log(error);
      }
      await refreshAccounts()
    } catch (error) {
      sendMessage(dismiss => (
        <PromoteFailureMessage
          account={selectedAccount}
          dismiss={dismiss}
          errorMessage={error.message}
        />
      ))
    } finally {
      setLoading(false)
    }
  }, [sendMessage, selectedAccount])

  const onConfirmChangeStatus = useCallback(async () => {
    setLoading(true)
    setchangeStatusModal(false)
    let change = 'disable'
    if (selectedAccount.Enabled === false) {
      change = 'enable'
    }
    try {
      await AccountService.changeAccountStatusAccountByUserId(selectedAccount.UserId, change)
      sendMessage(dismiss => (
        <ChangeStatusSuccessMessage account={selectedAccount} dismiss={dismiss} />
      ))
      try {
        AuditService.createAuditLog(`Disabled user: ${selectedAccount.EmailAddress.replace(' (you)', '')}`)
      } catch (error) {
          console.log(error);
      }
      await refreshAccounts()
    } catch (error) {
      sendMessage(dismiss => (
        <ChangeStatusFailureMessage
          account={selectedAccount}
          dismiss={dismiss}
          errorMessage={error.message}
        />
      ))
    } finally {
      setLoading(false)
    }
  }, [sendMessage, selectedAccount])

  /*TODO*/
  // const onConfirmChangeUserPoolMFA = useCallback(async () => {
  //   setLoading(true)
  //   setopenUserPoolMFAModal(false)
  //   try {
  //     await AccountService.changeUserPoolMFAConfig(selectedUserPoolMFAoption)
  //     sendMessage(dismiss => (
  //       <ChangeMFASuccessMessage dismiss={dismiss} />
  //     ))
  //     await refreshAccounts()
  //   } catch (error) {
  //     sendMessage(dismiss => (
  //       <ChangeMFAFailureMessage
  //         dismiss={dismiss}
  //         errorMessage={error.message}
  //       />
  //     ))
  //   } finally {
  //     setLoading(false)
  //   }
  // }, [sendMessage, UserPoolMFAoption])


  const onClickRefresh = async () => {

    setLoading(true)
    await refreshAccounts()
    setLoading(false)
  }

  // const handleChange = (e) => {
  //   setSelectedUserPoolMFAoption(e.target.value);
  // }
  // const handleChange2 = (e) => {
  //   setnewSelectedAccountMFAoption(e.target.value)
  // }

  // const onConfirmChangeUserMFA = useCallback(async () => {
  //   setLoading(true)
  //   setopenMFAModal(false)
  //   try {
  //     await AccountService.changeUserMFA(selectedAccount.UserId, newSelectedAccountMFAoption)
  //     sendMessage(dismiss => (
  //       <ChangeMFASuccessMessage dismiss={dismiss} />
  //     ))
  //     await refreshAccounts()
  //   } catch (error) {
  //     sendMessage(dismiss => (
  //       <ChangeMFAFailureMessage
  //         dismiss={dismiss}
  //         errorMessage={error.message}
  //       />
  //     ))
  //   } finally {
  //     setLoading(false)
  //   }
  // }, [sendMessage, UserPoolMFAoption])

  // const openUserMFAModal = () => {
  //   setopenMFAModal(true)
  //   if (selectedAccount.MFASettings !== undefined) {
  //     setSelectedAccountMFAoption(selectedAccount.MFASettings)
  //   }
  //   else {
  //     setSelectedAccountMFAoption(null)
  //   }
  // }
  return (
    <>
      <div className='md:fluid p-8 lg:px-20 px-12 visible lg:hidden text-xs'>
        <Header as='h1'>Registered accounts</Header>
        <MessageList.MessageList messages={messages} />
        <AccountsTable.AccountsTable
          accounts={accounts}
          columns={[
            AccountsTableColumns.EmailAddress,
            AccountsTableColumns.FullName,
          ]}
          loading={loading}
          selectedAccountMobile={selectedAccountMobile}
          onSelectAccount={onSelectAccountMobile}
          // onClick={()=>setAccountModal(true)}
        >
        </AccountsTable.AccountsTable>
        <DeleteAccountModal
          account={selectedAccountMobile? selectedAccountMobile : null}
          onConfirm={onConfirmDelete}
          open={deleteModalOpen}
          isAdmin={selectedAccountMobile && selectedAccountMobile.IsAdmin}
          onClose={() => setDeleteModalOpen(false)}
        />
        <ViewAccountModal
          account={selectedAccountMobile? selectedAccountMobile : null}
          open={openAccountModal}
          onClose={()=> setAccountModal(false)}
          canDelete={!loading && selectedAccountMobile && !isYou(selectedAccountMobile)}
          onClickDelete={() => setDeleteModalOpen(true)}
          canPromote={!loading && selectedAccountMobile}
          isAdmin={selectedAccountMobile && selectedAccountMobile.IsAdmin}
          onClickPromote={() => setPromoteModalOpen(true)}
          onClickChangeStatus={() => setchangeStatusModal(true)}
          onClickRefresh={() => onClickRefresh()}
          canChangeStatus={!loading && selectedAccountMobile && !isYou(selectedAccountMobile)}
          // onClickOpenUserPoolMFA={() => setopenUserPoolMFAModal(true)}
          // canChangeUserMFA={!loading && selectedAccount}
          // onClickOpenUserMFA={openUserMFAModal}
          onClickOpenSubscriptions={openSubscriptions}
          canOpenSubscriptions={!loading && selectedAccountMobile}
          onClickOpenAccess={openAccess}
          canOpenAccess={!loading && selectedAccountMobile}
          canOpenViewMetrics={!loading && selectedAccountMobile}
          onClickOpenViewMetrics={openViewMetrics}
          canOpenPrivateAccess={!loading && selectedAccountMobile}
          onClickPrivate={()=>setPrivateModalOpen(true)}
        />
        {/* {console.log(openAccountModal)} */}
        <PromoteAccountModal
          account={selectedAccountMobile? selectedAccountMobile : null}
          onConfirm={onConfirmPromote}
          open={promoteModalOpen}
          onClose={() => setPromoteModalOpen(false)}
        />
        <StatusModal
          account={selectedAccountMobile? selectedAccountMobile : null}
          onConfirm={onConfirmChangeStatus}
          open={changeStatusModal}
          onClose={() => setchangeStatusModal(false)}
        />
        <SwaggerAccess
          account={selectedAccountMobile? selectedAccountMobile : null}
          open={editSwaggerAccess}
          onClose={() => {setEditSwaggerAccess(false); refreshAccounts()}}
          APIs={APIs}
        />
        <PrivateAccessModal
        account={selectedAccountMobile? selectedAccountMobile: null}
        open={privateModalOpen}
        onClose={()=>setPrivateModalOpen(false)}
        onConfirm={onConfirmPrivateAccess}
        
        />
      </div>
      <div className='lg:fluid p-8 lg:px-20 px-12 lg:visible invisible'>
        <Header as='h1'>Registered accounts</Header>
        <MessageList.MessageList messages={messages} />
        <AccountsTable.AccountsTable
          accounts={accounts}
          columns={[
            AccountsTableColumns.EmailAddress,
            AccountsTableColumns.FullName,
            AccountsTableColumns.Organisation,
            AccountsTableColumns.Project,
            AccountsTableColumns.IsAdmin,
            AccountsTableColumns.PrivateAccess,
            AccountsTableColumns.DateRegistered,
            AccountsTableColumns.LastLogin
          ]}
          loading={loading}
          selectedAccount={selectedAccount}
          onSelectAccount={onSelectAccount}
        >
          <TableActions
            canDelete={!loading && selectedAccount && !isYou(selectedAccount)}
            onClickDelete={() => setDeleteModalOpen(true)}
            canPromote={!loading && selectedAccount}
            isAdmin={selectedAccount && selectedAccount.IsAdmin}
            onClickPromote={() => setPromoteModalOpen(true)}
            onClickChangeStatus={() => setchangeStatusModal(true)}
            onClickRefresh={() => onClickRefresh()}
            canChangeStatus={!loading && selectedAccount && !isYou(selectedAccount)}
            // onClickOpenUserPoolMFA={() => setopenUserPoolMFAModal(true)}
            // canChangeUserMFA={!loading && selectedAccount}
            // onClickOpenUserMFA={openUserMFAModal}
            onClickOpenSubscriptions={openSubscriptions}
            canOpenSubscriptions={!loading && selectedAccount}
            onClickOpenAccess={openAccess}
            canOpenAccess={!loading && selectedAccount}
            canOpenViewMetrics={!loading && selectedAccount}
            onClickOpenViewMetrics={openViewMetrics}
            canOpenPrivateAccess={!loading && selectedAccount}
            onClickPrivate={()=>setPrivateModalOpen(true)}
          />
        </AccountsTable.AccountsTable>
        {/* {console.log(selectedAccount)} */}
        <DeleteAccountModal
          account={selectedAccount? selectedAccount : null}
          onConfirm={onConfirmDelete}
          open={deleteModalOpen}
          isAdmin={selectedAccount && selectedAccount.IsAdmin}
          onClose={() => setDeleteModalOpen(false)}
        />
        <PromoteAccountModal
          account={selectedAccount? selectedAccount : null}
          onConfirm={onConfirmPromote}
          open={promoteModalOpen}
          onClose={() => setPromoteModalOpen(false)}
        />
        <StatusModal
          account={selectedAccount? selectedAccount : null}
          onConfirm={onConfirmChangeStatus}
          open={changeStatusModal}
          onClose={() => setchangeStatusModal(false)}
        />
        <SwaggerAccess
          account={selectedAccount? selectedAccount : null}
          open={editSwaggerAccess}
          onClose={() => {setEditSwaggerAccess(false); refreshAccounts()}}
          APIs={APIs}
        />
        <PrivateAccessModal
        account={selectedAccount? selectedAccount: null}
        open={privateModalOpen}
        onClose={()=>setPrivateModalOpen(false)}
        onConfirm={onConfirmPrivateAccess}
        
        />
      </div>
    </>
  )
}
export default RegisteredAccounts

const DATE_TIME_FORMATTER = new Intl.DateTimeFormat('default', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
})

const TableActions = React.memo(
  ({ mobileView,canDelete, onClickDelete, canPromote, onClickPromote, isAdmin, canChangeStatus, onClickChangeStatus, onClickRefresh, onClickOpenUserMFA, onClickOpenUserPoolMFA, canChangeUserMFA, canOpenSubscriptions, canOpenAccess, canOpenViewMetrics, onClickOpenSubscriptions, onClickOpenAccess, onClickOpenViewMetrics, onClickPrivate, canOpenPrivateAccess }) => (
    <>
      <Button.Group>
        {mobileView?null:<Button content='Refresh' onClick={onClickRefresh}/>}
        {/* <Button
          content='MFA Config'
          onClick={onClickOpenUserPoolMFA}
        /> */}
      </Button.Group> &nbsp;&nbsp;
      <Menu vertical icon>
        <Dropdown item icon='th'>
          <Dropdown.Menu direction='left'>
            <Dropdown.Item disabled={!canChangeStatus} onClick={onClickChangeStatus}>Change Status</Dropdown.Item>
            <Dropdown.Item disabled={!canPromote || isAdmin} onClick={onClickPromote}>Promote to Admin</Dropdown.Item>
            {/* <Dropdown.Item disabled={!canChangeUserMFA} onClick={onClickOpenUserMFA}>Change User MFA</Dropdown.Item> */}
            <Dropdown.Item disabled={!canDelete} onClick={onClickDelete}>Delete</Dropdown.Item>
            {/* <Dropdown.Item disabled={!canOpenSubscriptions} onClick={onClickOpenSubscriptions}>Edit Subscriptions</Dropdown.Item> */}
            <Dropdown.Item disabled={!canOpenAccess} onClick={onClickOpenAccess}>Edit API Access</Dropdown.Item>
            {/* <Dropdown.Item disabled={!canOpenViewMetrics} onClick={onClickOpenViewMetrics}>View Usage metrics</Dropdown.Item> */}
            <Dropdown.Item disabled={!canOpenPrivateAccess} onClick={onClickPrivate}>Grant/Remove Private Access</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
    </>
  )
)

const ViewAccountModal = React.memo(
  ({account, open, onClose, canDelete, onClickDelete, canPromote, onClickPromote, isAdmin, canChangeStatus, onClickChangeStatus, onClickRefresh, canOpenAccess, onClickOpenAccess, onClickPrivate, canOpenPrivateAccess })=>
  account &&(
    <Modal size='large' open={open} onClose={onClose}>
      {/* {console.log(open)}   */}
      <Modal.Header>Account Information</Modal.Header>
      <Modal.Content>
        <strong>Full Name: </strong>{account.FullName}<br/><br/>
        <strong>Email: </strong>{account.EmailAddress}<br/><br/>
        <strong>Organisation: </strong>{account.Organisation}<br/><br/>
        <strong>Project: </strong>{account.Project}<br/><br/>
        <strong>Administrator: </strong>{isAdmin? 'Admin': 'User'}<br/><br/>
        <strong>Private API Access: </strong>{account.PrivateAccess? 'Allowed': 'Denied'}<br/><br/>
        <strong>Date Registered: </strong>{DATE_TIME_FORMATTER.format(new Date(account.DateRegistered))}<br/><br/>
        <strong>Last Login: </strong>{DATE_TIME_FORMATTER.format(new Date(account.LastLogin))}<br/><br/>
      </Modal.Content>
      <Modal.Actions>
          <Button onClick={onClose}>Close</Button>
          {/* <Button> */}
            <TableActions
              canDelete={canDelete}
              onClickDelete={onClickDelete}
              canPromote={canPromote}
              isAdmin={isAdmin}
              onClickPromote={onClickPromote}
              onClickChangeStatus={onClickChangeStatus}
              onClickRefresh={onClickRefresh}
              canChangeStatus={canChangeStatus}
              onClickOpenAccess={onClickOpenAccess}
              canOpenAccess={canOpenAccess}
              canOpenPrivateAccess={canOpenPrivateAccess}
              onClickPrivate={onClickPrivate}
              mobileView={true}
          />
          {/* </Button> */}
        </Modal.Actions>
    </Modal>
  )
)

const DeleteAccountModal = React.memo(
  ({ account, onConfirm, open, onClose, isAdmin }) =>
    account && (
      <Modal size='small' open={open} onClose={onClose}>
        <Modal.Header>Delete account</Modal.Header>
        <Modal.Content>
          {isAdmin && (
            <Message negative>
              <Message.Header>Danger! This is an admin account.</Message.Header>
              <p><strong>Deleting an admin account could cause temporary loss of access and temporary inability to configure the developer portal.</strong></p>
            </Message>
          )}
          <p>
            Are you sure you want to delete the account <strong>{account.EmailAddress}</strong>, and de-activate the             associated API key? This action is irreversible.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button negative onClick={onConfirm}>
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
    )
)

const PrivateAccessModal = React.memo(
  ({ account, onConfirm, open, onClose, isAdmin }) =>
    account && (
      <Modal size='small' open={open} onClose={onClose}>
        <Modal.Header>Grant Private Access</Modal.Header>
          {account.PrivateAccess===false?
          <>
            <Modal.Content>
              <p>
                Are you sure you want to grant private access to <strong>{account.EmailAddress}</strong>, granting access allows user to access all private services. Do you want to continue?
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={onClose}>Cancel</Button>
              <Button positive onClick={onConfirm}>
                Grant Access
              </Button>
            </Modal.Actions>
          </>
          :
          <>
            <Modal.Content>
            <p>
              Are you sure you want to remove private access to <strong>{account.EmailAddress}</strong>, user will not be able to access all private services. Do you want to continue?
            </p>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={onClose}>Cancel</Button>
              <Button negative onClick={onConfirm}>
                Remove Access
              </Button>
            </Modal.Actions>
          </>
          }
      </Modal>
    )
)

const PromoteAccountModal = React.memo(
  ({ account, onConfirm, open, onClose }) =>
    account && (
      <Modal size='small' open={open} onClose={onClose}>
        <Modal.Header>Confirm promotion</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to promote the account{' '}
            <strong>{account.EmailAddress}</strong> to Admin? This will allow
            the account to perform any Admin actions, including deleting and
            promoting other accounts.
          </p>
          <p>
            Only the owner of the Developer Portal can demote the account,
            through the Cognito console.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button negative onClick={onConfirm}>
            Promote
          </Button>
        </Modal.Actions>
      </Modal>
    )
)

const StatusModal = React.memo(
  ({ account, onConfirm, open, onClose }) =>
    account && (
      <Modal size='small' open={open} onClose={onClose}>
        <Modal.Header>Confirm change status of this account</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to change the status of this account{' '}
            <strong>{account.EmailAddress}</strong>? This will allow/disallow the
            account from being able to login to the developer portal.
          </p>
          <p>
            Only the owner of the Developer Portal can disable the account,
            through the Cognito console.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button negative onClick={onConfirm}>
            Change Status
          </Button>
        </Modal.Actions>
      </Modal>
    )
)

const UserPoolMFAModal = React.memo(
  ({ onConfirm, open, onClose, handleChange, MFAoption }) =>
  (
    <Modal size='small' open={open} onClose={onClose}>
      <Modal.Header>Confirm change MFA Settings of this user pool?</Modal.Header>
      <Modal.Content>
        <p>Current MFA config for this user pool: {MFAoption}</p>
        <input type="radio" value="ON" id="ON"
          onChange={handleChange} name="MFAoption" />
        <label for="ON">ON</label>
        <p></p>
        <input type="radio" value="OFF" id="OFF"
          onChange={handleChange} name="MFAoption" />
        <label for="OFF">OFF</label>
        <p></p>
        <input type="radio" value="OPTIONAL" id="OPTIONAL"
          onChange={handleChange} name="MFAoption" />
        <label for="OPTIONAL">OPTIONAL</label>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button negative onClick={onConfirm}>
          Change MFA settings
        </Button>
      </Modal.Actions>
    </Modal>
  )
)

const UserMFAModal = React.memo(
  ({ onConfirm, open, onClose, handleChange, UserMFAoption, UserPoolMFAoption }) =>
  (
    <Modal size='small' open={open} onClose={onClose}>
      <Modal.Header>Confirm change MFA Settings of this user?</Modal.Header>

      {UserPoolMFAoption === "ON" &&
        <Modal.Content>
          <p>Current MFA config for this user: {UserMFAoption}</p>
          <input type="radio" value="ON" id="ON"
            onChange={handleChange} name="MFAoption" />
          <label for="ON">ON (Authenticator App only for now)</label>
          <p></p>

        </Modal.Content>
      }
      {
        UserPoolMFAoption === "OFF" &&
        <Modal.Content>
          <p>Current MFA config for this user: {UserMFAoption}</p>
          <input type="radio" value="OFF" id="OFF"
            onChange={handleChange} name="MFAoption" />
          <label for="OFF">OFF</label>
        </Modal.Content>
      }
      <Modal.Content>
        <p>Current MFA config for this user: {UserMFAoption}</p>
        <input type="radio" value="ON" id="ON"
          onChange={handleChange} name="MFAoption" />
        <label for="ON">ON (Authenticator App only for now)</label>
        <p></p>
        <input type="radio" value="OFF" id="OFF"
          onChange={handleChange} name="MFAoption" />
        <label for="OFF">OFF</label>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button negative onClick={onConfirm}>
          Change User MFA settings
        </Button>
      </Modal.Actions>
    </Modal>
  )
)


const DeleteSuccessMessage = React.memo(({ account, dismiss }) => (
  <Message onDismiss={dismiss} positive>
    <Message.Content>
      Deleted account <strong>{account.EmailAddress}</strong>.
    </Message.Content>
  </Message>
))

const DeleteFailureMessage = React.memo(
  ({ account, errorMessage, dismiss }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        <p>
          Failed to delete account <strong>{account.EmailAddress}</strong>.
        </p>
        {errorMessage && <p>Error message: {errorMessage}</p>}
      </Message.Content>
    </Message>
  )
)
const PrivateSuccessMessage = React.memo(({ account, dismiss }) => (
  <Message onDismiss={dismiss} positive>
      {account.PrivateAccess?<Message.Content>Removed private access privileges from account <strong>{account.EmailAddress}</strong>.</Message.Content>:<Message.Content>Granted private access privileges to account <strong>{account.EmailAddress}</strong>.</Message.Content>}
  </Message>
))

const PrivateFailureMessage = React.memo(
  ({ account, errorMessage, dismiss }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        <p>
          Failed to grant private access to account <strong>{account.EmailAddress}</strong>.
        </p>
        {errorMessage && <p>Error message: {errorMessage}</p>}
      </Message.Content>
    </Message>
  )
)

const PromoteSuccessMessage = React.memo(({ account, dismiss }) => (
  <Message onDismiss={dismiss} positive>
    <Message.Content>
      Promoted account <strong>{account.EmailAddress}</strong>.
    </Message.Content>
  </Message>
))

const PromoteFailureMessage = React.memo(
  ({ account, errorMessage, dismiss }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        <p>
          Failed to promote account <strong>{account.EmailAddress}</strong>.
        </p>
        {errorMessage && <p>Error message: {errorMessage}</p>}
      </Message.Content>
    </Message>
  )
)

const ChangeStatusSuccessMessage = React.memo(({ account, dismiss }) => (
  <Message onDismiss={dismiss} positive>
    <Message.Content>
      Changed account status successfully!<strong>{account.EmailAddress}</strong>.
    </Message.Content>
  </Message>
))

const ChangeStatusFailureMessage = React.memo(
  ({ account, errorMessage, dismiss }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        <p>
          Failed to change status of account <strong>{account.EmailAddress}</strong>.
        </p>
        {errorMessage && <p>Error message: {errorMessage}</p>}
      </Message.Content>
    </Message>
  )
)

const ChangeMFASuccessMessage = React.memo(({ dismiss }) => (
  <Message onDismiss={dismiss} positive>
    <Message.Content>
      Changed MFA Settings successfully!
    </Message.Content>
  </Message>
))

const ChangeMFAFailureMessage = React.memo(
  ({ errorMessage, dismiss }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        <p>
          Failed to change MFA Settings
        </p>
        {errorMessage && <p>Error message: {errorMessage}</p>}
      </Message.Content>
    </Message>
  )
)

const SubscriptionsModal = React.memo(
  ({ account, open, onClose, APISWithusagePlans, selectedAccountSubscriptions, refreshAccounts }) =>
    account && (
      <Modal size='small' open={open} onClose={onClose}>
        <Modal.Header>Manage API Subscriptions for <strong>{account.FullName} ({account.EmailAddress})</strong> </Modal.Header>
        <Modal.Content>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>API Name</Table.HeaderCell>
                <Table.HeaderCell>API ID</Table.HeaderCell>
                <Table.HeaderCell>API Usage Plan ID</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {APISWithusagePlans.map(api =>
                <Table.Row key={"tr-" + api.APIId}>
                  <Table.Cell>{api.APIName}</Table.Cell>
                  <Table.Cell>{api.APIId}</Table.Cell>
                  <Table.Cell>{api.UsagePlanId}</Table.Cell>
                  <Table.Cell>{<SubscriptionButton account={account} currentAPI={api} selectedAccountSubscriptions={selectedAccountSubscriptions} refreshAccounts={refreshAccounts} />}</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={onClose}>Close</Button>
        </Modal.Actions>
      </Modal>
    )
)

const SwaggerAccess = React.memo(
  ({ account, open, onClose, APIs}) =>
    account && (
      <Modal size='small' open={open} onClose={onClose}>
        <Modal.Header>Manage API Services Access for <strong>{account.FullName} ({account.EmailAddress})</strong> </Modal.Header>
        <Modal.Content>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Service Name</Table.HeaderCell>
                {/* <Table.HeaderCell>APIs</Table.HeaderCell> */}
                <Table.HeaderCell>Allowed</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {/* {console.log(APIs)} */}
              {
                APIs.map(api =>
                  <Table.Row key={"tr-" + api.apis[0].id}>
                    {/* {console.log(api.apis)} */}
                    <Table.Cell>{api.apis[0].title}</Table.Cell>
                    {/* <Table.Cell>{api.APIs}</Table.Cell> */}
                    <Table.Cell collapsing>{<SwaggerAccessButton account={account} currentAPI={api.apis[0]} selectedSwaggerAccess={account.SwaggerAccess ? account.SwaggerAccess : []}></SwaggerAccessButton>}</Table.Cell>
                  </Table.Row>
                )
              }
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={onClose}>Save & Close</Button>
        </Modal.Actions>
      </Modal>
    )
)


const SubscriptionButton = observer(class SubscriptionButton extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const selectedAccountSubscriptions = this.props.selectedAccountSubscriptions;
    const currentAPI = this.props.currentAPI;

    let check = false;
    // if current selected account's subscriptions contains an api that matches with the current usage plan then show subscribed button
    selectedAccountSubscriptions.forEach(plan => { if (plan.id === currentAPI.UsagePlanId) (check = true) })

    return (
      check ? (
        <Button onClick={() => { unsubscribe(currentAPI.UsagePlanId, this.props.account.IdentityId); this.props.refreshAccounts(); }} negative>Unsubscribe</Button>
      ) : (
        <Button onClick={() => { subscribe(currentAPI.UsagePlanId, this.props.account.IdentityId); this.props.refreshAccounts(); }} positive>Subscribe</Button>
      )
    )
  }
})
async function updateSwaggerAccessClick(action, val, userid, email, title){
  let swaggerAccess = await AccountService.getSwaggerAccessForUser(userid)
  if(!swaggerAccess.result.includes(val)){
    swaggerAccess.result.push(val)
    updateSwaggerAccess(swaggerAccess.result, userid)
    try {
      AuditService.createAuditLog(`Granted Access to ${email}: ${title}`)
    } catch (error) {
        console.log(error);
    }
  }else{
    const apiIndex = swaggerAccess.result.indexOf(val) 
    swaggerAccess.result.splice(apiIndex, 1)
    updateSwaggerAccess(swaggerAccess.result, userid)
    try {
      AuditService.createAuditLog(`Removed Access for ${email}: ${title}`)
    } catch (error) {
        console.log(error);
    }
  }
}

// const SwaggerAccessButton = ({ selectedSwaggerAccess, currentAPI, account }) => {
//   const [check, setCheck] = useState(false)

//   // if current selected account's subscriptions contains an api that matches with the current usage plan then show subscribed button
//   selectedSwaggerAccess.forEach(api => { if (api === currentAPI.id) (setCheck(true)) })
  
//   return (
//     <Checkbox toggle onClick={(e) => { updateSwaggerAccessClick(e, currentAPI.id, account.UserId) }} defaultChecked={check}>Deny</Checkbox>
//   )
// }

const SwaggerAccessButton = observer(class SwaggerAccessButton extends React.Component {
  constructor(props) {
    super(props);
  }
  // refreshAccounts()
  render() {
    // console.log(this.props.account.UserId)
    // AccountService.getSwaggerAccessForUser(this.props.account.UserId)
    let selectedSwaggerAccess = this.props.selectedSwaggerAccess;
    // const selectedSwaggerAccess = AccountService.getSwaggerAccessForUser(this.props.account.UserId);
    const currentAPI = this.props.currentAPI;
    console.log(currentAPI)
    const account = this.props.account;
    let check = false;
    // if current selected account's subscriptions contains an api that matches with the current usage plan then show subscribed button
    selectedSwaggerAccess.forEach(api => { if (api === currentAPI.id) (check = true) })
    return (
      <Checkbox toggle onChange={() => { updateSwaggerAccessClick(check, currentAPI.id, account.UserId, account.EmailAddress.replace(' (you)', ''), currentAPI.title) }} defaultChecked={check}></Checkbox>
    )
  }
})

function loadUsageMetric(usagePlanId, canvasId, cognitoIdentityId) {
  fetchUsageMetrics(usagePlanId, cognitoIdentityId)
    .then((result) => {
      const data = mapUsageByDate(result.data, 'used')
      const ctx = document.getElementById(canvasId)

      const labels = data.map(d => new Date(d[0]).toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }))
      const used = data.map(d => d[1])
      const remaining = data.map(d => d[2])
      const max = Math.max(...used, ...remaining)

      const chartConfig = {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: 'Requests used (per month)',
              data: used,
              lineTension: 0,
              backgroundColor: '#00bfff',
              borderColor: '#00bfff',
              pointBackgroundColor: 'transparent',
              pointBorderColor: 'transparent',
              borderWidth: 2,
              pointRadius: 10,
              pointHoverRadius: 10

            },
            {
              label: 'Requests used (per month)',
              data: remaining,
              lineTension: 0,
              backgroundColor: 'transparent',
              borderColor: 'red',
              pointBackgroundColor: 'transparent',
              pointBorderColor: 'transparent',
              borderWidth: 2,
              pointRadius: 10,
              pointHoverRadius: 10
            }
          ]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                suggestedMax: max + (max * 0.02)
              }
            }]
          }
        }
      }

      // eslint-disable-next-line no-new
      let chart = new Chart(ctx, chartConfig);
      if (chart) {
        // just to get rid of no-use variabel error
      }

    })
    .catch((error) => {
      console.error(error)
    })
}


const Graph = (props) => {
  let cognitoIdentityId = props.account.IdentityId.split(":");
  cognitoIdentityId = cognitoIdentityId[1];
  const usagePlanId = props.currentAPI.UsagePlanId;
  const canvasId = `api-usage-chart-container-${usagePlanId}`;
  const selectedAccountSubscriptions = props.selectedAccountSubscriptions;
  let check = false;
  loadUsageMetric(usagePlanId, canvasId, cognitoIdentityId);
  selectedAccountSubscriptions.forEach(plan => {
    if (plan.id === usagePlanId) {
      check = true
    }
  })

  return (
    check ?
      <Grid.Column width={16} widescreen={8} key={usagePlanId} style={{ marginBottom: '40px' }}>
        <canvas id={canvasId} />
      </Grid.Column>
      :
      <i>User is not subscribed to this API.</i>
  )

}


const ViewMetricsModal = React.memo(
  ({ account, open, onClose, APISWithusagePlans, selectedAccountSubscriptions }) =>

    account && (
      <Modal size='small' open={open} onClose={onClose}>
        <Modal.Header>API Metrics for {account.FullName}({account.EmailAddress})</Modal.Header>
        <Modal.Content>
          <ul>
            {APISWithusagePlans.map(api =>
              <li key={"li-" + api.APIId}>
                <h5>{api.APIName}({api.APIId})</h5>
                <Graph account={account} currentAPI={api} selectedAccountSubscriptions={selectedAccountSubscriptions} />
              </li>
            )}
          </ul>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Close</Button>
        </Modal.Actions>
      </Modal>
    )
)