// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, {useState, useEffect, useRef, useLayoutEffect} from 'react'
import { Menu, Dropdown, DropdownDivider, Image, Header, Icon, Accordion, Sidebar, Button } from 'semantic-ui-react'

import {
  isAdmin,
  isAuthenticated,
  isRegistered,
  logout
} from 'services/self'

// mobx
import { observer } from 'mobx-react'

// router
import { Navigate, redirect, useLocation, useNavigate } from 'react-router-dom'

// components
import MenuLink from 'components/MenuLink'
import { store } from 'services/state'
import { pagesConfig } from 'services/get-fragments'
import {NavBarLinks} from './NavBarLinks'
import Scroll from 'react-scroll'
const ScrollLink = Scroll.ScrollLink


//sry but we are trying to make your life easier if you want to edit navbar, edit the NavBarLinks.js so it will automatically
//update the mobile and desktop view. :D ~ Perry and Nigel(interns 2023)
export const NavBar = observer((props) => {
  const navigate = useNavigate()
  const email = store.user && store.user.email
  const name = store.fullName
  const location = useLocation()
  const pages = pagesConfig.custom && pagesConfig.custom.filter(p => p.Visible && p.Position.navbar)

  const NavPages = ({ pages }) => {
    const CollapsingDropdown = () => 
      <Dropdown item text='More' className='max-xl:flex hidden'>
        <Dropdown.Menu>
        <Dropdown.Header>More Pages</Dropdown.Header>

          {pages.map(page => <Dropdown.Item><MenuLink to={page.PageURL} className='border-0 p-0 '>{page.Name}</MenuLink></Dropdown.Item>)}
        </Dropdown.Menu>
      </Dropdown> 
    
    const DropdownStatic = () => 
      <Dropdown item text='More'>
        <Dropdown.Menu>
        <Dropdown.Header>More Pages</Dropdown.Header>

          {pages.map(page => <Dropdown.Item><MenuLink to={page.PageURL} className='border-0 p-0 '>{page.Name}</MenuLink></Dropdown.Item>)}
        </Dropdown.Menu>
      </Dropdown> 
    
    const CollapsingLink = () => pages.map(page => <MenuLink to={page.PageURL} className='border-0 text-center h-20  xl:flex hidden'>{page.Name}</MenuLink>)
    const LinkStatic = () =>  pages.map(page => <MenuLink to={page.PageURL} className='border-0 text-center h-20 '>{page.Name}</MenuLink>)
    if (pages.length == 1) {
      return <LinkStatic/>
    }
    if (pages.length < 3) {
      return (
        <>
          <CollapsingDropdown/>
          <CollapsingLink/>
        </>
      )
    }
    return <DropdownStatic/>
  }
  return(
    <Menu className='min-h-min border-0 px-16 px-0 mx-auto my-4 w-screen'>
      <MenuLink to='/' className='border-0 text-center h-20 '>
        {
          // <img src='/custom-content/nav-logo.png' className='h-full w-full'/>
          <Image centered size='small' src='/custom-content/images/nav-logo.png' className='xl:mx-20 rounded-md pb-0 mx-5 mb-0 sm:w-64 w-48'></Image>
        }
      </MenuLink>
      {/* This is for Desktop View */}
      <Menu.Menu position='right' className='border-0 Desktop-menu lg:flex space-x-8 lg:visible hidden'>
        {/* {location.pathname.startsWith('/apis') && <MenuLink to='/apis/errorcodes' className='border-0 text-center h-20 '>Error Code</MenuLink>} */}
        {
          NavBarLinks.map((e)=>{
            if(e.dropdown){
              if(e.name === 'Admin Panel'){
                return(
                  isAuthenticated() && isAdmin()?
                    // <Dropdown item text={e.name}>
                    //   <Dropdown.Menu>
                    //   {
                    //     e.dropdown.map(dropdown => <MenuLink to={dropdown.url} className='border-0 '>{dropdown.name}</MenuLink> )
                    //   }
                    //   </Dropdown.Menu>
                    // </Dropdown>
                    <MenuLink to='/admin' className='border-0 '>Admin Panel</MenuLink>
                    :null
                    )
              }else if(e.name === 'user'){
                return(
                  isAuthenticated() &&
                    <>
                    <Dropdown item trigger= {
                      <>
                        <span className='me-4'>{name}</span>
                        <Icon name='user'></Icon>
                      </>
                    } 
                    className='font-bold'>
                      <Dropdown.Menu>
                      <Dropdown.Header content={email}/>
                      {
                        e.dropdown.map(dropdown => <MenuLink to={dropdown.url} className='border-0 '>{dropdown.name}</MenuLink>)
                      }
                      </Dropdown.Menu>
                    </Dropdown>
                    </>
                    )
              }else{
                return(
                  <Dropdown item text={e.name}>
                    <Dropdown.Menu>
                    {
                      e.dropdown.map(dropdown => <Dropdown.Item><MenuLink to={dropdown.url} className='border-0 '>{dropdown.name}</MenuLink></Dropdown.Item>)
                    }
                    </Dropdown.Menu>
                  </Dropdown>
                )
              }
            }else{
              if(e.name === 'Login / Sign up'){
                return (
                  !isAuthenticated() && <MenuLink to={e.url} className='border-0 text-center h-20 '>{e.name}</MenuLink>
                )
              }else if(e.name === 'More'){
                return pages && pages.length > 0 && <NavPages pages={pages}/>
              }else{
                return (
                    <MenuLink to={e.url} className='border-0 text-center h-20 '>{e.name}</MenuLink>
                )
              }
            }
          })
        }
      </Menu.Menu>
      {/* Mobile View */}
      <Menu.Menu position='right' className='border-0 Mobile-Menu lg:hidden flex visible place-items-center'>        
      <Button onClick={props.onShow} icon='bars' className='bg-transparent'>
        
      </Button>
      </Menu.Menu>

    </Menu>
  )}
)

export default NavBar
