import React, {useEffect, useState} from 'react';
import { Dropdown, Pagination } from 'semantic-ui-react';
// import Pagination from 'components/Pagination';

const Paginate = ({ defaultActivePage, onPageChange, items }) => {
   const [itemsPerPage, setItemsPerPage] = useState(10)
   const [activePage, setActivePage] = useState(defaultActivePage || 1)
   const [totalPages, setTotalPages] = useState(undefined)
   
   const pageChangeHandler = (activePage) => {
      const indexOfFirstItem = (activePage-1) * itemsPerPage // 0-index
      const indexOfLastItem = activePage * itemsPerPage
      onPageChange(indexOfFirstItem, indexOfLastItem)
   }
   
   const rowsOptions = [
      {
         key:  5,
         text: '5',
         value: 5
      },
      {
         key: 10,
         text: '10',
         value: 10
      },
      {
         key: 20,
         text: '20',
         value: 20
      },
   ]
   
   useEffect(() => {
      setActivePage(defaultActivePage || 1)
      setTotalPages(Math.ceil(items.length/itemsPerPage))
      pageChangeHandler(activePage)
   }, [items])

   useEffect(() => {
      pageChangeHandler(activePage)
   }, [activePage, totalPages])

   const handleSelect = (e, data) => {
      const indexOfFirstItem = (activePage-1) * itemsPerPage
      const newPage = Math.ceil((indexOfFirstItem+1)/data.value)
      if (newPage != activePage) {
         setActivePage(newPage)
      }
      setItemsPerPage(data.value)
      setTotalPages(Math.ceil(items.length/data.value))
   }

   return (
      <div className='px-4 w-full'>
         Items per page
         <Dropdown
            compact
            className='mx-2'
            placeholder='number of rows to display'
            selection
            defaultValue={itemsPerPage}
            options={rowsOptions} 
            onChange={handleSelect}
            />
         { items.length &&
            <span className='mx-4'>{1 + (activePage-1) * itemsPerPage}-{activePage * itemsPerPage < items.length ? activePage * itemsPerPage : items.length} of {items.length} items</span>
         }
         <Pagination className="float-right" onPageChange={(e, {activePage}) => setActivePage(activePage)} totalPages={totalPages ?? 1} activePage={activePage}/>
      </div>
   )
}
 
export default Paginate;