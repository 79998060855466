import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Label } from 'semantic-ui-react'
import { changePassword } from 'services/self'
import { store } from 'services/state'

const ChangePassword = (props) => {
    const accessToken = store.accessToken;//localStorage.getItem('Access_Token');

    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [isPasswordError, setIsPasswordError] = useState(false);

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    }
    const handleNewPassword2Change = (e) => {
        setNewPassword2(e.target.value);
    }
    const handleCurrentPasswordChange = (e) => {
        setCurrentPassword(e.target.value);
    }

    useEffect(() => {
        setIsPasswordError(newPassword !== newPassword2);
    }, [newPassword, newPassword2])


    const handleSubmit = (e) => {
        e.preventDefault();
        changePassword({ token: accessToken, oldpassword: currentPassword, newpassword: newPassword2 }).then(() => {

        }).catch(() => {

        });
    }

    return (
        <Modal open={props.show} >
            <Modal.Header>Change Password</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>Current Password</label>
                        <input placeholder='Current Password' type="password" value={currentPassword} onChange={handleCurrentPasswordChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>New Password</label>
                        <input placeholder='New Password' type="password" onChange={handleNewPasswordChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>Re-enter New Password</label>
                        <input placeholder='Re-enter New Password' type="password" onChange={handleNewPassword2Change} />
                        {
                            isPasswordError && <Label basic color='red' pointing>
                                Passwords does not match!
                            </Label>
                        }
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color='black' onClick={() => props.onClose()}>
                    Cancel
                </Button>
                <Button
                    content="Change Password"
                    onClick={handleSubmit}
                    positive
                    disabled={isPasswordError || newPassword === "" || newPassword2 === "" || currentPassword === ""}
                />
            </Modal.Actions>
        </Modal>

    );
}



export default ChangePassword