import React, { useEffect, useState } from 'react'
import AdminNavbar from 'components/AdminNavbar'
import { apiGatewayClientWithCredentials } from 'services/api'
import { Card, Menu, Segment, Table, Header } from 'semantic-ui-react'
import * as AccountService from 'services/accounts'

const formatDate = isoDateString =>
new Intl.DateTimeFormat('en-SG', {
  dateStyle: 'medium',
  timeStyle: 'short'
}).format(new Date(isoDateString))

export default function AdminDashboard() {
    const [success, setSuccess] = useState(null)
    const [failure, setFailure] = useState(null)
    const [totalRequests, setTotalRequests] = useState(null)
    const [requestLogs, setRequestLogs] = useState([])
    const [auditLogs, setAuditLogs] = useState([]) 
    const [totalLogins, setTotalLogins] = useState(null)
    const [active, setActive] = useState('Request logs')
    function processLogs(logs) {
        const processedLogs = logs
        .sort((a,b)=>(a.LogTime>b.LogTime)?-1:1)
        .slice(0,10)
        .map((log, index) => {
            return {...log, Index: index+1, LogTime: formatDate(Number(log.LogTime))} 
        })

        if (processedLogs.length === 0) return processedLogs
        return [ 
            ...processedLogs,
            ...Array(10-processedLogs.length).fill({})
        ]
    }

    function getData(){
        apiGatewayClientWithCredentials().then(app=>
            app.get('/adminDashboard', {}, {}, {})
        ).then(res => {
            if(res.status === 200){
                setSuccess(res.data.requestLogs.filter(x=>(x.Status>=200&&x.Status<400)&&parseInt(x.LogTime)>=Date.now()-86400000).length)//86400000 == 24 hours
                setFailure(res.data.requestLogs.filter(x=>(x.Status>=400)&&parseInt(x.LogTime)>=Date.now()-86400000).length)//86400000 == 24 hours
                setTotalRequests(res.data.requestLogs.filter(x=>parseInt(x.LogTime)>=Date.now()-86400000).length)
                setRequestLogs(processLogs(res.data.requestLogs))
                setAuditLogs(processLogs(res.data.auditLogs))
            }
        }).catch(err=>{
            console.error(err)
        })
        AccountService.fetchRegisteredAccounts().then( async(res)=>{
            // getUserInfo(res)
            Promise.all(res.map(async x=>{
                await AccountService.getUserInfo(x.UserId).then(res=>{
                    return x=res.result
                })
                return x
            })).then((res)=>{
                setTotalLogins(res.filter(user=>user?Date.parse(user.LastLogin)>=Date.now()-86400000:false).length)
            })
        })
        // console.log('test')
    }
    useEffect(()=>{
        getData()
    }, [])
    // function getUserInfo(users){
    // }
    return (
        <div className="min-h-[65vh]">
            <div className='p-8 lg:px-20 px-12'>
                <Header as='h1'>Last 24h Metrics</Header>
                <Card.Group itemsPerRow={4}>
                    <Card>
                        <Card.Header className='text-lg text-center'>
                            Total Requests:
                        </Card.Header>
                        <Card.Content>
                            <p className='text-center text-8xl '>{totalRequests&&totalRequests}</p>
                        </Card.Content>
                    </Card>
                    <Card color='green'>
                        <Card.Header className='text-lg text-center'>
                            2XX:
                        </Card.Header>
                        <Card.Content>
                            <p className='text-center text-8xl '>{success&&success}</p>
                        </Card.Content>
                    </Card>
                    <Card color='red'>
                        <Card.Header className='text-lg text-center'>
                            4XX/5XX:
                        </Card.Header>
                        <Card.Content>
                            <p className='text-center text-8xl '>{failure&&failure}</p>
                        </Card.Content>
                    </Card>
                    <Card>
                        <Card.Header className='text-lg text-center'>
                            Total Logins:
                        </Card.Header>
                        <Card.Content>
                            <p className='text-center text-8xl '>{totalLogins&&totalLogins}</p>
                        </Card.Content>
                    </Card>
                </Card.Group>
                <Menu attached='top' tabular>
                    <Menu.Item
                        name='Request Logs'
                        active={active==='Request logs'}
                        onClick={()=>setActive('Request logs')}
                    />
                    <Menu.Item
                        name='Audit Logs'
                        active={active==='Audit logs'}
                        onClick={()=>setActive('Audit logs')}
                    />
                </Menu>
                <Segment attached='bottom'>
                    {active ==='Request logs'?
                        <Table className=''>
                            <Table.Header fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell>Index</Table.HeaderCell>
                                    <Table.HeaderCell>Time</Table.HeaderCell>
                                    <Table.HeaderCell>Email</Table.HeaderCell>
                                    <Table.HeaderCell>Full Name</Table.HeaderCell>
                                    <Table.HeaderCell>Service</Table.HeaderCell>
                                    <Table.HeaderCell>Url</Table.HeaderCell>
                                    <Table.HeaderCell>Status</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {requestLogs.length > 0  ? requestLogs.map(i => 
                                    Object.keys(i).length ?
                                    <Table.Row key={i.Index}>
                                        <Table.Cell>{i.Index}</Table.Cell>
                                        <Table.Cell>{i.LogTime}</Table.Cell>
                                        <Table.Cell>{i.UserId}</Table.Cell>
                                        <Table.Cell>{i.FullName}</Table.Cell>
                                        <Table.Cell>{i.Service}</Table.Cell>
                                        <Table.Cell>{i.Url}</Table.Cell>
                                        <Table.Cell>{i.Status}</Table.Cell>
                                    </Table.Row>:
                                    <Table.Row>
                                        {Object.keys(requestLogs[0]).map(() => <Table.Cell>&nbsp;</Table.Cell>)}
                                    </Table.Row>
                                ) :
                                <Table.Row>
                                    <Table.Cell colSpan='7' className='text-center'>
                                    No Requests in the last 24 hours
                                    </Table.Cell>
                                </Table.Row> }
                            </Table.Body>
                        </Table>
                    :<Table className=''>
                        <Table.Header fullWidth>
                            <Table.Row>
                                <Table.HeaderCell>Index</Table.HeaderCell>
                                <Table.HeaderCell>Time</Table.HeaderCell>
                                <Table.HeaderCell>Email</Table.HeaderCell>
                                <Table.HeaderCell>Full Name</Table.HeaderCell>
                                <Table.HeaderCell>Action</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {auditLogs.length > 0  ? auditLogs.map(i => 
                                Object.keys(i).length ?
                                <Table.Row>
                                    <Table.Cell>{i.Index}</Table.Cell>
                                    <Table.Cell>{i.LogTime}</Table.Cell>
                                    <Table.Cell>{i.UserId}</Table.Cell>
                                    <Table.Cell>{i.FullName}</Table.Cell>
                                    <Table.Cell>{i.Action}</Table.Cell>
                                </Table.Row>:
                                <Table.Row>
                                    {Object.keys(requestLogs[0]).map(() => <Table.Cell>&nbsp;</Table.Cell>)}
                                </Table.Row>
                            ) :
                            <Table.Row>
                                <Table.Cell colSpan='5' className='text-center'>
                                No logs yet
                                </Table.Cell>
                            </Table.Row> }
                        </Table.Body>
                    </Table>
                    }
                </Segment>
            </div>
        </div>
)
}
