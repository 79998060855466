// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, {useState, useEffect, useRef, useLayoutEffect} from 'react'
import { Menu, Dropdown, DropdownDivider, Image, Header, Icon, Accordion, Sidebar, Button, Card, Segment, Transition } from 'semantic-ui-react'

import {
  isAdmin,
  isAuthenticated,
  isRegistered,
  logout
} from 'services/self'

// mobx
import { observer } from 'mobx-react'

// router
import { Navigate, redirect, useLocation, useNavigate } from 'react-router-dom'

// components
import MenuLink from 'components/MenuLink'
import { store } from 'services/state'
import { pagesJSON } from 'services/get-fragments'
import {NavBarLinks} from './NavBarLinks'
import Scroll from 'react-scroll'


//sry but we are trying to make your life easier if you want to edit navbar, edit the NavBarLinks.js so it will automatically
//update the mobile and desktop view. :D ~ Perry and Nigel(interns 2023)
export const AdminNavbar = observer((props) => {
    const [active , setActive] = useState('home')
    useEffect(() => {
        setActive(window.location.pathname)
    }, [window.location.pathname])
  return(  
    <div className="flex min-h-[100vh] max-w-full">
        <div className='hover:w-64 w-20 min-w-[20px] overflow-hidden transition-all hover:delay-700'>
            <Menu pointing secondary vertical fluid className='h-full'>
            { NavBarLinks.filter(x=>x.name==='Admin Panel').map((e)=>{
                return e.dropdown.map(dropdown => 
                    <MenuLink
                        to={dropdown.url}
                        className={`text-left overflow-hidden py-4 px-6 hover:bg-gray-300 ${active === dropdown.url && 'bg-gray-300'}`}
                        active={active === dropdown.url}
                    >
                        <div className='w-64'>
                            <Icon.Group size='large'>
                                <Icon name={dropdown.icon} color='white' className='w-8 me-6 float-left'/>
                            </Icon.Group>
                            <span>{dropdown.name}</span>
                        </div>
                    </MenuLink>
                )
                
            })}
            </Menu>
        </div>
        <div className='min-w-0 w-full'>
            {props.children}
        </div>
    </div>
    )
})

export default AdminNavbar
