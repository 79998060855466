import { apiGatewayClientWithCredentials as getApiGatewayClient, apiGatewayClient } from 'services/api'
import { cognitoUserPoolId, cognitoClientId } from 'services/api'
import { store } from 'services/state'


const url = ([first, ...rest], ...parts) =>
  first + parts.map((p, i) => encodeURIComponent(p) + rest[i]).join('')


const fetch = (method, target, params = {}) =>
  getApiGatewayClient()
    .then(apiGatewayClient => apiGatewayClient[method](target, {}, params, {}))
    .then(response => response.data)
    .catch(error => { throw error && (error.data || error) })


export const createAuditLog = async(action) => {
  try {
    const auditLog = {
        Email: store.user.email,
        FullName: store.fullName,
        Action: action
    }
    await fetch('post', '/audit', { auditLog })
    console.log(auditLog)
  } catch (error) {
    console.log(error)
  }
}

export const createRequestLog = async(request) => {
  // console.log(request)
  const requestLog = {
    Email: store.user.email,
    FullName: store.fullName,
    Url: request.Url,
    Status: request.Status,
    Service: request.Service
  }
    await fetch('post', '/request', { requestLog })
}

