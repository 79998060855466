import React, {useState, useEffect, useRef} from 'react'

//react utils
import { Table, Button, Message, Placeholder, Header, Modal, Form, Input, Label,Select } from 'semantic-ui-react'

import { apiGatewayClientWithCredentials } from 'services/api'
import _ from 'lodash'

//components
import SampleFlows from 'components/SampleFlows'


import * as AuditService from 'services/auditing'
import * as ImageService from 'services/images'

const domain = _.get(window, 'config.fetchDomain')

export const ImageManagement = () => {
    const [images, setImages] = useState([])
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState([])

    let fileInput = useRef(null)

    const getImages = () => {
        setLoading(true)
        ImageService.getAllImages()
        .then(({ result }) => {
            setImages(result)
        })
        .catch(error => console.error(error))
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        getImages()
    }, [])

    const UploadModal = () => {
        const [folder, setFolder] = useState('sampleflows/')
        const [prefix, setPrefix] = useState('')
        const [fullPrefix, setFullPrefix] = useState(`/${folder}{prefix}`)
        const [imageUrls, setImageUrls] = useState([])

        const [modalOpen, setModalOpen] = useState(false)

        const imageUrlHandler = (e, data) => {
            switch (e.target.id || data.id) {
                case 'folder':
                    setFolder(data.value)
                    setFullPrefix(`${data.value+prefix}`)
                    break;
                case 'prefix':
                    setPrefix(e.target.value)
                    setFullPrefix(`${folder+e.target.value}`)
                    break;
                case 'images':
                    const files = []
                    for (const file of e.target.files) {
                        files.push(file.name)
                        _.some(images, {URL: `custom-content/${fullPrefix}/${file.name}`})
                    }
                    setImageUrls(files)
                    break;
                default:
                    break;
            }
        }
        const uploadSampleFlows = (event) => {
            event.preventDefault() 
            setErrors([])

            const form = event.target
            const formData = new FormData(form)
            const formJson = Object.fromEntries(formData.entries())
            
            const files = fileInput.current.files 
            
            let anyFailures
            
            if (!formJson.prefix) {  
                setErrors(current => [...current, 'Prefix is required!'])
                anyFailures = true
            }
            
            if (files.length < 1) { 
                setErrors(current => [...current, "You did not select any image"])
                anyFailures = true
            }
    
            if (anyFailures) {
              return
            }
    
            ;[].forEach.call(files, file => {
              const reader = new window.FileReader()
              reader.onload = (e) => {
                const img = e.target.result.split(",")[1]
                const url = `/${fullPrefix}/${file.name}`
                // console.log(url)
                apiGatewayClientWithCredentials()
                .then((app) => app.post('/images', {}, { img, url }, {}))
                .then((res) => {
                    if (res.status === 200) {
                        getImages()
                        AuditService.createAuditLog(`Uploaded Image: ${url}`)
                    }
                })
              }
              reader.readAsDataURL(file)
            }) 
        }

        const options = [
            { key: 'images', text: 'images/', value: 'images/' },
            { key: 'sampleflows', text: 'sampleflows/', value: 'sampleflows/' }
        ]
        return (
        <>
            <Modal
            closeIcon
            closeOnEscape
            closeOnDimmerClick
            size='large'
            open={modalOpen}
            openOnTriggerClick
            onClose={() => {setImageUrls([]); setErrors([]); setModalOpen(false)}}
            trigger={
                <Button color='blue' floated='right' onClick={() => setModalOpen(true)}>
                Add Image(s)
                </Button>
            }
            >
                <Modal.Header>Select .jpeg, .jpg, .png or .svg files</Modal.Header>
                <Modal.Content>
                    <>
                    <Header>Add Sample Flows</Header>
                    <Form onSubmit={(e) => uploadSampleFlows(e)}>
                        <Form.Field>
                        <label htmlFor='prefix'>Prefix<span className='text-red-500'>*</span></label>
                        <Input action type='text' id="prefix" name='prefix' actionPosition='left' onChange={(e) => {imageUrlHandler(e)}}>
                            <Select className="w-40" id='folder' compact options={options} value={folder} onChange={(e, data) => {imageUrlHandler(e, data)}}/>
                            <input />
                        </Input>
                        </Form.Field>    
                        <Form.Field>
                        <label htmlFor='images'>Select Images<span className='text-red-500'>*</span></label>
                        <input type='file' id='images' name='files' accept='image/jpeg,image/png,image/svg+xml' multiple onChange={(e) => {imageUrlHandler(e)}} ref={fileInput}/>
                        </Form.Field>
                        <Message info>
                        Images: 
                        <br />
                        {imageUrls.map(url => 
                            <p>
                            {domain}/custom-content/{fullPrefix}/
                            <span className='font-bold'>{url}</span>
                            {_.some(images, {URL: `custom-content/${fullPrefix}/${url}`}) && 
                                <Label color='red' pointing='left'>
                                Image exists. Will overwrite
                                </Label>
                            }
                            <br />
                            </p>
                        )}
                        </Message>
                        {!!errors.length &&
                        <Message color='red' list={errors}/>}
                        <Button type='submit' disabled={!(prefix && imageUrls.length > 0)} fluid>Upload</Button>
                    </Form>
                    </>
                </Modal.Content>
            </Modal>
        </>
        )
    }

    
    
    return(
        <>
            <div className='p-8 lg:px-20 px-12'>
                <div className=''>
                    <Header as='h1'>Images</Header>
                    <SampleFlows 
                    loading={loading}
                    onDelete={getImages}
                    toolbar={
                        <>
                            <Button content="Refresh" onClick={() => getImages()} floated='right'/>
                            <UploadModal/>
                        </>
                    } 
                    images={images}></SampleFlows>
                </div>
            </div>
        </>
    )

}
export default ImageManagement
