// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useLayoutEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

// react-router
import { Link } from 'react-router-dom'

// mobx
import { observable } from 'mobx'

// markdown parsing
import frontmatter from 'front-matter'
import { marked } from 'marked'
import * as _ from 'lodash'

import * as PagesService from 'services/pages'

// Markdown files
// import md from '/custom-content/content-fragments/ms-pages'

export const fragments = observable({})
export const pagesConfig = observable({})

const domain = _.get(window, 'config.fetchDomain')

export const loadPagesConfig = () => {
  try {
    PagesService.getPagesConfig('custom')
    .then(({ result }) => {
      pagesConfig['custom'] = [...result]
      result.forEach(page => {
        loadHtml(`${domain}/custom-content/content-fragments/md-pages/${page.PageURL}.md`, page.PageURL)
      })
    })
    .finally(() => pagesConfig['UpdatedAt'] = new Date().toISOString())
  } catch (error) {
    console.error(error)
  }
  try {
    PagesService.getPagesConfig('managed')
    .then(({ result }) => {
      pagesConfig['managed'] = [...result]
      result.forEach( page => {
        fetch(`${domain}/custom-content/content-fragments/md-pages/${page.PageURL}.md`).then((response) => {
          if (!response.ok) throw new Error('Something went wrong');
        })
        .then(() => {
          loadHtml(`${domain}/custom-content/content-fragments/md-pages/${page.PageURL}.md`, page.PageURL)
        })
        .catch((error) => {
          console.warn(`Falling back to window.fetch for ${page.PageURL}.md`)
          loadHtml(`${domain}/custom-content/content-fragments/${page.PageURL}.md`, page.PageURL) 
        })
      })
    })
    .finally(() => pagesConfig['UpdatedAt'] = new Date().toISOString())

  } catch (error) {
    console.error(error)
  }
}

export const reloadPagesConfig = () => {
  try {
    PagesService.getPagesConfig('custom')
    .then(({ result }) => {
      pagesConfig['custom'] = [...result]
    })
  } catch (error) {
    console.error(error)
  }
}

// export const loadFragments = () => {
//   loadHtml('/custom-content/content-fragments/home.md', 'home')
//   loadHtml('/custom-content/content-fragments/starter-guide.md', 'starter-guide')//load starter guide markdown file
//   loadHtml('/custom-content/content-fragments/terms-of-use.md', 'terms-of-use')//load TOS markdown file
//   loadHtml('/custom-content/content-fragments/steps-to-call-api.md', 'steps-to-call-api')
// }

// export const lazyloadFragments = async (PageURL, Name) => {
//   loadHtml(PageURL, Name)
//   // loadHtmlS3(pagename, pagename)
// }

/**
 * Pre-load the custom-content markdown, parses its frontmatter, and renders it as JSX. This method
 * is asynchronous and doesn't actually return anything -- instead, it acts on a MobX Observable --
 * the fragment. The fragment is an object with a `jsx` property that maps to the rendered
 * component, and any number of other properties collected from the front-matter.
 *
 * @param {String} path   Path to the file to load in. Should be a markdown file.
 * @param {String} fragment   Name of the fragment. Determines where rendered data gets stored.
 *
 * @returns {Object}
 */

// function loadHtmlS3(pagename, fragment) {
//   // if we want to display a loading indicator, this would be where
//   fragments[fragment] = { jsx: () => null }
//   PagesService.getPageAnon(pagename)
//   .then(({ data }) => {
//     const text = data.result
//     const parsedMarkdown = frontmatter(text)
//     const html = marked(parsedMarkdown.body, {
//       headerPrefix: 'header-',
//       silent: true
//     })

//     fragments[fragment] = {
//       jsx: () => <ShowHTML html={html} />,
//       ...parsedMarkdown.attributes
//     }
//   })
//   .catch( error => {
//     console.warn(`Falling back to window.fetch for ${pagename}.md`)
//     loadHtml(`/custom-content/content-fragments/${pagename}.md`, `${fragment}`)

//   })
// }

function loadHtml(path, fragment) {
  // if we want to display a loading indicator, this would be where
  fragments[fragment] = { jsx: () => null }

  window.fetch(path).then(response => response.text().then(text => {
    const parsedMarkdown = frontmatter(text)
    const html = marked(parsedMarkdown.body, {
      headerPrefix: 'header-',
      silent: true
    })
    fragments[fragment] = {
      jsx: () => <ShowHTML html={html} />,
      ...parsedMarkdown.attributes
    }
  }))
}

export function ShowHTML({ html }) {
  /** @type {import("react").MutableRefObject<HTMLDivElement>} */
  const ref = useRef()

  // Easier to do it here than to use a separate `useMemo` hook.
  useLayoutEffect(() => {
    // Normal links will work, but the cause a full page reload. We don't want that, so we replace
    // them with react-router Links. However, replacing external links with react-router Links
    // causes them to not work at all. We don't want that either, so we attempt to determine if a
    // link is external or not, and replace them as appropriate.

    const mountPoints = []
    const links = ref.current.getElementsByTagName('a')

    for (let i = 0; i < links.length; i++) {
      const link = links[i]
      // if absolute url, use an `a` tag
      // https://stackoverflow.com/a/19709846/4060061
      if (/^(?:[a-z]+:)?\/\//i.test(link.href)) {
        link.target = '_blank'
        link.rel = 'noopener noreferrer'
      } else {
        // Replace links with react-router-dom tags so that they route correctly
        const span = document.createElement('span')
        // If there's CSS, don't listen to it.
        span.style.setProperty('display', 'inline', 'important')
        ReactDOM.render(<Link
          to={link.href}
          target={link.target}
          dangerouslySetInnerHTML={{ __html: link.innerHTML }}
        />, span)
        link.replaceWith(span)
        mountPoints.push(span)
      }
    }

    // Gracefully unmount any mount points that were added
    return () => {
      mountPoints.forEach(elem => {
        ReactDOM.render(null,elem)
      })
    }
  }, [])

  return <div ref={ref} dangerouslySetInnerHTML={{ __html: html }}/>
}
