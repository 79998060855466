import React, {useEffect, useState} from 'react'
import TimeoutModal from 'components/TimeoutModal';
import Cookies from 'js-cookie'
import { useIdleTimer } from 'react-idle-timer'
import {Modal, Button} from 'semantic-ui-react'


export const HandleTimeout = (props)=> {
    const [showModal, setShowModal] = useState(false)
    const [open, setOpen] = useState(false)
    const [remaining, setRemaining] = useState(10_000)
    const handleOnIdle = event => {
        if(Cookies.get('cognitoUserPoolId')){
            Cookies.remove('cognitoUserPoolId')//remove cookies so even if user tries to bypass the modal by reloading the will get kicked
            Cookies.remove('userId')
            setOpen(false) // close prompt for input
            setShowModal(true) // show modal to inform user that they have been logged out
        }
      }
    
    const handleOnPrompt = event => {
        setOpen(true) // prompt for input by user 
    }
    const handleStillHere = () => {
        activate()  //if user returns from idling when prompted. Resets the idle timer
        setOpen(false)  // close prompt modal
    }
    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))//show how much time user if left before kicking them out
        }, 500)

        return () => {
            clearInterval(interval)//clear previous intervals
        }
    })

    
    const { getRemainingTime, activate } = useIdleTimer({
        timeout: 1000 * 60 * 5,//5 minutes
        onIdle: handleOnIdle, // show modal when user is idle
        onPrompt: handleOnPrompt, // show prompt to check if user is active
        debounce: 500, // take input every 500ms
        promptBeforeIdle: 1000*30//prompt for input 30 seconds before kicking them out
    })
    const handleLogout = ()=>{
        setShowModal(false) // hide idle modal
        props.onLogout(); // log user out
    }    
    return (
    <div>
        <TimeoutModal 
        showModal={showModal} 
        handleLogout={handleLogout}
        />
        <Modal open={open}>
            <Modal.Header>Are you still here?</Modal.Header>
            <Modal.Content>Logging out in {remaining} seconds</Modal.Content>
            <Modal.Actions><Button onClick={handleStillHere}>Im still here</Button></Modal.Actions>
        </Modal>
    </div>
    )
}

export default HandleTimeout
