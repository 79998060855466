import React, {useState, useEffect, useRef} from 'react'

//react utils
import { Link, useParams, Navigate } from 'react-router-dom'
import { Header, Segment, Icon } from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { observer } from 'mobx-react'

//components
import { fragments } from 'services/get-fragments'


export const MarkdownPage = observer(() => {
    const params = useParams()
    const selectedFragment = fragments[params.pagename]
    if (!selectedFragment.jsx()) {
        console.error('Error loading page fragment');
        return (
            <Segment placeholder className='mx-4 px-8 pb-20 min-h-[75vh]'>
                <Header icon>
                    <Icon name='exclamation triangle' />
                    Error loading page fragment
                </Header>
            </Segment>
        )
    }
    return(
        <>
            <div className='max-w-7xl mx-auto px-8 pb-20 min-h-[75vh]'> 
                <Header as='h1' className='my-12 text-blue-700'>{selectedFragment? selectedFragment.header: null}</Header>
                {/* <Header as='h1' className='my-12 text-blue-700 font-thin'>{selectedFragment? selectedFragment.header: null}</Header> */}
                <div className=''>{selectedFragment? <selectedFragment.jsx/>: null}</div>
            </div>
        </>
        )
    
})
export default MarkdownPage
