import React, {useState, useEffect, useRef} from 'react'

//react utils
import { useParams, Navigate, useNavigate } from 'react-router-dom'
import { Table, Button, Header, Input, Placeholder, Dropdown } from 'semantic-ui-react'

//components
import { apiGatewayClientWithCredentials } from 'services/api'

import _ from 'lodash'
import Paginate from 'components/Paginate'

const formatDate = isoDateString =>
new Intl.DateTimeFormat('en-SG', {
  dateStyle: 'medium',
  timeStyle: 'short'
}).format(new Date(isoDateString))

export const Logs = () => {
    const [rawData, setRawData] = useState([])
    const [auditlogs, setAuditlogs] = useState([])
    const [displayedLogs, setDisplayedLogs] = useState([])
    const [loading, setLoading] = useState(true)

    const { log } = useParams();
    const navigate = useNavigate();


    const getLogs = (log) => {
        setLoading(true)
        apiGatewayClientWithCredentials()
        .then((app) => app.get(`/${log}`, {}, {}, {}))
        .then((res) => {
            if (res.status === 200) {
                const formattedData = res.data.logs.sort((a,b)=>(a.LogTime>b.LogTime)?-1:1).map((log, index) => { return {...log, Index: index+1, LogTime: formatDate(Number(log.LogTime))} })
                setRawData(formattedData)
                setAuditlogs(formattedData)
                setLoading(false)
            }
        })
    }
    
    useEffect(()=>{
        getLogs(log)
    }, [log])

    const search = (value) => {
        const filterFn = log => {
            for (const key in log) {
                if (Object.hasOwnProperty.call(log, key)) {
                    const result = log[key].toString().toLowerCase().includes(value.toLowerCase())
                    if (result) return result
                }
            }
            return false
        }
        let queriedData = rawData
        if (value) {
            queriedData = rawData.filter(filterFn)
        } 
        setAuditlogs(queriedData)
    }

    const handlePageChange = (indexOfFirstLog, indexOfLastLog) => {
        const itemsPerPage = indexOfLastLog - indexOfFirstLog
        const pagedLogs = auditlogs.slice(indexOfFirstLog, indexOfLastLog)
        setDisplayedLogs(pagedLogs.length > 0 ? [...pagedLogs, ...Array(itemsPerPage-pagedLogs.length).fill({})]: pagedLogs)
        // itemsPerPage - displayedLogs.length && setDisplayedLogs(l => l.push(...Array(itemsPerPage-l.length).fill({})))
    }

    return(
        <>
            <div className='p-8 lg:px-20 px-12'>
                <div className=''>
                    <Header as='h1'><Dropdown inline options={[{text: 'Audit Logs', key: 1, value: 'audit'}, {text: 'Request Logs', key: 2, value: 'request'}]} onChange={(e, data) => {navigate(`/admin/logs/${data.value}`)}} value={log}/></Header>
                    <div className='mb-4 space-x-4'>
                        <Input className='' icon='search' placeholder='Search...' iconPosition='left' onChange={(e) => {search(e.target.value)}}/>
                        <Button className='float-right' content="Refresh" onClick={() => {getLogs(log)}} floated='right'/>
                    </div>
                    <Table className='' >
                    { log == "audit" ?
                        <>
                            <Table.Header fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell>No.</Table.HeaderCell>
                                    <Table.HeaderCell>Time</Table.HeaderCell>
                                    <Table.HeaderCell>Email</Table.HeaderCell>
                                    <Table.HeaderCell>Full Name</Table.HeaderCell>
                                    <Table.HeaderCell>Action</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                { !loading && (displayedLogs.length > 0  ? displayedLogs.map((i) => 
                                    Object.keys(i).length ?
                                    <Table.Row key={i.Index}>
                                        <Table.Cell>{i.Index}</Table.Cell>
                                        <Table.Cell>{i.LogTime}</Table.Cell>
                                        <Table.Cell>{i.UserId}</Table.Cell>
                                        <Table.Cell>{i.FullName}</Table.Cell>
                                        <Table.Cell>{i.Action}</Table.Cell>
                                    </Table.Row> :
                                    <Table.Row>
                                        {Object.keys(displayedLogs[0]).map(() => <Table.Cell>&nbsp;</Table.Cell>)}
                                    </Table.Row>
                                ) :
                                <Table.Row>
                                    <Table.Cell colSpan='5' className='text-center'>
                                    No logs yet
                                    </Table.Cell>
                                </Table.Row>) } 
                                {/* { !loading && currentLogs.length < auditlogs.length

                                    <Table.Row>
                                        <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                        <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                        <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                        <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                    </Table.Row>
                                } */}
                                { loading && 
                                <Table.Row>
                                    <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                    <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                    <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                    <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                    <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                </Table.Row>}
                            </Table.Body>
                        </> :
                        <>
                            <Table.Header fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell>No.</Table.HeaderCell>
                                    <Table.HeaderCell>Time</Table.HeaderCell>
                                    <Table.HeaderCell>Email</Table.HeaderCell>
                                    <Table.HeaderCell>Full Name</Table.HeaderCell>
                                    <Table.HeaderCell>Service</Table.HeaderCell>
                                    <Table.HeaderCell>Url</Table.HeaderCell>
                                    <Table.HeaderCell>Status</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                { !loading && (displayedLogs.length > 0  ? displayedLogs.map(i => 
                                    Object.keys(i).length ?
                                    <Table.Row key={i.Index}>
                                        <Table.Cell>{i.Index}</Table.Cell>
                                        <Table.Cell>{i.LogTime}</Table.Cell>
                                        <Table.Cell>{i.UserId}</Table.Cell>
                                        <Table.Cell>{i.FullName}</Table.Cell>
                                        <Table.Cell>{i.Service}</Table.Cell>
                                        <Table.Cell>{i.Url}</Table.Cell>
                                        <Table.Cell>{i.Status}</Table.Cell>
                                    </Table.Row>:
                                    <Table.Row>
                                        {Object.keys(displayedLogs[0]).map(() => <Table.Cell>&nbsp;</Table.Cell>)}
                                    </Table.Row>
                                ) :
                                <Table.Row>
                                    <Table.Cell colSpan='7' className='text-center'>
                                    No logs yet
                                    </Table.Cell>
                                </Table.Row>) } 
                                { loading && 
                                <Table.Row>
                                    <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                    <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                    <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                    <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                    <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                    <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                    <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                </Table.Row>}
                            </Table.Body>
                        </>
                    }
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan={7}>
                                    <Paginate
                                        onPageChange={handlePageChange}
                                        items={auditlogs}
                                        />
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </div>
            </div>
        </>
    )

}

export default Logs
