// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState, useEffect} from 'react'
import {
    useNavigate,
    useParams
  } from 'react-router-dom';

// semantic-ui
import { Button, Dimmer, Form,  Card, Segment, Divider, Icon } from 'semantic-ui-react'

// store
import { getApi, updateUsagePlansAndApisList } from 'services/api-catalog'
import { apiGatewayClientWithCredentials } from 'services/api'
// utilities
import * as AuditService from 'services/auditing'
import * as ImageService from 'services/images'

//components
import LoaderBar from 'components/Loader'
import MarkdownEditor from 'components/MarkdownEditor'
import SampleFlows from 'components/SampleFlows'

export default function ApiJSON() {
    function uploadSwaggerUpdate(event) {
        let swagger = swaggerObj
        console.log(swagger)

        if(JSON.stringify(wholeSwaggerObj.info.description) === JSON.stringify(swagger)){
            alert('No changes made')
            navigate('/admin/apis')
            return 
        }
        wholeSwaggerObj.info.description = swagger
        setLoading(true)//set loading to true so user cannot click anything until it has finished updating
        apiGatewayClientWithCredentials()
            .then((app) => 
                app.post('/admin/catalog/updateGenericApi', {}, { id: apiId, swagger:JSON.stringify(wholeSwaggerObj) }, {})
            ).then((res) => {
                if (res.status === 200) {
                setLoading(false)
                alert("Updated Successfully!")
                updateUsagePlansAndApisList(true)
                navigate('/admin/apis')
            }
        })
        try {
            AuditService.createAuditLog(`Updated Description for swagger: ${Name}`)
        } catch (error) {
            console.error(error);
        }
        
    }

    const { apiId, stage } = useParams();
    const navigate = useNavigate();
    const [swaggerObj, setSwaggerObj] = useState(undefined);
    const [Name, setName] = useState();
    const [wholeSwaggerObj, setWholeSwaggerObj] = useState(undefined)
    const [loading, setLoading] = useState(false);
    const [loadingSampleFlows, setLoadingSampleFlows] = useState(false)
    const [images, setImages] = useState([])


    useEffect(()=>{
        getApi(apiId || 'ANY', true, stage, true)
        .then(async(api) => {
            setSwaggerObj(api.swagger.info.description);//set swagger description
            setName(api.swagger.info.title);//set swagger title
            setWholeSwaggerObj(api.swagger)
          })
        getImages()
    }, [])

    const getImages = () => {
        setLoadingSampleFlows(true)
        ImageService.getSampleflows()
        .then(({ result }) => {
            setImages(result)
        })
        .catch(error => console.error(error))
        .finally(() => setLoadingSampleFlows(false))
    }
    return (
        <div className='p-8 lg:px-20 px-12'>
            <div className='mb-4'>
                <Button onClick={()=>navigate(-1)} icon>
                    <Icon name='chevron left' />
                    Back
                </Button>
            </div>
            <Dimmer active={loading} size='massive'>
                <LoaderBar active={loading} message={"Updating... Please Wait"} value='1'/>
            </Dimmer>
            <Card.Group>
                <Card className='border-2 rounded-md w-full'>
                    <Card.Header className='text-center m-3 font-bold text-xl'>Uploaded Sample Flows</Card.Header>
                    <Card.Content>
                    <SampleFlows 
                    loading={loadingSampleFlows}
                    onDelete={getImages}
                    images={images}
                    disableDelete
                    toolbar={
                        <>
                            <Button content="Refresh" onClick={() => getImages()} floated='right'/>
                        </>
                    }></SampleFlows>
                    </Card.Content>
                </Card>
                <Divider></Divider>
                <Card className='border-2 rounded-md w-full min-w-0'>
                    {/* <Form onSubmit={(e)=>uploadSwaggerUpdate(e)}> */}
                    <Card.Header className='text-center m-3 font-bold text-xl'>{Name}</Card.Header>
                    <Card.Content>
                        <Form.Field>
                            {swaggerObj&&
                                <MarkdownEditor value={swaggerObj} onChange={setSwaggerObj}/>  
                            }
                        </Form.Field>
                    </Card.Content>
                    <Card.Content extra>
                        <Button className='float-right m-0 mr-5 mb-5' onClick={(e)=>uploadSwaggerUpdate(e)}>Update</Button>
                    </Card.Content>
                    {/* </Form> */}
                </Card>
            </Card.Group>
        </div>
    )
}
