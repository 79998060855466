
export const EmailAddress = {
  id: 'EmailAddress',
  title: 'Email address',
  render: account => account.EmailAddress,
  ordering: {
    iteratee: 'EmailAddress'
  },
  filtering: {
    accessor: 'EmailAddress'
  }
}

export const IsAdmin = {
  id: 'IsAdmin',
  title: 'Administrator',
  render: account => account.IsAdmin ? 'Yes' : 'No',
  ordering: {
    iteratee: 'IsAdmin'
  },
  filtering: {
    accessor: 'IsAdmin'
  }
}

export const changeStatus = {
  id: 'isDisachangeStatusbled',
  title: 'Status',
  render: account => account.Enabled ? "Yes":'No' ,
  ordering: {
    iteratee: 'changeStatus'
  },
  filtering: {
    accessor: 'Status'
  }
}

export const MFASettings = {
  id: 'MFASettings',
  title: 'MFASettings',
  render: account => account.MFASettings
}

export const DateRegistered = {
  id: 'DateRegistered',
  title: 'Date registered',
  render: account => formatDate(account.DateRegistered),
  ordering: {
    iteratee: 'DateRegistered'
  }
}

export const RegistrationMethod = {
  id: 'RegistrationMethod',
  title: 'Registration method',
  render: account => account.RegistrationMethod
}

export const ApiKeyId = {
  id: 'ApiKeyId',
  title: 'API key ID',
  render: account => account.ApiKeyId,
  filtering: {
    accessor: 'ApiKeyId'
  }
}

export const Promoter = {
  id: 'Promoter',
  title: 'Promoter',
  render: ({ PromoterUserId, PromoterEmailAddress }) =>
    PromoterUserId ? `${PromoterEmailAddress} (${PromoterUserId})` : '',
  filtering: {
    accessor: ({ PromoterUserId, PromoterEmailAddress }) =>
      PromoterUserId ? `${PromoterEmailAddress} ${PromoterUserId}` : ''
  }
}

export const Inviter = {
  id: 'Inviter',
  title: 'Inviter',
  render: ({ InviterUserId, InviterEmailAddress }) =>
    InviterUserId ? `${InviterEmailAddress} (${InviterUserId})` : '',
  filtering: {
    accessor: ({ InviterUserId, InviterEmailAddress }) =>
      InviterUserId ? `${InviterEmailAddress} ${InviterUserId}` : ''
  }
}

export const DatePromoted = {
  id: 'DatePromoted',
  title: 'Date promoted',
  render: ({ DatePromoted }) => (DatePromoted ? formatDate(DatePromoted) : ''),
  ordering: {
    iteratee: 'DatePromoted'
  }
}

export const DateRequested = {
  id: 'DateRequested',
  title: 'Date requested',
  render: account => formatDate(account.DateRequested),
  ordering: {
    iteratee: 'DateRequested'
  }
}

export const DateInvited = {
  id: 'DateInvited',
  title: 'Date invited',
  render: account => formatDate(account.DateInvited),
  ordering: {
    iteratee: 'DateInvited'
  }
}

const DATE_TIME_FORMATTER = new Intl.DateTimeFormat('en-SG', {
  dateStyle: 'medium',
  timeStyle: 'short'
})

export const Organisation = {
  id: 'Organisation',
  title: 'Organisation',
  render: account => account.Organisation,
  ordering: {
    iteratee: 'Organisation'
  },filtering: {
    accessor: 'Organisation'
  }
}

export const Project = {
  id: 'Project',
  title: 'Project',
  render: account => account.Project,
  ordering: {
    iteratee: 'Project'
  },filtering: {
    accessor: 'Project'
  }
}
export const PrivateAccess = {
  id: 'PrivateAccess',
  title: 'Private',
  render: account => account.PrivateAccess ? 'Allowed' : 'Denied',
  ordering: {
    iteratee: 'PrivateAccess'
  }
}

export const FullName = {
  id: 'FullName',
  title: 'FullName',
  render: account => account.FullName,
  ordering: {
    iteratee: 'FullName'
  }
}

export const LastLogin = {
  id: 'LastLogin',
  title: 'Last Login',
  render: account => account.LastLogin?formatDate(account.LastLogin):null,
  ordering: {
    iteratee: 'LastLogin'
  }
}

const formatDate = isoDateString =>
  DATE_TIME_FORMATTER.format(new Date(isoDateString))
