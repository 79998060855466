// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

//react utils
import React, {useState, useEffect} from 'react';
import { Header, Card } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { observer } from 'mobx-react'

//components
import ApisMenu from 'components/HomePageApisMenu'
import { fragments } from 'services/get-fragments'

export const StarterGuide = observer(()=> {
    const starterFragment = fragments['starter-guide']
    return ( 
      <>
      <div className='max-w-7xl mx-auto px-16 pb-20'>
        <div className=''>
          <Header as='h1' className='my-12 text-blue-700 font-thin'>Getting Started</Header>
          <Card.Group className='flex flex-col'>
            <div className='p-4 flex'>
              <Card fluid className='rounded-2xl shadow-[0_0_14px_rgba(0,44,218,0.2)]'>
                <Card.Content className='p-8'> 
                  <Card.Header className='my-4 text-xl font-bold'>What is CCDP</Card.Header>
                  <Card.Description className='my-4 overflow-y-auto h-[100px] scroll-smooth'>
                    <div className='text-xl'>
                      <ReactMarkdown
                        children={starterFragment && starterFragment.WhatIsCCDP}
                      />
                    </div>
                  </Card.Description>
                </Card.Content>
              </Card>
            </div>
            <div className='p-4 flex'>
              <Card fluid className='rounded-2xl shadow-[0_0_14px_rgba(0,44,218,0.2)]'>
                <Card.Content className='p-8'>
                  <Card.Header className='my-4 text-xl font-bold'>When to use CCDP</Card.Header>
                  <Card.Description className='my-4 overflow-y-auto h-[100px] scroll-smooth'>
                    <div className='text-xl'>
                      <ReactMarkdown
                        children={starterFragment && starterFragment.WhenToUseCCDP}
                      />
                    </div>
                  </Card.Description>
                </Card.Content>
              </Card>
            </div>
            <div className='p-4 flex'>
              <Card fluid className='rounded-2xl shadow-[0_0_14px_rgba(0,44,218,0.2)]'>
                <Card.Content className='p-8'>
                  <Card.Header className='my-4 text-xl font-bold'>Supported Network and Browsers</Card.Header>
                  <Card.Description className='my-4 overflow-y-auto h-[100px] scroll-smooth'>
                    <div className='text-xl'>
                      <ReactMarkdown
                        children={starterFragment && starterFragment.SupportedNetworkAndBrowsers}
                      />
                    </div>
                  </Card.Description>
                </Card.Content>
              </Card>
            </div>
          </Card.Group>
          <Header as='h1' className='my-12 text-blue-700 font-thin'>Get Started</Header>
          <div className="container p-6 text-[rgba(0,0,0,0.68)]">
              <div className='border-l-4 border-dotted border-indigo-600 block relative'>
                <div className='ml-10'>
                  <span className='border-4 rounded-full px-2 ml-0 text-3xl border-indigo-600 absolute -left-[20px] bg-white text-indigo-600 text-center'>1</span>
                    <h5 className='text-3xl font-normal'>
                      <span className='font-bold'>Browse API Library & Select the API </span>
                      You Would Like To Integrate With
                    </h5>
                    <br />
                    <h6 className='text-xl font-normal'>API Document Navigation</h6>
                      <ApisMenu page='starterGuide'/>
                    <hr className='my-8'/>
                  <span className='border-4 rounded-full px-2 ml-0 text-3xl border-indigo-600 absolute -left-[20px] bg-white text-indigo-600 text-center'>2</span>
                    <h5 className='text-3xl font-normal my-2'>Request for UAT API Keys</h5>
                        <div>
                          <p><span className='font-bold '>2.1</span>  Send us your request through our Contact Us Form or contact CCDP through your digital reps.</p>
                          <br/>
                          <p><span className='font-bold '>2.2</span>  Once request has been approved, we will perform IP address whitelisting between requestor's server and CCDP.</p>
                          <br/>
                          <p><span className='font-bold '>2.3</span>  CCDP will provide UAT keys for you to proceed with development and testing.</p>
                          <br/>
                          <p>Note: Requestor will need to engage source systems for ID creation(if required), test data and UAT support</p>
                        </div>
                        <hr className='my-8'/>
                  <span className='border-4 rounded-full px-2 ml-0 text-3xl border-indigo-600 absolute -left-[20px] bg-white text-indigo-600 text-center'>3</span>
                    <h5 className='text-3xl font-normal my-2'>Finally, Request for Production Onboarding</h5>
                    <div>
                      <p><span className='font-bold '>3.1</span>  After you have completed the testing, send us your request for Production Onboarding through our Contact Us Form or contact CCDP through your digital reps.</p>
                      <br/>
                      <p><span className='font-bold '>3.2</span>  Send us a copy of the Acknowledgement Certificate.</p>
                      <br/>
                      <p><span className='font-bold '>3.3</span>  Perform IP address whitelisting between requestor's server and CCDP.</p>
                      <br/>
                      <p><span className='font-bold '>3.4</span>  CCDP will provide production keys for you to proceed with production API calls.</p>
                      <br/>
                    </div>
                </div>
              </div>
                    <hr className='my-8'/>
            <div>
            </div>
            <h5 className='text-3xl font-normal my-6'>How to Call CCDP API(s)</h5> 
            <div>
              See detailed <Link to="/steps-to-call-api" className="font-bold">steps on how to call CCDP API(s).</Link>
            </div>
            <hr className='my-16'/>
          </div>
        </div>
      </div>
      </>
    )
          
  })

export default StarterGuide