// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

import { observer } from 'mobx-react'

import Sidebar from 'components/Sidebar/Sidebar'
import SidebarHeader from 'components/Sidebar/SidebarHeader'
import MenuLink from 'components/MenuLink'
import { isRegistered } from 'services/self'

export const SideNav = observer(() =>isRegistered() && (
  <Sidebar className='h-screen'>
    <SidebarHeader>My Dashboard</SidebarHeader>
    <MenuLink to='/dashboard/profile'>Profile</MenuLink>
    <MenuLink to='/dashboard/apis'>API</MenuLink>
  </Sidebar>
))
