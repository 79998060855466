// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { useLocation } from 'react-router-dom'

// mobx
import { observer } from 'mobx-react'
import { CardGroup, Divider, Image, Menu } from 'semantic-ui-react'
import { pagesConfig } from 'services/get-fragments'


// components
import MenuLink from 'components/MenuLink'

export const Footer = observer(() => {
    const location = useLocation()
    const pages = pagesConfig.custom && pagesConfig.custom.filter(p => p.Visible && p.Position.footer)
    return (
        <div className="bg-gray-900 m-0 py-10 place-content-center relative bottom-0 mt-auto">
            <CardGroup className='xl:grid xl:grid-cols-2 flex flex-col content-center mx-0 px-0'>
                <div className='pb-0 mb-0 flex'>
                    <Image centered-size='mini' src='/custom-content/images/synapxe-logo.png' className='lg:mx-20 rounded-md mx-5 max-w-xs'></Image>
                </div>
                <div className='xl:text-right mx-0 lg:px-20 w-full max-xl:pt-8'>
                    <Menu className='xl:float-right bg-transparent my-0 py-0 md:grid md:grid-flow-col md:auto-cols-max md:auto-rows-max flex flex-col'>
                        <MenuLink to='/faq' className='text-white font-bold my-0 pb-0'>FAQ</MenuLink>
                        <MenuLink to='/RequestAPI' className='text-white font-bold my-0 pb-0 '>Contact Us</MenuLink>
                        <MenuLink to='/terms-of-use' className='text-white font-bold my-0 pb-0 '>Terms of Use</MenuLink>
                        <MenuLink className='text-white font-bold my-0 pb-0'><a href="https://www.synapxe.sg/privacy-policy/" target='_blank'>Privacy Policy</a></MenuLink>
                        {location.pathname.includes('/apis') && <MenuLink to='/apis/errorcodes' className='text-white font-bold my-0 pb-0'>Error Codes</MenuLink>}
                        {pages && pages.map(p => <MenuLink to={p.PageURL} className='text-white font-bold my-0 pb-0'>{p.Name}</MenuLink>)}
                    </Menu>
                </div>
            </CardGroup>
            <Divider className='mt-10 mx-10 border-slate-500 border-t-0' />
            <p className='text-slate-500 lg:float-right xl:text-right text-center lg:px-20 px-5'>Copyright &#169; Synapxe Pte. Ltd. All Rights Reserved.</p>
        </div>
    )

})

export default Footer
