import React, { useRef } from 'react'
import CodeEditor from '@uiw/react-textarea-code-editor';
import CodeMirror from '@uiw/react-codemirror';
import { markdown, markdownLanguage } from "@codemirror/lang-markdown";
import { languages } from "@codemirror/language-data";
import { createTheme } from '@uiw/codemirror-themes';
import { tags as t } from '@lezer/highlight';
import rehypePrism from "rehype-prism-plus";
// import rehypeRewrite from "rehype-rewrite";

const myTheme = createTheme({
    theme: 'light',
    settings: {
      background: '#ffffff',
      backgroundImage: '',
      foreground: '#000000',
      caret: '#5d00ff',
      selection: '#036dd626',
      selectionMatch: '#036dd626',
      lineHighlight: '#8a91991a',
      gutterBackground: '#fff',
      gutterForeground: '#8a919966',
    },
    styles: [
      { tag: t.comment, color: '#787b8099' },
      { tag: t.variableName, color: '#0080ff' },
      { tag: [t.string, t.special(t.brace)], color: '#000000' },
      { tag: t.number, color: '#000000' },
      { tag: t.bool, color: '#75baff' },
      { tag: t.null, color: '#75baff' },
      { tag: t.keyword, color: '#000000' },
      { tag: t.operator, color: '#000000' },
      { tag: t.className, color: '#f1c232' },
      { tag: t.definition(t.typeName), color: '#75baff' },
      { tag: t.typeName, color: '#75baff' },
      { tag: t.angleBracket, color: '#000000' },
      { tag: t.tagName, color: '#c9005f' },
      { tag: t.attributeName, color: '#bf9000' },
    ],
  });
export default function MarkdownEditor(props) {
    var count = props.value.match(/\n/g || [])?props.value.match(/\n/g || []).length+1:1
    let string = '';
    if(count < 10){
      string = '\n'.repeat(10-count)
    }
    return (
        // <CodeEditor
        //     data-color-mode="light"
        //     className='min-h-[50vh] '
        //     value={props.value}
        //     language="markdown"
        //     placeholder="Start Typing..."
        //     onChange={(e)=>props.onChange(e.target.value)}
        //     padding={25}
        //     rehypePlugins={[
        //         [rehypePrism, { ignoreMissing: true }],
        //       ]}
        //     style={{
        //         fontSize: 13,
        //         backgroundColor: "#FFFFFF",
        //         fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
        //     }}
        // />
        <CodeMirror
        style={{
            flexGrow: 1
        }}
        value={props.value + string}
        extensions={[
            markdown({ base: markdownLanguage, codeLanguages: languages })
        ]}
        onChange={(e)=>props.onChange(e)}
        minHeight='50vh'
        id='test'
        maxHeight='80vh'
        theme={myTheme}
        />
    );
}
