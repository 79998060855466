import React, {useState} from "react"

// semantic-ui
import { Card, Form, Message, Button, List, Icon, Popup, Divider } from "semantic-ui-react"

//Component
import { useNavigate, Link } from 'react-router-dom'

import { register, verifyPassword } from 'services/self'
import { toTitleCase } from 'services/misc'

import PasswordRequirements from 'components/PasswordRequirements'
import PasswordRequirementsPopup from 'components/PasswordRequirementsPopup'

export default function SignUp() {   
    const [firstName, setFirstName] = useState('')
    const [errFirstName, setErrFirstName] = useState(false)

    const [lastName, setLastName] = useState('')
    const [errLastName, setErrLastName] = useState(false)

    const [email, setEmail] = useState('')
    const [errEmail, setErrEmail] = useState(false)

    const [password, setPassword] = useState('')
    const [errPassword, setErrPassword] = useState(false)
    
    const [passwordC, setPasswordC] = useState('')
    const [errPasswordC, setErrPasswordC] = useState(false)

    const [organization, setOrganization] = useState('')
    const [errOrganization, setErrOrganization] = useState(false)

    const [project, setProject] = useState('')
    const [errProject, setErrProject] = useState(false)

    const [consent, setConsent] = useState(false)
    const [errConsent, setErrConsent] = useState(false)

    const [formLoading, setFormLoading] = useState(false)
    const [errorMsgs, setErrorMsgs] = useState([])

    const navigate = useNavigate()
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMsgs([])
        setFormLoading(true)
        setErrPassword(false)
        setErrPasswordC(false)

        const errMessageTemplate = {
            firstName: () => setErrFirstName("Please fill in your first name"),
            lastName: () => setErrLastName("Please fill in your last name"),
            organization: () => setErrOrganization("Please fill in your organisation name"),
            project: () => setErrProject("Please fill in your project name"), 
            email: () => setErrEmail("Invalid email address"), 
            password: () => setErrPassword("Password field must not be empty"), 
            passwordC: () => setErrPasswordC("Password field must not be empty"),
            consent: () => setErrConsent("Please accept our terms and conditions and privacy policy"),
        }

        let anyErrors = false
        const inputs = {firstName, lastName, organization, project, email, password, passwordC, consent}
        
        Object.keys(inputs).forEach(key => {
            if (!inputs[key]) {
                errMessageTemplate[key]()
                anyErrors = true
            }
        })
        
        const [ verifyResult ] = verifyPassword(password)

        if (!verifyResult) {
            setErrPassword(current => current ? current : "Password is not strong enough")
            anyErrors = true
        }
        if (password !== passwordC) {
            setErrPassword(current => current ? current : true)
            setErrPasswordC('The password you entered does not match')
            anyErrors = true
        }

        if (anyErrors) return setFormLoading(false)

        if (firstName && lastName && email && password && passwordC && organization && project && consent && verifyResult ) {
            await register({ name:firstName+' '+lastName, email, password, organization, project }, (err, data) => {
                if (err) {
                    setErrorMsgs([err.message])
                    return
                }
                navigate({
                    pathname: '/confirmation',
                    search: `?email=${email}`
                }, { state: { message: "Check your email for verification code" } })
            })
        }        
        setFormLoading(false)
    } 
	return (
        <>
			<div className="max-w-7xl mx-auto px-4 sm:px-16 pb-20 pt-8 min-h-[calc(100vh-245px)]">
                <div className="lg:max-w-3xl max-w-md mx-auto">
                    <Card className="bg-gray-200 shadow-md mx-auto" fluid>
                        <Card.Content>
                            <Card.Header className="text-xl text-center m-5">Sign Up</Card.Header>
                            <Form className="m-5" onSubmit={handleSubmit} error={errorMsgs.length} loading={formLoading}>
                                <Form.Group widths='equal'>
                                    <Form.Input
                                        error={errFirstName} 
                                        label={<label>First Name <span className='text-red-500'>*</span></label>}
                                        placeholder='First Name' 
                                        value={firstName} 
                                        onChange={(e, data) => {setFirstName(data.value); setErrFirstName(false)}}/>
                                    <Form.Input 
                                        error={errLastName} 
                                        label={<label>Last Name <span className='text-red-500'>*</span></label>}
                                        placeholder='Last Name' 
                                        value={lastName} 
                                        onChange={(e, data) => {setLastName(data.value); setErrLastName(false)}}/>
                                </Form.Group>
                                <div className="grid grid-cols-1 gap-x-2 sm:grid-cols-2 max-sm:mb-[1em]">
                                    <Form.Input 
                                        error={errOrganization} 
                                        label={<label>Organisation Name <span className='text-red-500'>*</span></label>}
                                        placeholder='Organisation Name' 
                                        value={organization}
                                        onChange={(e, data) => {setOrganization(data.value); setErrOrganization(false)}}
                                        />
                                    <Form.Input 
                                        error={errProject} 
                                        label={<label>Project Name <span className='text-red-500'>*</span></label>} 
                                        placeholder='Project Name' 
                                        value={project}
                                        onChange={(e, data) => {setProject(data.value); setErrProject(false)}}
                                        />
                                </div>
                                <Form.Input 
                                    type="email"
                                    error={errEmail} 
                                    label={<label>Email Address <span className='text-red-500'>*</span></label>}
                                    placeholder='Email Address' 
                                    value={email} 
                                    onChange={(e, data) => {setEmail(data.value); setErrEmail(false)}}/>
                                <Form.Input 
                                    autoComplete="new-password"
                                    type='password'
                                    error={errPassword} 
                                    label={<PasswordRequirementsPopup/>}
                                    placeholder='Password' 
                                    value={password} 
                                    onChange={(e, data) => {setPassword(data.value); setErrPassword(false)}}/>
                                <PasswordRequirements password={password} error={errPassword}/>
                                <Form.Input 
                                    autoComplete="new-password"
                                    type='password'
                                    error={errPasswordC} 
                                    label={<label>Confirm Password <span className='text-red-500'>*</span></label>}
                                    placeholder='Confirm Password' 
                                    value={passwordC} 
                                    onChange={(e, data) => {setPasswordC(data.value); setErrPasswordC(false)}}/>
                                <Form.Checkbox 
                                    tabIndex="0"
                                    error={errConsent}
                                    label={<label>I agree to the <Link target="_blank" to="/terms-of-use" className="font-bold underline text-indigo-500">Terms and Conditions<Icon name="external alternate"/></Link> and <Link target="_blank" to="https://www.ihis.com.sg/Privacy_Policy/" className="font-bold underline text-indigo-500">Privacy Policy<Icon name="external alternate"/></Link> <span className='text-red-500'>*</span></label>}
                                    checked={consent} 
                                    onChange={(e, data) => {setConsent(data.checked); setErrConsent(false)}}/>
                                <Message
                                error
                                list={errorMsgs}
                                />
                                
                                <Button className="bg-indigo-500" type='submit' fluid disabled={!(firstName && lastName && email && password && passwordC && organization && project && consent)}>Submit</Button>
                            </Form>
                        </Card.Content>
                    </Card>
                    <Divider horizontal>Or</Divider>
                    <p className="text-center">Have an account? <Link className="text-indigo-500" to="/login">Login now.</Link></p>
                </div>
            </div>
		</>
	)
}

// Hide sign up form with coming soon text
/*export default function SignUp() {
    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-16 pb-20 pt-8 min-h-[calc(100vh-245px)]">
            <div className="lg:max-w-3xl max-w-md mx-auto">
                <Card className="bg-gray-200 shadow-md mx-auto" fluid>
                    <Card.Content>
                        <Card.Header className="text-xl text-center m-5">Coming Soon...</Card.Header>
                    </Card.Content>
                </Card>
                <Divider horizontal>Or</Divider>
                <p className="text-center">Have an account? <Link className="text-indigo-500" to="/login">Login now.</Link></p>
            </div>
        </div>
    )
}*/