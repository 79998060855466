// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// swagger-ui
import "swagger-ui-react/swagger-ui.css";

// semantic-ui
import { List, Popup, Icon } from 'semantic-ui-react'

// services
import { getPasswordRequirements } from 'services/self'

export default function PasswordRequirementsPopup(props) {
    return (
        <label>
            {typeof props.content === 'string' ? props.content : 'Password' } <span className='text-red-500'>*</span>
            <Popup
                trigger={<Icon name='question' size='small' circular className="ms-4"/>}
                position='bottom left'>
            Password Requirements:
            <List bulleted items={getPasswordRequirements(false)}/>
            </Popup>
        </label>
    )
}
