import React, {useState, useEffect} from "react"
// semantic-ui
import { Header, Image, Card, Form, Message, Button, Input } from "semantic-ui-react"

import * as queryString from 'query-string'

//Component
import { useNavigate, useLocation } from 'react-router-dom'
import { addNotification } from 'components/AlertPopup'

import * as AccountService from 'services/accounts'
import { confirmSignUp, initiateAuth, resendCode } from 'services/self'
import { result } from "lodash"

export default function ConfirmSignUp() {   
    const location = useLocation()
    const state = location.state ? location.state : {}
    const navigate = useNavigate()

    const { email, Qcode } = queryString.parse(location.search)
    const [code, setCode] = useState(Qcode ?? "")
    const [errorMsgs, setErrorMsgs] = useState([])
    const [message, setMessage] = useState(state.message ?? "")
    const [formLoading, setFormLoading] = useState(false)
    const [resend, setResend] = useState("Resend")
    window.history.replaceState({}, document.title)
    const handleSubmit = async (event) => {
        event.preventDefault()
        setFormLoading(true)

        await confirmSignUp({ email, code }, async (err, data) => {
            setFormLoading(false)
            if (err) {
                // console.log(err);
                setErrorMsgs([err.message])
                return
            }
            // console.log(data);
            navigate("/login", { state: { message: "Successfully confirmed account." } })
        })     
    }
    const handleResend = async (event) => {
        event.preventDefault()
        document.getElementById('resendBtn').disabled = true
        await resendCode({email}, async(err, data)=>{
            if(err){
                setErrorMsgs([err.message])
                return
            }
            setResend("Sent")
            setTimeout(function(){
                setResend("Resend")
                document.getElementById('resendBtn').disabled = false
            }, 30000)

            // navigate("/login", {state: { message: "Successfully confirmed account"}})
        })
    }
	return (
        <>
			<div className="max-w-7xl mx-auto px-4 sm:px-16 pb-20 pt-8">
                <div className="lg:max-w-xl max-w-md mx-auto">
                    <Card className="bg-gray-200 shadow-md mx-auto" fluid>
                        <Card.Content>
                            <Card.Header className="text-xl text-center m-5">Confirmation</Card.Header>
                            <Form className="m-5" onSubmit={handleSubmit} error={errorMsgs.length != 0} success={message} loading={formLoading}>
                                <Message
                                success
                                content={message}
                                />
                                <Form.Field>
                                    <label>Email Address</label>
                                    <input type="email" placeholder='Email Address' value={email} readOnly className="disabled"/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Code</label>
                                    <Input 
                                        placeholder='Code' 
                                        value={code} 
                                        onChange={(event) => setCode(event.target.value)}
                                        // actionPosition='right'
                                        action={{
                                            content: resend,
                                            onClick: (event) => handleResend(event),
                                            id: 'resendBtn'
                                        }}
                                    />
                                </Form.Field>
                                <Message
                                error
                                list={errorMsgs}
                                />
                                {/* <Button onClick={(event)=>{handleResend(event)}} id='resendBtn'>{resend}</Button> */}
                                <Button className="bg-indigo-500" type='submit' fluid>Submit</Button>
                            </Form>
                        </Card.Content>
                    </Card>
                </div>
            </div>
		</>
	)
}
