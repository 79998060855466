// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState, useEffect} from 'react'
import {
    useNavigate,
    useParams
  } from 'react-router-dom';

// semantic-ui
import { Button, Loader, Dimmer, Form,  Card, div, TextArea, Table, Checkbox, Icon } from 'semantic-ui-react'

// store
import { getApi } from 'services/api-catalog'
import { apiGatewayClientWithCredentials } from 'services/api'

// utilities
import _ from 'lodash'
import { PrivateAccess } from 'components/Admin/Accounts/AccountsTableColumns';
import * as AuditService from 'services/auditing'
import { store } from 'services/state'
import LoaderBar from 'components/Loader'

//components


export default function ApiServiceClass() {
    function uploadSwaggerUpdate(event) {
        if(JSON.stringify(wholeSwaggerObj.info.common) === JSON.stringify(commonAccess) && JSON.stringify(wholeSwaggerObj.info.private) === JSON.stringify(privateAccess)){
            alert('No Changes made')
            navigate('/admin/apis')
            return 
        } 
        wholeSwaggerObj.info.common = commonAccess
        wholeSwaggerObj.info.private = privateAccess
        setLoading(true)//set loading to true so user cannot click anything until it has finished updating
        apiGatewayClientWithCredentials()
            .then((app) => 
                app.post('/admin/catalog/updateGenericApi', {}, { id: apiId, swagger:JSON.stringify(wholeSwaggerObj) }, {})
            ).then((res) => {
                if (res.status === 200) {
                setLoading(false)
                // alert("Updated Successfully!")
                navigate('/admin/apis')
            }
        })
        try {
            AuditService.createAuditLog(`Edited API Access Level: ${wholeSwaggerObj.info.title}`)
        } catch (error) {
            console.er(error);
        }
    }
    const { apiId, stage } = useParams();
    const navigate = useNavigate();
    const [privateAccess, setPrivateAccess] = useState([]);
    const [commonAccess, setCommonAccess] = useState([]);
    const [normalAccess, setNormalAccess] = useState([]);
    const [allAccess, setAllAccess] = useState([]);
    const [Name, setName] = useState();
    const [wholeSwaggerObj, setWholeSwaggerObj] = useState(undefined)
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        getApi(apiId || 'ANY', true, stage, true)
        .then((api) => {
            setAllAccess(api.swagger.paths?Object.keys(api.swagger.paths): []);
            setPrivateAccess(api.swagger.info.private? api.swagger.info.private: []);//set swagger description
            setCommonAccess(api.swagger.info.common? api.swagger.info.common: []);//set swagger
            setName(api.swagger.info.title);//set swagger title
            setWholeSwaggerObj(api.swagger)
        })
    }, [])
    useEffect(()=>{
        setNormalAccess(allAccess?allAccess.filter(x=>!privateAccess.includes(x)).filter(x=>!commonAccess.includes(x)):[] );//set swagger
        // setAllAccess(allAccess)
    }, [privateAccess, commonAccess])

    function handleChange(event, checked){
        const path = event.split(':')[0]
        const type = event.split(':')[1]
        switch(!checked){
            case true:
                switch(type){
                    case 'common':
                        setCommonAccess(commonAccess.filter(x=>x!==path))
                        break
                    case 'private':
                        setPrivateAccess(privateAccess.filter(x=>x!==path))
                        break
                }
                break
            case false:
                switch(type){
                    case 'common':
                        setCommonAccess([...commonAccess, path])
                        setPrivateAccess(privateAccess.filter(x=>x!==path))
                        break
                    case 'private':
                        setPrivateAccess([...privateAccess, path])
                        setCommonAccess(commonAccess.filter(x=>x!==path))
                        break
                }
                break
        }
        document.getElementById(event).checked = !checked
    }
    return (
        <div className='p-8 lg:px-20 px-12'>
            <div className='mb-4'>
                <Button onClick={()=>navigate(-1)} className='!ml-3' icon>
                    <Icon name='chevron left' />
                    Back
                </Button>
            </div>
            <Dimmer active={loading} size='massive'>
                <LoaderBar active={loading} message={"Updating... Please Wait"} value='1'/>
            </Dimmer>
            <Card.Group>
                <Form onSubmit={(e)=>uploadSwaggerUpdate(e)} className='w-full' style={{"margin": '20px'}}>
                    <Card className='w-full'>
                        <Card.Header className='text-center m-3 font-bold text-xl'>{Name}<br /><p className='text-sm font-normal'>Normal classes are readonly, select/unselect common/private to select normal.</p></Card.Header>
                        <Card.Content>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Services</Table.HeaderCell>
                                    <Table.HeaderCell>Common</Table.HeaderCell>
                                    <Table.HeaderCell>Normal</Table.HeaderCell>
                                    <Table.HeaderCell>Private</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>

                                {allAccess&&allAccess.map(path=>{
                                    return(
                                        <Table.Row>
                                            <Table.Cell>{path}</Table.Cell>
                                            <Table.Cell className='!place-content-center'><Checkbox className='align-center' id={path+':common'} checked={commonAccess.includes(path)} onChange={(e, data)=>handleChange(data.id, data.checked)}/></Table.Cell>
                                            <Table.Cell><Checkbox id={path+':normal'} checked={normalAccess.includes(path)} readOnly/></Table.Cell>
                                            <Table.Cell><Checkbox id={path+':private'} checked={privateAccess.includes(path)} onChange={(e, data)=>handleChange(data.id, data.checked)}/></Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                        </Card.Content>
                        <Card.Content extra>
                            <Button type='submit' className='float-right m-0 mr-5 mb-5'>Update</Button>
                        </Card.Content>
                    </Card>
                </Form>
            </Card.Group>
        </div>
    )

}
