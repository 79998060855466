// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react'
import { CardGroup, Card, Image, Menu } from 'semantic-ui-react'
import {
  isAdmin
} from 'services/self'

import { apiGatewayClientWithCredentials } from 'services/api'
import { createRequestLog } from 'services/auditing'


// swagger-ui
import "swagger-ui-react/swagger-ui.css";

// semantic-ui

// services
import { getApi } from 'services/api-catalog'

// components
import { RedocStandalone } from 'redoc';
import Footer from 'components/Footer'
import 'rapidoc'


// state
import { useLocation, useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import { store } from 'services/state'
import * as AccountService from 'services/accounts'
import MenuLink from 'components/MenuLink';
import { Icon, Button, Modal } from 'semantic-ui-react';
import * as queryString from 'query-string'

export const Apis = observer(()=>{
  const location = useLocation()
  const { apiId, stage } = useParams();
  // const {title} = queryString.parse(location.search)
  const [swaggerList, setSwaggerList] = useState([])
  const [swaggerObj, setSwaggerObj] = useState(null);
  const [privateAccess, setPrivateAccess] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [tryItResponse, setTryItResponse] = useState(undefined);
  const [title, setTitle] = useState()
  

  useEffect(()=>{
      if(isAdmin()){
        setSwaggerList(apiId);
        setPrivateAccess(true)
      }else{
        AccountService.getPrivateAccessForUser(store.userId).then(async userInfo=>{
          switch(userInfo.result){
            case true:
              await AccountService.getSwaggerAccessForUser(store.userId).then(userInfo => {
                if(userInfo.result != null){
                  setSwaggerList(userInfo.result);
                  setPrivateAccess(true)
                }//set swagger access when page has been loaded
              })
              break;
              default:
                await AccountService.getSwaggerAccessForUser(store.userId).then(userInfo => {
                  if(userInfo.result != null){
                    setSwaggerList(userInfo.result);
                    setPrivateAccess(false)
                  }//set swagger access when page has been loaded
                })
              }
            })
      }
  }, [])//run it once webpage has been rendered

  useEffect(()=>{
    if(privateAccess != null){
      getApi(apiId || 'ANY', true, stage, true)
        .then(async(api) => {
          if(privateAccess && swaggerList.includes(apiId) || api.swagger.info.title.includes('Common Code and Value Sets')){//checks if user has access to private services
            setSwaggerObj(api?.swagger);//set swagger object
          }else if(swaggerList.includes(apiId)){//check if user has access to normal services
            let tempObjects = await Object(api?.swagger?.paths)//place all path in a temp object to get later
            const tempKey = Object.keys(api?.swagger?.paths).filter(x=> api?.swagger.info.private?!api?.swagger.info.private.includes(x): true)//filter keys that are normal
            api.swagger.paths = {}//clear all paths in swagger
            tempKey.forEach(x=>{
              api.swagger.paths[x] = tempObjects[x]//declare object
            })
            setSwaggerObj(api?.swagger);//set swagger object
          }else{//if user do not have access to both private and normal, display common services
            let tempObjects = await Object(api?.swagger?.paths)//place all path in a temp object to get later
            const tempKey = Object.keys(api?.swagger?.paths).filter(x=> api?.swagger.info.common.includes(x))//filter keys that are common
            api.swagger.paths = {}//clear all paths in swagger
            tempKey.forEach(x=>{
              api.swagger.paths[x] = tempObjects[x]//declare object
            })
            setSwaggerObj(api?.swagger);//set swagger object
          }
        })
    }
  }, [privateAccess])//Call and filter swaggerObj before setting it once privateAccess has been set

  useEffect(()=>{
    if(swaggerObj != null){
      let docEl = document.getElementById('thedoc');//get rapidoc element
        docEl.loadSpec(swaggerObj)//load swagger into specs
        // console.log(swaggerObj.info.title);
        // setTitle(swaggerObj.info.title)
      }
    }, [swaggerObj])//render rapidoc element once swaggerObj has been set

  useEffect(()=>{
    let docEl = document.getElementById('thedoc');//get rapidoc element
    docEl.addEventListener('before-try', (e) => {
      let headers = {};
      for (const header of e.detail.request.headers.entries()) {
        headers[header[0]] = header[1]
      }
      // console.log(swaggerObj.info.title)
      fetcher({...e.detail.request, headers: headers, title: swaggerObj.info.title})
      e.detail.controller.abort()
    })
  }, [swaggerObj])

  const fetcher = (params = {}) => {
    apiGatewayClientWithCredentials()
      .then((app) => app.post('/tryitout', {}, {params}, {}))
      .then((res) => {
        if (res.status === 200) {
          setModalOpen(true)
          setTryItResponse(JSON.stringify(res.data, null, 3))
          // createRequestLog({Url: JSON.parse(res.config.data).params.url, Status: res.status})
          createRequestLog({Url: JSON.parse(res.config.data).params.url, Service: params.title, Status: res.status})
        }else{
          createRequestLog({Url: JSON.parse(res.config.data).params.url, Service: params.title, Status: res.status})
        }
      }).catch(err=>{
          setModalOpen(true)
          setTryItResponse(JSON.stringify(err, null, 3))
          // createRequestLog({Url: JSON.parse(err.config.data).params.url, Status: err.status})
          createRequestLog({Url: JSON.parse(err.config.data).params.url, Service: params.title, Status: err.status})
      })
  }
  function copyToClipboard() {
    navigator.clipboard.writeText(tryItResponse);
    alert('Copied response')
  }
  

    
  return (
    <>
      <MenuLink className='absolute right-0 bottom-14 p-3 rounded-full text-center' to='#overview'><Icon name='angle up' className='text-center m-0 bg-gray-400 p-2' circular></Icon></MenuLink>
      <MenuLink className='absolute right-0 bottom-0 p-3 rounded-full text-center' to='/apis/errorCodes'><Icon name='bug' className='text-center m-0 bg-gray-400 p-2' circular></Icon></MenuLink>
      {/* <Button onClick={fetcher}>TRY FETCH</Button> */}
      <rapi-doc
        style={{ height: "100vh", width: "100%" }}
        theme = "light"
        show-header = 'false'
        show-curl-before-try = 'true'
        render-style="read"
        id="thedoc"
        schema-description-expanded='true'
        info-description-headings-in-navbar="true"
        show-method-in-nav-bar="as-colored-text"
        api-key-location="header"
        allow-schema-description-expand-toggle='false'
        schema-expand-level='1'
        // fetch-credentials="omit"
        // allow-try='false'
      >
        {/* <div slot='footer'>
          <div className="bg-gray-900 m-0 py-10 place-content-center">
              <CardGroup className='xl:grid xl:grid-cols-2 flex flex-col content-center justify-center mx-0 px-0'>
                  <div className='pb-0 mb-0 flex max-xl:justify-center'>
                      <Image centered-size='mini' src='/custom-content/synapxe-logo.png' className='lg:mx-20 rounded-md pb-0 mx-5 mb-0 max-w-xs'></Image>
                  </div>
                  <div className='xl:text-right mx-0 lg:px-20 w-full max-xl:pt-8'>
                      <Menu className='xl:float-right bg-transparent my-0 py-0 md:grid md:grid-flow-col md:auto-cols-max md:auto-rows-max flex flex-col md:justify-center'>
                          <MenuLink to='/RequestAPI' className='text-white font-bold my-0 pb-0 justify-center'>Contact Us</MenuLink>
                          <MenuLink to='/terms-of-use' className='text-white font-bold my-0 pb-0 justify-center'>Terms of Use</MenuLink>
                          <MenuLink className='text-white font-bold my-0 pb-0 justify-center'><a href="https://www.ihis.com.sg/Privacy_Policy/" target='_blank'>Privacy Policy</a></MenuLink>
                          <MenuLink to='/apis/errorcodes' className='text-white font-bold my-0 pb-0 justify-center'>Error Codes</MenuLink>
                      </Menu>
                  </div>
              </CardGroup>
              <hr className='lg:mx-20 border-slate-500 mx-5 mt-10'></hr>
              <p className='text-white lg:float-right xl:text-right text-center lg:px-20 px-5'>Copyright &#169; Synapxe Pte Ltd. All Rights Reserved.</p>
          </div>
      
        </div> */}
        
      </rapi-doc>
      
      <Modal
          closeIcon
          closeOnEscape
          closeOnDimmerClick
          onOpen={() => {setModalOpen(true)}}
          onClose={() => {setModalOpen(false)}}
          open={modalOpen}
      >
        <Modal.Header>Response <br/> <Button onClick={()=>{copyToClipboard()}}>Copy Response</Button></Modal.Header>
        <Modal.Content className='px-0'>
          <div className='px-8 whitespace-pre-wrap overflow-scroll font-mono'>
              {tryItResponse}
          </div>
        </Modal.Content>
      </Modal>
    </>
  )
})

export default Apis
