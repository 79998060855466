import React from 'react'
import { useParams, Navigate } from 'react-router-dom'

//components
import MarkdownPage from 'components/MarkdownPage'
import { observer } from 'mobx-react'
import { pagesConfig } from 'services/get-fragments'
import { isAuthenticated, isAdmin } from 'services/self'
import { Loader, Segment } from 'semantic-ui-react'


export const Page = observer(() => {
    const params = useParams()
    const [unsure, setUnsure] = React.useState('')
    const [forceTimeout, setForceTimeout] = React.useState(false)
    let unsureTimer
    let forceTimer

    React.useEffect(()=> {
        unsureTimer = setTimeout(setUnsure, 10000, 'This is taking a bit longer than expected')
        forceTimer = setTimeout(setForceTimeout, 30000, true)
    }, [])

    if (!pagesConfig.UpdatedAt) {
        if (forceTimeout) {
            return <Navigate to={'/404'}/>
        }
        return <div className='h-[80vh] relative'><Loader active content={unsure ? unsure : 'Loading Page'} indeterminate={!!unsure}/></div>
    }

    const pageObj = pagesConfig.custom && pagesConfig.custom.filter(p => p.PageURL === params.pagename)[0]
    if (!pageObj) {
        return <Navigate to={'/404'}/>
    }
    const authorize = () => {
        switch (pageObj.Visible) {
            case true:
                return pageObj.Authenticated === !!isAuthenticated() === true || !pageObj.Authenticated || !!isAdmin() ? 200 : 403
            default:
                return 404
        }
    }
    switch (authorize()) {
        case 200:
            return(
                <>
                    <MarkdownPage/>
                </>
            )
        case 403:
            return <Navigate to={'/login'}/>
        case 404:
            return <Navigate to={'/404'}/>
        default:
            return <Navigate to={'/404'}/>
    }
})

export default Page
