import React, { useEffect, useState } from 'react'
import { Button, Form, Message } from 'semantic-ui-react';
import * as AccountService from 'services/accounts'
import ChangePassword from './ChangePassword';
import { store } from 'services/state'
import { observer } from 'mobx-react'

const Profile = observer(() => {
    const [fullName, setFullName] = useState();
    const [organisation, setOrganisation] = useState();
    const [project, setProject] = useState();
    const [email, setEmail] = useState(store.Email);
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showFailureMessage, setShowFailureMessage] = useState(false);
    const [showWarning, setShowWarning] = useState(false)
    const [IsButtonDisabled, setIsButtonDisabled] = useState(false)


    useEffect(() => {
        if (store.FullName === '' || store.Organisation === ''|| store.Project === '') {
            setShowWarning(true);
        }
        AccountService.getUserInfo(store.userId).then((userInfo) => {
            //localStorage.setItem("FullName", userInfo?.result?.FullName);
            setFullName(userInfo?.result?.FullName);

            //localStorage.setItem("Organisation", userInfo?.result?.Organisation);
            setOrganisation(userInfo?.result?.Organisation);

            setProject(userInfo?.result?.Project);
        });
    }, [])

    useEffect(() => {
        if (showSuccessMessage) {
            AccountService.getUserInfo(store.userId).then((userInfo) => {
                //localStorage.setItem("FullName", userInfo?.result?.FullName);
                setFullName(userInfo?.result?.FullName);

                //localStorage.setItem("Organisation", userInfo?.result?.Organisation);
                setOrganisation(userInfo?.result?.Organisation);

                setProject(userInfo?.result?.Project);

                setIsFormChanged(false);
                if (!userInfo.result.FullName || !userInfo.result.Organisation|| !userInfo.result.Project) {
                    setShowWarning(true);
                }
            }).catch((e) => {
                console.error(e);
            });
        }
    }, [showSuccessMessage, showWarning]);

    useEffect(() => {
        if (fullName?.length === 0 || organisation?.length === 0 || !isFormChanged || showSuccessMessage || project?.length === 0) {
            setIsButtonDisabled(true);
        }
        else {
            setIsButtonDisabled(false);
        }
    }, [fullName, organisation, showSuccessMessage, isFormChanged, project]);


    const handleSubmit = async () => {
        await AccountService.updateUserProfile(store.userId, fullName, organisation, project).then(() => {
            setShowSuccessMessage(true); //organisation updated but not the project
            setIsFormChanged(false);
            setShowWarning(false);
        }).catch(() => {
            setShowFailureMessage(true);
        });

    }

    const resetMessages = () => {
        setShowSuccessMessage(false);
        setShowFailureMessage(false);
        //setFormChanged(false);
    }

    return (
        <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ padding: '2em', width: '50%' }}>
                {
                    showWarning && <Message
                        negative
                        header='Please update user profile to proceed!'
                    />
                }
                {
                    showSuccessMessage && <Message
                        success
                        header='Successfully updated user profile!'
                    />
                }
                {
                    showFailureMessage && <Message
                        negative
                        header='Failed to update user profile!'
                    />
                }
                <h1>My Profile</h1>
                <Form onSubmit={handleSubmit}>
                    <Form.Field>
                        <label>Full Name</label>
                        <input type="text" name="full-name" value={fullName} onChange={(e) => { setFullName(e.target.value); setIsFormChanged(true); resetMessages(); }} placeholder="Full Name" />
                    </Form.Field>
                    <Form.Field>
                        <label>Organization</label>
                        <input type="text" name="organization" value={organisation} onChange={(e) => { setOrganisation(e.target.value); setIsFormChanged(true); resetMessages(); }} placeholder="Organization" />
                    </Form.Field>
                    <Form.Field>
                        <label>Project</label>
                        <input type="text" name="project" value={project} onChange={(e) => { setProject(e.target.value); setIsFormChanged(true); resetMessages(); }} placeholder="Project" />
                    </Form.Field>
                    <Form.Field>
                        <label>Email</label>
                        <input type="text" name="Email" value={store.user.email} onChange={(e) => { setEmail(e.target.value) }} placeholder="Email" disabled />
                    </Form.Field>
                    <Button content="Save" positive />
                </Form>
                <div style={{ marginTop: '3em' }}>
                    <Button onClick={() => { setChangePassword(true); }} content="Change Password" />
                    {
                        changePassword && <ChangePassword show={changePassword} onClose={() => setChangePassword(false)} />
                    }
                </div>
            </div>
        </div>
    );
})



export default Profile