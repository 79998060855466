import { apiGatewayClientWithCredentials as getApiGatewayClient } from 'services/api'
import { cognitoUserPoolId, cognitoClientId } from 'services/api'

const getAccountsWithFilter = async filter =>
{
  const apiGatewayClient = await getApiGatewayClient()
  const response = await apiGatewayClient.get(
    '/admin/accounts',
    undefined,
    undefined,
    { queryParams: { filter } }
  )
  return response.data.accounts
}

const getAllUserInfo = async () =>
{
  const apiGatewayClient = await getApiGatewayClient()
  const response = await apiGatewayClient.get('/admin/getAllAccountInfo')
  return response.data.accounts
}

const getSingleUserInfo = async userId =>
{
  const apiGatewayClient = await getApiGatewayClient()
  const response = await apiGatewayClient.get(`/admin/getSingleAccountInfo/${userId}`)
  return response.data.accounts
}

const getAllUsersSubscriptions = async () =>
{
  const apiGatewayClient = await getApiGatewayClient()
  const response = await apiGatewayClient.get('/admin/subscriptions')

  return response.data.AllUsersSubscriptions
}

const url = ([first, ...rest], ...parts) =>
  first + parts.map((p, i) => encodeURIComponent(p) + rest[i]).join('')


const fetch = (method, target, params = {}) =>
  getApiGatewayClient()
    .then(apiGatewayClient => apiGatewayClient[method](target, {}, params, {}))
    .then(response => response.data)
    .catch(error => { throw error && (error.data || error) })

export const fetchRegisteredAccounts = () => getAccountsWithFilter('registered')
export const fetchAdminAccounts = () => getAccountsWithFilter('admin')
export const fetchAllUserInfo = () => getAllUserInfo()
export const fetchSingleUserInfo = userId => getSingleUserInfo(userId)
export const fetchAllUserSubscriptions = () => getAllUsersSubscriptions()
export const fetchPendingRequestAccounts = () => getAccountsWithFilter('pendingRequest')
export const fetchPendingInviteAccounts = () => getAccountsWithFilter('pendingInvite')
export const deleteAccountByUserId = userId => fetch('delete', url`/admin/accounts/${userId}`)
// TODO: verify if this is even correct - I suspect it should've posted to
// `/admin/accounts/:userId/denyRequest` instead.
export const deleteInviteByUserId = userId => fetch('delete', url`/admin/accounts/${userId}`)
export const createInviteByEmail = (email, project, organisation, name) => fetch('post', '/admin/accounts', { targetEmailAddress: email, project: project, organisation: organisation, name:name })
export const resendInviteByEmail = email => fetch('put', '/admin/accounts/resendInvite', { targetEmailAddress: email })
export const promoteAccountByUserId = userId => fetch('put', url`/admin/accounts/${userId}/promoteToAdmin`)
export const approveAccountRequestByUserId = userId => fetch('put', url`/admin/accounts/${userId}/approveRequest`)
export const denyAccountRequestByUserId = userId => fetch('put', url`/admin/accounts/${userId}/denyRequest`)
export const changeAccountStatusAccountByUserId = (userId, change) => fetch('put', url`/admin/accounts/${userId}/changeAccountStatus/${change}`)
export const changeUserPoolMFAConfig = (MfaConfiguration) => fetch('put', url`/admin/accounts/UserPoolMFAoptions/${MfaConfiguration}`)
export const getUserPoolMFAConfig = () => fetch('get', url`/admin/accounts/UserPoolMFAoptions`)
export const changeUserMFA = (userId, change) => fetch('put', url`/admin/UserMFA/${userId}/${change}`)
export const updateUserProfile = (userId, FullName, Organisation, Project) => fetch('put', '/profile', { userId: userId, FullName: FullName, Organisation: Organisation, Project: Project });
export const getUserInfo = userId => fetch('get', url`/profile/${userId}`);
export const updateSwaggerAccessForUser = (userId, SwaggerAccess) => fetch('put', '/access', { userId: userId, SwaggerAccess: SwaggerAccess });
export const getSwaggerAccessForUser = (userId) => fetch('get', url`/access/${userId}`);
export const getSwaggerById = (swaggerId) => fetch('get', url`/access/swagger/${swaggerId}`);
export const cognitoInitiateAuth = (email, password) => fetch('post', `/authLogin`, {username: email, password: password, cognitoUserPoolId: cognitoUserPoolId, cognitoClientId: cognitoClientId});
export const getPing = () => fetch('get', `/ping`);
export const getPrivateAccessForUser = (userId) => fetch('get', url`/PrivateAccess/${userId}`);
export const updatePrivateAccessForUser = (userId, PrivateAccess) => fetch('put', '/PrivateAccess', { userId: userId, PrivateAccess: PrivateAccess });
// export const updatePrivateAccess = (userId, privateAccess) => fetch('put',