import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

export const PageLayout = () => {

    return (
        <Outlet/>
    )
  
}

export default PageLayout