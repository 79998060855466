import React, { useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  isAdmin,
  isAuthenticated,
  isRegistered,
  logout
} from 'services/self'

// semantic-ui
import { Loader, Card, List } from 'semantic-ui-react'

// store
import { observer } from 'mobx-react'
import { store } from 'services/state'


// utilities
import _ from 'lodash'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'
import * as AccountService from 'services/accounts'

//components
import MenuLink from 'components/MenuLink'

const sorter = [
  "Appointments and Registrations",
  "Patient Profile",
  "Pre/Post Consultation Questionnaire",
  "Consent and Relationship",
  "Notifications",
  "Common Code and Value Sets",
  "Bills",
  "Payments",
  "HealthierSG",
  "Authentication Broker"
]

function getApisWithStages () {
  const apiList = [].concat(_.get(store, 'apiList.generic', []), _.get(store, 'apiList.apiGateway', [])).map(api => ({
    group: api.apiId || api.id,
    id: api.apiStage || api.id,
    title: api.swagger.info.title,
    route: `/apis/${api.id}` + (api.apiStage ? '/' + api.apiStage : ''),
    
    stage: api.apiStage,
    desc: api.swagger.tags ? api.swagger.tags[0].description? api.swagger.tags[0].description: api.swagger.info.description.split('\n\n', 2)[0]: null,
    info: api.swagger.info ? api.swagger.info : null,
    options: api.swagger.paths? api.swagger.paths: null,
    common: api.swagger.info.common? api.swagger.info.common.length: 0,
  }))
  
  // console.log("end2");
  return _.toPairs(_.groupBy(apiList, 'group'))
    .map(([group, apis]) => ({ group, apis, active: _.some(apis, 'active'), title: apis[0].title, desc: apis[0].desc, info: apis[0].info, options: apis[0].options, common: apis[0].common}))
}

function checkLength(apiGroupList, page, originalLength, remainingApiGroupList){
  if(originalLength === 0 || originalLength === undefined){ //check if there are apis in this group
      return <p className='p-4 font-bold text-rose-500'>No API Has Been Published</p>
  }else if(apiGroupList.length > 0){ //if have return APIs
    switch(page){
      case 'HomePage':
        return (
          <Card.Group className='grid lg:grid-cols-2'>
          {apiGroupList.map(({ apis, title, group, active, desc, info, common },j) => (
            <div className='p-4 flex'>
              <Card fluid className='rounded-2xl shadow-[0_0_14px_rgba(0,44,218,0.2)]'>
                <Card.Content className='p-8'>
                      <Link to={"/apis/"+group + `?title=${title}`} className=' '>
                  <Card.Header className='my-4 text-xl font-bold headers'>{title} &gt;</Card.Header>
                      </Link>
                  <Card.Description className='my-4 overflow-y-auto h-[100px] scroll-smooth'>
                    <div className=''>
                      <ReactMarkdown 
                        className = 'break-word '
                        children = {String(desc).split("\n\n")[1]? String(desc).split("\n\n")[1]:String(desc)}
                        rehypePlugins={[remarkGfm /*Turn Markdown to Table*/, rehypeRaw /*Turn Markdown to HTML */]}>
                      </ReactMarkdown>
                    </div>
                  </Card.Description>
                </Card.Content>
              </Card>
            </div>
          ))}
          {!isAdmin()?remainingApiGroupList?remainingApiGroupList.filter(x=>x.common > 0).map(({ apis, title, group, active, desc, info, common },j) => (
            <div className='p-4 flex'>
              <Card fluid className='rounded-2xl shadow-[0_0_14px_rgba(0,44,218,0.2)]'>
                <Card.Content className='p-8'>
                      <Link to={"/apis/"+group + "?title=" + title} className=' '>
                  <Card.Header className='my-4 text-xl font-bold headers'>{title} &gt;</Card.Header>
                      </Link>
                  <Card.Description className='my-4 overflow-y-auto h-[100px] scroll-smooth'>
                    <div className=''>
                      <ReactMarkdown 
                        className = 'break-word '
                        children = {String(desc).split("\n\n")[1]? String(desc).split("\n\n")[1]:String(desc)}
                        rehypePlugins={[remarkGfm /*Turn Markdown to Table*/, rehypeRaw /*Turn Markdown to HTML */]}>
                      </ReactMarkdown>
                    </div>
                  </Card.Description>
                </Card.Content>
              </Card>
            </div>
          )):null: null}
        </Card.Group>
      )
    case 'starterGuide':
      return (
          <Card.Group className='grid lg:grid-cols-2 '>
            {apiGroupList.map(({ apis, title, group, active, desc, info, options },j) => (
              <div className='p-4 flex'>
                <Card fluid className='rounded-2xl shadow-[0_0_14px_rgba(0,44,218,0.2)]'>
                  <Card.Content className='p-8'>
                    <Card.Header className='my-4 text-xl font-bold'>{title} &gt;</Card.Header>
                    <Card.Description className='my-4 overflow-y-auto h-[100px] scroll-smooth'>
                      <div className=''>
                        <List bulleted>
                        {Object.keys(options).map(e => (
                            options[e].summary? <List.Item>{options[e].summary}</List.Item>: e.replaceAll('-', ' ').split('$')[1]? <List.Item>{e.replaceAll('-', ' ').split('$')[1].charAt(0).toUpperCase()+e.replaceAll('-', ' ').split('$')[1].slice(1)}</List.Item>: null
                          ))}
                        </List>
                      </div>
                    </Card.Description>
                  </Card.Content>
                </Card>
              </div>
            ))}
          </Card.Group>
      )
    }
  }else{
    let check = remainingApiGroupList.filter(x=>x.common > 0).length
    switch(true){
      case check>0:
        return(
          <Card.Group className='grid lg:grid-cols-2 '>
            {remainingApiGroupList.filter(x=>x.common > 0).map(({ apis, title, group, active, desc, info, common },j) => (
            <div className='p-4 flex'>
              <Card fluid className='rounded-2xl shadow-[0_0_14px_rgba(0,44,218,0.2)]'>
                <Card.Content className='p-8'>
                      <Link to={"/apis/"+group + "?title=" + title} className=' '>
                  <Card.Header className='my-4 text-xl font-bold headers'>{title} &gt;</Card.Header>
                      </Link>
                  <Card.Description className='my-4 overflow-y-auto h-[100px] scroll-smooth'>
                    <div className=''>
                      <ReactMarkdown 
                        className = 'break-word '
                        children = {String(desc).split("\n\n")[1]? String(desc).split("\n\n")[1]:String(desc)}
                        rehypePlugins={[remarkGfm /*Render Markdown tags*/, rehypeRaw /*Render HTML tags in Markdown */]}>
                      </ReactMarkdown>
                    </div>
                  </Card.Description>
                </Card.Content>
              </Card>
            </div>
          ))}
          </Card.Group>
        )
        break;
      default:
        return <p className='p-2 font-bold text-rose-500'>No common services available / APIs has not been subscribed to you, to use the API. <MenuLink to='/requestAPI' className='underline'>Please send a request</MenuLink></p>
    }
  }
}

export default observer(function ApisMenu (Page) {
  let apiGroupList = getApisWithStages()
  apiGroupList.forEach(o=>{
    if(!sorter.includes(o.title)) sorter.push(o.title)
  })
  apiGroupList = apiGroupList.sort((x,y)=>{
    return sorter.indexOf(x.title) - sorter.indexOf(y.title)
  })
  const [swaggerList, setSwaggerList] = useState([])
  useEffect(()=>{
    if(swaggerList.length === 0){
      AccountService.getSwaggerAccessForUser(store.userId).then(userInfo => {
        if(userInfo.result != null){
          setSwaggerList(userInfo.result);
        }//set swagger access when page has been loaded
      })
    }
    }, [])
  if(isAuthenticated() && Page['page'] === 'HomePage'){
    if(isAdmin()){
      return (
        <>
          <h1 className='underline font-bold text-xl my-8'>CCDP</h1>
          {checkLength(apiGroupList.filter(x=> x.info['x-logo']? x.info["x-logo"].altText? x.info["x-logo"].altText.includes("CCDP"): false: false), 'HomePage', apiGroupList.filter(x=> x.info['x-logo']? x.info["x-logo"].altText? x.info["x-logo"].altText.includes("CCDP"):false:false).length, apiGroupList.filter(x=> x.info['x-logo']? x.info["x-logo"].altText? x.info["x-logo"].altText.includes("CCDP"):false:false))}
          <h1 className='underline font-bold text-xl my-8'>HSGW</h1>
          {checkLength(apiGroupList.filter(x=> x.info['x-logo']? x.info["x-logo"].altText? x.info["x-logo"].altText.includes("HSGW"): false: false), 'HomePage', apiGroupList.filter(x=> x.info['x-logo']? x.info["x-logo"].altText? x.info["x-logo"].altText.includes("HSGW"):false:false).length, apiGroupList.filter(x=> x.info['x-logo']? x.info["x-logo"].altText? x.info["x-logo"].altText.includes("HSGW"):false:false))}
          <h1 className='underline font-bold text-xl my-8'>Others</h1>
          {checkLength(
                  apiGroupList.filter(x=> x.info['x-logo']? 
                    x.info["x-logo"].altText?
                    !x.info["x-logo"].altText.includes("CCDP")&&!x.info["x-logo"].altText.includes("HSGW") 
                    : true
                    : x), 'HomePage', 
                    apiGroupList.filter(x=> x.info['x-logo']? !x.info["x-logo"].altText.includes("CCDP")&&!x.info["x-logo"].altText.includes("HSGW"):true).length,
                    apiGroupList.filter(x=> x.info['x-logo']? !x.info["x-logo"].altText.includes("CCDP")&&!x.info["x-logo"].altText.includes("HSGW"):true)
            )}       
        </>
      )
    }else{
      return (
        <>
          <h1 className='underline font-bold text-xl my-8'>CCDP</h1>
            {/* checks for api grouping, e.g. CCDP or HSGW then renders the necessary card */}
            {checkLength(apiGroupList.filter(x=> x.info['x-logo']? x.info["x-logo"].altText? x.info["x-logo"].altText.includes("CCDP") && (swaggerList.includes(x.group) || x.title.includes('Common Code and Value Sets')): false: false), 'HomePage', apiGroupList.filter(x=> x.info['x-logo']? x.info["x-logo"].altText? x.info["x-logo"].altText.includes("CCDP"):false:false).length, apiGroupList.filter(x=> x.info['x-logo']? x.info["x-logo"].altText? x.info["x-logo"].altText.includes("CCDP") && (!swaggerList.includes(x.group) || x.title.includes('Common Code and Value Sets')):false:false))}
    
          <h1 className='underline font-bold text-xl my-8'>HSGW</h1>
            {checkLength(apiGroupList.filter(x=> x.info['x-logo']? x.info["x-logo"].altText? x.info["x-logo"].altText.includes("HSGW") && (swaggerList.includes(x.group) || x.title.includes('Common Code and Value Sets')): false: false), 'HomePage', apiGroupList.filter(x=> x.info['x-logo']? x.info["x-logo"].altText? x.info["x-logo"].altText.includes("HSGW"):false:false).length, apiGroupList.filter(x=> x.info['x-logo']? x.info["x-logo"].altText? x.info["x-logo"].altText.includes("HSGW") && (!swaggerList.includes(x.group) || x.title.includes('Common Code and Value Sets')):false:false))}
    
          <h1 className='underline font-bold text-xl my-8'>Others</h1>
            {checkLength(
              apiGroupList.filter(x=> x.info['x-logo']? 
                x.info["x-logo"].altText?
                !x.info["x-logo"].altText.includes("CCDP")&&!x.info["x-logo"].altText.includes("HSGW") 
                : true
                : x), 'HomePage', 
                apiGroupList.filter(x=> x.info['x-logo']? !x.info["x-logo"].altText.includes("CCDP")&&!x.info["x-logo"].altText.includes("HSGW"):true).length,
                apiGroupList.filter(x=> x.info['x-logo']? !x.info["x-logo"].altText.includes("CCDP")&&!x.info["x-logo"].altText.includes("HSGW") && !swaggerList.includes(x.group):true))}
        </>
      )
    }
  }else if(isAuthenticated() && Page['page'] === 'starterGuide'){
    return(
      <>
          <h1 className='underline font-bold text-xl my-8 '>CCDP</h1>
            {/* checks for api grouping, e.g. CCDP or HSGW then renders the necessary card */}
            {checkLength(apiGroupList.filter(x=> x.info["x-logo"]? x.info["x-logo"].altText.includes("CCDP"): false), 'starterGuide', apiGroupList.filter(x=> x.info["x-logo"]? x.info["x-logo"].altText.includes("CCDP"): false).length)}
    
          <h1 className='underline font-bold text-xl my-8 '>HSG</h1>
            {checkLength(apiGroupList.filter(x=> x.info["x-logo"]? x.info["x-logo"].altText.includes("HSGW"): false), 'starterGuide', apiGroupList.filter(x=> x.info["x-logo"]? x.info["x-logo"].altText.includes("HSGW"): false).length)}
    
          <h1 className='underline font-bold text-xl my-8 '>Others</h1>
            {checkLength(apiGroupList.filter(x=> x.info["x-logo"]? !x.info["x-logo"].altText.includes("CCDP")&&!x.info["x-logo"].altText.includes("HSGW") : true), 'starterGuide', apiGroupList.filter(x=> x.info["x-logo"]? !x.info["x-logo"].altText.includes("CCDP")&&!x.info["x-logo"].altText.includes("HSGW") : true).length)}
        </>
      )
  }else{
    return (
      <div className='text-black items-center'>
        {isAuthenticated()?
          <>
            <h1 className='underline font-bold text-xl my-8'>CCDP</h1>
              {/* checks for api grouping, e.g. CCDP or HSGW then renders the necessary card */}
              {checkLength(apiGroupList.filter(x=> x.info['x-logo']? x.info["x-logo"].altText? x.info["x-logo"].altText.includes("CCDP") && swaggerList.includes(x.group): false: false), 'HomePage', apiGroupList.filter(x=> x.info['x-logo']? x.info["x-logo"].altText? x.info["x-logo"].altText.includes("CCDP"):false:false).length, apiGroupList.filter(x=> x.info['x-logo']? x.info["x-logo"].altText? x.info["x-logo"].altText.includes("CCDP") && !swaggerList.includes(x.group):false:false))}
      
            <h1 className='underline font-bold text-xl my-8'>HSG</h1>
              {checkLength(apiGroupList.filter(x=> x.info['x-logo']? x.info["x-logo"].altText? x.info["x-logo"].altText.includes("HSGW") && swaggerList.includes(x.group): false: false), 'HomePage', apiGroupList.filter(x=> x.info['x-logo']? x.info["x-logo"].altText? x.info["x-logo"].altText.includes("HSGW"):false:false).length, apiGroupList.filter(x=> x.info['x-logo']? x.info["x-logo"].altText? x.info["x-logo"].altText.includes("HSGW") && !swaggerList.includes(x.group):false:false))}
      
            <h1 className='underline font-bold text-xl my-8'>Others</h1>
              {checkLength(
                apiGroupList.filter(x=> x.info['x-logo']? 
                  x.info["x-logo"].altText?
                  !x.info["x-logo"].altText.includes("CCDP")&&!x.info["x-logo"].altText.includes("HSGW") 
                  : true
                  : x), 'HomePage', 
                  apiGroupList.filter(x=> x.info['x-logo']? !x.info["x-logo"].altText.includes("CCDP")&&!x.info["x-logo"].altText.includes("HSGW"):true).length,
                  apiGroupList.filter(x=> x.info['x-logo']? !x.info["x-logo"].altText.includes("CCDP")&&!x.info["x-logo"].altText.includes("HSGW") && !swaggerList.includes(x.group):true))}
          </>
          :<>
            <h1 className='text-center'><MenuLink to="/login" className='underline'>Sign in/up</MenuLink> to access our APIs</h1>
            <div className="relative flex py-5 items-center">
                <div className="flex-grow border-t border-gray-400"></div>
                <span className="flex-shrink mx-4 text-gray-600">OR</span>
                <div className="flex-grow border-t border-gray-400"></div>
            </div>
            <h1 className='text-center'>Check out our <MenuLink to="/starter-guide" className='underline'>Starter Guide</MenuLink></h1>
          </>
        }
        </div>
    )
  }
})