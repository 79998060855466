import React, { useEffect } from 'react'

//component
import ApisMenu from 'components/HomePageApisMenu'

export default function FormSg(){
    // useEffect(() => {
    //     let iframe = document.getElementById('iframe')
    //     let iframeContainer = document.getElementById('iframeContainer')
    //     iframe.style.height = 
    //     iframe.contentWindow.document.body.scrollHeight + 'px';
    //     console.log(iframe.style.height)
    // }, [])

    useEffect(()=>{
    
    }, [])

    return (
        <>
            <p>
                If the form below is not loaded, you can also fill it in <a href="https://form.gov.sg/64ddd4af9d5ced0012f0c1dc" className='text-blue-500 underline'target="_blank">here</a>.
            </p>
            
            <iframe
            id="iframe"
            src="https://form.gov.sg/64ddd4af9d5ced0012f0c1dc"
            height='1800px'
            width='100%'
            scrolling='no'
            onLoad={() => document.querySelector('.ui.w-screen.menu').scrollIntoView()}
            ></iframe>
        </> 
        
    )
}