import React, { Component } from 'react'

import { SideNav } from './'
import PageWithSidebar from 'components/PageWithSidebar'

export class Admin extends Component {
  render () {
    
    return (
      <>
        <this.props.child/>
      </>
    )
  }
}
