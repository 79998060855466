// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState } from 'react'

// swagger-ui
import "swagger-ui-react/swagger-ui.css";

// semantic-ui
import { Modal, Button, Form, Message } from 'semantic-ui-react'

// services
import { getApi } from 'services/api-catalog'
import { apiGatewayClientWithCredentials } from 'services/api'
import { store } from 'services/state'
import * as AuditService from 'services/auditing'

export default function InvalidationButton( props ) {
    const [modalOpen, setModalOpen] = useState(false)
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)

    const onSubmit = async (event) => {
        event.preventDefault() 
        setLoading(true)
        const errArray = []
        const form = event.target
        const formData = new FormData(form)
        const formJson = Object.fromEntries(formData.entries())
        const path = formJson.path
        if (path && !path.startsWith('/')) {
            errArray.push("Path must start with a /")
        }
        if (path.length == 1 || path.includes(' ')) {
            errArray.push("Invalid Path")
        }
        
        setErrors(errArray)
        if (errArray.length > 0) {
            setLoading(false)
            return
        }
        
        apiGatewayClientWithCredentials()
        .then((app) => app.post(`/invalidate`, { path }, {}))
        .then((res) => {
                if (res.status === 200) {
                        setModalOpen(false)
                        const callback = props.callback ? props.callback : null
                        if (callback) {
                                callback()
                            }
                        } else {
                                setErrors(res.message)
                        }
        })
        try {
            await AuditService.createAuditLog('Create New Invalidation')
        } catch (error) {
            console.error(error);
        }
        setLoading(false)
    }

    

    return (
        <Modal
            closeIcon
            closeOnEscape
            closeOnDimmerClick
            openOnTriggerClick
            open={modalOpen}
            onOpen={() => {setModalOpen(true); setErrors([])}}
            onClose={() => {setModalOpen(false)}}
            trigger={
            <Button basic color='blue' floated='right' {...props}>
                {props.children ? props.children : 'Flush cache'}
            </Button>
            }
        >
            <Modal.Header>Confirm Invalidation</Modal.Header>
            <Modal.Content>
            <>
                <Form onSubmit={onSubmit} id="invalidate" loading={loading}>
                    <Form.Field>
                        <label htmlFor='path'>Path (Optional):</label>
                        <input type='text' id='path' name='path' placeholder="Default: /*"/>
                    </Form.Field>
                </Form>
                
                { errors.length > 0 && <Message error list={errors}></Message> }
            </>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setModalOpen(false)}>Cancel</Button>
                <Button color="yellow" form="invalidate" type="submit" loading={loading}>Invalidate</Button>
            </Modal.Actions>
        </Modal>
    )
}
