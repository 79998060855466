// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState, useRef } from 'react'

import {
  isAuthenticated
} from 'services/self'

import { useLocation } from 'react-router-dom'

// fragments
import { fragments } from 'services/get-fragments'

// semantic-ui
import { Header, Image, Card, Input, Message, Button, Icon } from 'semantic-ui-react'

//component
import ApisMenu from 'components/HomePageApisMenu'

// mobx
import { observer } from 'mobx-react'
import MenuLink from 'components/MenuLink'
import {Element} from 'react-scroll'
import LoaderBar from 'components/Loader'

export const HomePage = observer(() => {
  function test(ev){
    const searchFilter = document.getElementById('searchFilter').value
    if(searchFilter.length > 0){
      document.querySelectorAll('.headers').forEach(el=>{
        if(!el.textContent.toLowerCase().includes(searchFilter.toLowerCase())){
          el.parentElement.parentElement.parentElement.parentElement.setAttribute('style', 'display: none !important')

        }else{
          el.parentElement.parentElement.parentElement.parentElement.setAttribute('style', 'display: block !important')
        }
      })
    }else{
      document.querySelectorAll('.headers').forEach(el=>{
        el.parentElement.parentElement.parentElement.parentElement.setAttribute('style', 'display: block !important')
      })
    }
  }
  const homeFragment = fragments.index
  const [announcement, setAnnouncement] = useState(true)
  const docs = useRef(null)
  let location = useLocation();
  
  const scrollToDocs = () => { docs.current?.scrollIntoView({ behavior: 'smooth' }) }
  
  useEffect(() => {
    if (location.hash === '#docs') scrollToDocs()
  }, [location])

  return (
    <>
      {/* <LoaderBar
        active={true}
        value={true}
      /> */}
      { homeFragment && homeFragment.announcement &&
        <div className='bg-blue-200 px-8 py-4' hidden={!announcement}>
          <Header className='text-center text-lg'>
            <div>
              <Icon name='info circle'/>{homeFragment.announcement}
              <Button icon='x' floated='right' className='bg-transparent text-black text-xl p-0' onClick={() => setAnnouncement(false)}></Button>
            </div>
          </Header>
        </div>
      }
      <div className='max-w-7xl mx-auto px-16 flex flex-col justify-between min-h-[100vh]'>
        <div className='grid lg:grid-cols-2 my-12'>
          <div className='m-auto max-lg:pt-16'>
            <Header as='h1' className='text-blue-700'>{homeFragment && homeFragment.header}</Header>
            <p>{homeFragment && homeFragment.tagline}</p>
          </div>
          <div className='m-auto flex w-full justify-center items-center'>
            <Image className='' centered size='medium' src='/custom-content/images/discover.png' />
          </div>
        </div>
        <div className='grid lg:grid-cols-2 my-12'>
          <div className='m-auto flex w-full justify-center items-center'>
            <Image centered size='medium' src='/custom-content/images/started.png' />
          </div>
          <div className=''>
            <Header as='h1' className='mb-8 text-blue-700'>Getting Started</Header>
            <Card.Group id='starter-guide'>
              <Card fluid className='rounded-2xl shadow-[0_0_14px_rgba(0,44,218,0.2)]'>
                <Card.Content className='p-8'>
                  <Card.Header className='my-4'><MenuLink to='/starter-guide'>Starter Guide &gt;</MenuLink></Card.Header>
                  <Card.Description className='my-4'>
                    Find out more on how to apply for API Keys and steps to call CCDP API(s).
                  </Card.Description>
                </Card.Content>
              </Card>
              {isAuthenticated() &&  <Card fluid className='rounded-2xl shadow-[0_0_14px_rgba(0,44,218,0.2)]'>
                  <Card.Content className='p-8'>
                    <Card.Header className='my-4'><MenuLink to ='/#docs'>Read our API Documentation &gt;</MenuLink></Card.Header>
                    <Card.Description className='my-4'>
                      Find out more about how our Healthcare APIs work.
                    </Card.Description>
                  </Card.Content>
                </Card>}
              {isAuthenticated() && <Card fluid className='rounded-2xl shadow-[0_0_14px_rgba(0,44,218,0.2)]'>
                <Card.Content className='p-8'>
                  <Card.Header className='my-4'><MenuLink to='/requestApi'>Experiment with our APIs &gt;</MenuLink></Card.Header>
                  <Card.Description className='my-4'>
                    Start experimenting with our healthcare APIs. <br/><br/>
                    Register your interest now!
                  </Card.Description>
                </Card.Content>
              </Card>}
            </Card.Group>
          </div>
        </div>
        <div className='my-12' id='docs' ref={docs}>
          <Header as='h1' className='mb-8 text-blue-700'>Our APIs</Header>
          {/* <input type='text' autoComplete='off' onKeyUp={(e)=>test(e)} placeholder='Search...' id='searchFilter'/> */}
          <p className="my-8">{homeFragment && homeFragment.ourApis}</p>
          {isAuthenticated()?<Input placeholder='Search...' id='searchFilter' icon='search' iconPosition='left' onChange={(e)=>test(e)} fluid/>:null}
          <ApisMenu page='HomePage'/>
        </div>
      </div>
    </>

  )

})

export default HomePage
