// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes, Navigate, Outlet, useLocation, useRouteError, isRouteErrorResponse } from 'react-router-dom';

import * as queryString from 'query-string'


// content-fragments
import { loadPagesConfig } from 'services/get-fragments'

// semantic-ui
import 'semantic-ui-css/semantic.css'
import { Icon, Loader  } from 'semantic-ui-react'

// pages
import Home from 'pages/HomePage'
import Apis from 'pages/Apis'
import { Admin, ApiManagement } from 'pages/Admin'
import { Dashboard } from 'pages/Dashboard'
import StarterGuide from 'pages/StarterGuide'
import SignIn from 'pages/Authentication/SignIn'
import SignUp from 'pages/Authentication/SignUp'
import ConfirmSignUp from 'pages/Authentication/ConfirmSignUp'
import ForgetPassword from 'pages/Authentication/ForgetPassword'
import Terms from 'pages/Authentication/Terms'
import Steps from 'pages/Steps'
import FormSg from 'pages/FormSg'
import Page from 'pages/Page'
import PageLayout from 'pages/PageLayout'
import Errors from 'pages/Errors'

// components
import Footer from 'components/Footer'
import AlertPopup from 'components/AlertPopup'
import GlobalModal from 'components/Modal'
import NavBar from 'components/NavBar'
import MobileNav from 'components/MobileNav'
import ApiSearch from './components/ApiSearch'

import { isAdmin, isRegistered, init, login, logout, getCognitoUrl } from 'services/self'
import './index.css'
import './dist/output.css'
import RegisteredAccounts from 'pages/Admin/Accounts/RegisteredAccounts';
import AdminAccounts from 'pages/Admin/Accounts/AdminAccounts';
import PendingInvites from 'pages/Admin/Accounts/PendingInvites';
import Pages from 'pages/Admin/Pages';
import ImageManagement from 'pages/Admin/ImageManagement'
import Invalidations from 'pages/Admin/Invalidations';
import Logs from 'pages/Admin/Logs';
import ApiJson from 'pages/Admin/ApiJson';
import UpdateApi from 'pages/Admin/UpdateApi';
import EditAccess from 'pages/Admin/ApiServiceClass';
import ViewAPIS from 'pages/Dashboard/ViewAPIS';
import Profile from 'pages/Dashboard/Profile';
import RequestLog from 'pages/Admin/RequestLog';
import { store } from 'services/state'
import AdminDashboard from 'pages/Admin/AdminDashboard';
import AdminNavbar from 'components/AdminNavbar';
import FAQ from 'pages/FAQ'
import {HandleTimeout} from 'pages/HandleTimeout';

const isProfileValid = () => {
  const FullName = store.FullName; //localStorage.getItem("FullName");
  const Organisation = store.Organisation;//localStorage.getItem("Organisation");
  return FullName && Organisation && FullName !== "" && Organisation !== "";
}

const PrivateRoute = () => {
  const location = useLocation();
  if (isRegistered()) {
    // if (location.pathname !== "/dashboard/profile" && !isProfileValid())
    //   return <Navigate to="/dashboard/profile" />
    return <Outlet />;
  } else
    return <Navigate to="/login" />;
}

const AdminRoute = () => {
  return isAdmin() ? <AdminNavbar><Outlet /></AdminNavbar> : <Navigate to="/" />;
}

const Redirect = (props) => {
  const { url } = props;
  useEffect(() => {
    window.location.href = url;
  }, [url]);
  return <h5>Redirecting...</h5>;
};

const SignOut = () => {
  useEffect(() => {
    logout()
  }, []);
  return (
    <div className="flex items-center h-[80vh]">
      <Loader active content='Logging Out'/>
    </div>
    
  );
};
// To shut up a dev warning
const IndexWebHook = () => {

  const [loggedIn, setLoggedIn] = useState(false);
  const [loggedOut
    , setLoggedOut] = useState(false);

  useEffect(() => {
    const { action, code } = queryString.parse(window.location.search)
    if (action === 'login') {
      login(code).then(() => {
        setLoggedIn(true);
      }).catch(e => {
        console.error(e)
      })
    } else if (action === 'logout') {
      logout();
      setLoggedOut(true);
    }
  }, []);
  return (loggedIn || loggedOut) && <Navigate to='/' />;
}

const ErrorHandler404 = () => {
  return (
    <div className='flex items-center justify-center h-[80vh]'>
      <div className="font-bold align-middle text-4xl flex flex-col items-center justify-center">
        <Icon name="power cord" size='large' className='mb-8'/>
        Page not found
      </div>
    </div>
  )
} 

class App extends React.Component {
  constructor() {
    super()
    init()

    this.state = {
      visible: false,
      active: true
    }
    // We are using an S3 redirect rule to prefix the url path with #!
    // This then converts it back to a URL path for React routing
    if (window.location.hash && window.location.hash[1] === '!') {
      const hashRoute = window.location.hash.substring(2)
      window.history.pushState({}, 'home page', hashRoute)
    }
    loadPagesConfig()
  }

  setMobileNav(visible) {
    this.setState(prev => ({
      ...prev,
      visible: visible
    }))
  }

  setTimeOutActive(active) {
    this.setState(prev => ({
      ...prev,
      active: active
    }))
  }

  render() {
    return (
      <BrowserRouter>
        <MobileNav visible={this.state.visible} onHide={() => this.setMobileNav(false)}>
          <NavBar visible={this.state.visible} onShow={() => this.setMobileNav(true)}/>
          <GlobalModal />
          {isRegistered() &&<HandleTimeout
            onActive={()=>{this.setTimeOutActive(true)}} 
            onIdle={()=>{this.setTimeOutActive(false)}}
            onLogout={()=>{logout()}}
            />}
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/#docs' element={<Home />} />
            <Route
              exact
              path='/index.html'
              element={<IndexWebHook />}
            />
                <Route path='/admin' element={<AdminRoute />}>
                    <Route path='/admin' element={<Admin child={AdminDashboard} />} />
                    <Route path='/admin/apis' element={<Admin child={ApiManagement} />} />
                    <Route path='/admin/logs/:log' element={<Admin child={Logs} />} />
                    <Route path='/admin/pages/:pagetype' element={<Admin child={Pages} />} />
                    <Route path='/admin/images' element={<Admin child={ImageManagement} />} />
                    <Route path='/admin/invalidations' element={<Admin child={Invalidations} />} />
                    <Route path='/admin/viewapi/:apiId' element={<Admin child={ApiJson} />} />
                    <Route path='/admin/updateapi/:apiId' element={<Admin child={UpdateApi} />} />
                    <Route exact path='/admin/accounts' element={<Admin child={RegisteredAccounts} />} />
                    <Route exact path='/admin/accounts/admins' element={<Admin child={AdminAccounts} />} />
                    <Route exact path='/admin/accounts/invites' element={<Admin child={PendingInvites} />} />
                    <Route path='/admin/editaccess/:apiId' element={<Admin child={EditAccess}/>}/>
                </Route>
            <Route path='/dashboard' element={<PrivateRoute />}>
              <Route path='/dashboard' element={<Dashboard child={Profile} />} />
              <Route exact path='/dashboard/apis' element={<Dashboard child={ViewAPIS} />} />
              <Route exact path='/dashboard/profile' element={<Dashboard child={Profile} />} />
            </Route>
            <Route exact path='/apis' element={<PrivateRoute />}>
              <Route exact path='/apis' element={<Apis />} />
              <Route exact path='/apis/search' element={<ApiSearch />} />
              <Route exact path='/apis/:apiId' element={<Apis />} />
              <Route path='/apis/:apiId/:stage' element={<Apis />} />
              <Route path='/apis/errorcodes' element={<Errors />} />
            </Route>
            <Route path='/faq' element={<FAQ />} />
            <Route path='/login' element={<SignIn />} />
            <Route path='/register' element={<SignUp />} />
            <Route path='/confirmation' element={<ConfirmSignUp/>} />
            <Route path='/recovery' element={<ForgetPassword/>} />
            {/* <Route path='/login' element={<Redirect url={getCognitoUrl('login')} />} /> */}
            <Route path='/logout' element={<SignOut />} />

            
            <Route path='/starter-guide' element={<StarterGuide />} />
            <Route path='/steps-to-call-api' element={<Steps />} />
            <Route path='/RequestAPI' element={<FormSg />} />
            <Route path='/terms-of-use' element={<Terms />} />

            <Route exact path='/404' element={<ErrorHandler404/>}/>
            <Route path='/' element={<PageLayout/>}>
              <Route exact path=':pagename' element={<Page />}/>
            </Route>
          </Routes>
          <AlertPopup />
          {/* {console.log(this.props.location.pathname)} */}
          <Footer />
        </MobileNav>
      </BrowserRouter>
    )
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);
