import React, {useEffect} from 'react'
import {
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Accordion
} from 'semantic-ui-react'

import {
    isAdmin,
    isAuthenticated,
    isRegistered,
    logout
  } from 'services/self'

import MenuLink from 'components/MenuLink'
import { NavBarLinks } from 'components/NavBarLinks'

import { observer } from 'mobx-react'
import { store } from 'services/state'
import { pagesConfig } from 'services/get-fragments'
import { useLocation } from 'react-router-dom'


export const MobileNav = observer((props) => {
    const [activeIndex, setActiveIndex] = React.useState(2)
    const pages = pagesConfig.pages && pagesConfig.pages.filter(p => p.visible && p.position.navbar)

    const handleNavigation = () => {
        
    }
    const handleClick = (e, titleProps) => {
        const { index } = titleProps
        const newIndex = activeIndex === index ? -1 : index
        setActiveIndex(newIndex)
    }
    const location = useLocation()

    useEffect(()=>{
        props.onHide()
    }, [location])

    const MenuAccordian = ({ name, dropdown = [], content, index}) => {
        return (
            <Menu.Item>
                <Accordion.Title
                    active={activeIndex === index}
                    content={content ? content : name}
                    className='border-0'
                    icon={<Icon name={activeIndex === index ? 'caret down' : 'caret right'} className='absolute right-0 top-50'/>}
                    index={index}
                    onClick={handleClick}
                    />
                <Accordion.Content active={activeIndex === index}>
                        {dropdown.map(link => {
                            return (
                                <MenuLink to={link.url} className='border-0 sidebar-link'>
                                    {link.name}
                                </MenuLink>
                            )
                        })}
                </Accordion.Content>
            </Menu.Item>
        )
    }
    return (
        <Sidebar.Pushable>
            <Sidebar
            direction='right'
            animation='overlay'
            icon='labeled'
            onHide={() => {document.getElementById("pusher").setAttribute('style', 'height: auto !important'); props.onHide()}}
            onVisible={() => {document.getElementById("pusher").setAttribute('style', 'height: 100% !important')}}
            visible={props.visible}
            width='thin'
            >
                <Accordion 
                as={Menu} 
                borderless
                vertical 
                fluid
                className='h-full px-4'
                > 
                     
                    <MenuLink to='/' className='border-0 sidebar-link'>
                        <Image centered size='small' src='/custom-content/nav-logo.png' className='rounded-md py-4'/>
                    </MenuLink>
                    {NavBarLinks.map((e)=>{
                        if (e.name === 'user') {
                            return isAuthenticated() && 
                                <MenuAccordian 
                                name={e.name} 
                                dropdown={e.dropdown}
                                content={e.name ==='user' ?
                                    <>
                                    <span className='me-4'>{store.fullName}</span>
                                    <Icon name='user'></Icon>
                                    </>:<span className='me-4'>{e.name}</span>
                                }
                                index={e.index}/>
                        }
                        if (e.name === 'More') {
                            return (
                                pages && pages.length > 0 && 
                                pages.map(page => 
                                    <MenuLink to={page.link} className='border-0 sidebar-link'>
                                        {page.name}
                                    </MenuLink>
                                )
                            )

                        }
                        if (e.name === 'Admin Panel') {
                            return isAdmin() && <MenuAccordian name={e.name} dropdown={e.dropdown} index={e.index}/>
                        }
                        if (e.dropdown) {
                            return <MenuAccordian name={e.name} dropdown={e.dropdown} index={e.index}/>
                        }
                        if (['Login', 'Register'].includes(e.name)) {
                            return (
                                !isAuthenticated() && <MenuLink to={e.url} className='border-0 sidebar-link'>
                                    {e.name}
                                </MenuLink>
                            )
                        }
                        return (
                            <MenuLink to={e.url} className='border-0 sidebar-link'>
                                {e.name}
                            </MenuLink>
                        )
                    })}
                    
                </Accordion>
            </Sidebar>

            <Sidebar.Pusher dimmed={props.visible} id="pusher">
                {props.children}
            </Sidebar.Pusher>
        </Sidebar.Pushable>
    )
})

export default MobileNav