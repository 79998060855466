import React, {useState, useEffect} from 'react'

//Utils
import {Card} from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { observer } from 'mobx-react'
import frontmatter from 'front-matter'
import { marked } from 'marked'
import rehypeRaw from 'rehype-raw'

const Terms = observer(()=>{
    const [terms, setTerms] = useState()

    useEffect(()=>{ //only do it once when page has been loaded
        window.fetch('/custom-content/content-fragments/terms-of-use.md').then(response => response.text().then(text => {
            const parsedMarkdown = frontmatter(text)//read text from markdown file
            // const html = marked(parsedMarkdown.body)
            // console.log(html)
            setTerms(parsedMarkdown.body)//set text into const text
        }))
    }, [])
    return(
        <>
            <div className='mx-20 pb-20'>
                <h1 className='font-bold text-blue-700 text-2xl'>Terms of Use</h1>
                <Card fluid className='rounded-2xl shadow-[0_0_14px_rgba(0,44,218,0.2)] h-full text-l'>
                    <Card.Content className='p-8 default-links'>
                        <ReactMarkdown
                        children={terms}
                        rehypePlugins={[remarkGfm, rehypeRaw]}
                        className='font-sans break-normal leading-7'/>
                    </Card.Content>
                </Card>
            </div>
        </>
    )
})
export default Terms
