import React, {useEffect} from 'react'
import { Dimmer, Progress } from 'semantic-ui-react'
import { useState } from 'react'

function LoaderBar({message, active, value}) {
    const [percent, setPcnt] = useState(0)

    const increment = () => {
        setPcnt(percent >= 100 ? 0 : percent + 20)
    }
    if(active){
        if(percent<80){
            setTimeout(increment,Number(value)*1000)
        }else{
            clearTimeout()
        }
    }
    

    return (
        active?
            <div className='p-96 h-screen opacity-100 justify-center place-items-center'>
                <Progress percent={percent} className='!w-fill !text-white' color='teal' inverted/>
                {message}
            </div>
        :
        null
    )
}

export default LoaderBar
