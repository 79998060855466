import React, {useState, useEffect} from "react"
// semantic-ui
import { Header, Message, Card, Form, List, Popup, Icon, Button, Divider } from "semantic-ui-react"

//Component
import { useNavigate, useLocation, Link, Navigate } from 'react-router-dom'

import { initiateAuth, respondToNewPasswordAuth, isAuthenticated, verifyPassword } from 'services/self'
import { toTitleCase } from 'services/misc'

export default function SignIn() {   
    const navigate = useNavigate()
    const location = useLocation()
    const state = location.state ? location.state : {}
    
    const [message, setMessage] = useState(state.message ? state.message : "")
    const [invitedChallenge, setInvitedChallenge] = useState(undefined)
    window.history.replaceState({}, document.title)

    if (isAuthenticated()) {
        return <Navigate to='/'/>
    }
    const SignInForm = () => {
        const [email, setEmail] = useState("")
        const [errEmail, setErrEmail] = useState(false)
        const [password, setPassword] = useState('')
        const [errPassword, setErrPassword] = useState(false)
        const [errorMsgs, setErrorMsgs] = useState([])
        const [formLoading, setFormLoading] = useState(false)


        const handleSubmit = async (event) => {
            event.preventDefault();
            setErrorMsgs([])
            setFormLoading(true)
            setErrEmail(false)
            setErrPassword(false)

            if (!email) setErrEmail('Invalid email address')
            if (!password) setErrPassword('Enter a password')

            if (!email || !password) return setFormLoading(false)
            
            const respondToAuthData = await initiateAuth({ email, password }, (err, data) => {
                setFormLoading(false)
                // console.log(data)
                if (err) {
                    if(err.__type==='UserNotConfirmedException'){
                        navigate(`/confirmation?email=${email}`)
                    };
                    setErrEmail(true)
                    setErrPassword(true)
                    setErrorMsgs([err.message])
                    return
                }
                if (data.ChallengeName === "NEW_PASSWORD_REQUIRED") {
                    setInvitedChallenge(data)
                    setPassword('')
                    return
                }
                navigate("/")
            })
        }
        return (
            <Form className="m-5" onSubmit={handleSubmit} error={errorMsgs.length != 0} success={!!message} loading={formLoading}>
                <Message
                success
                content={message}
                />
                <Form.Input
                    label="Email Address"
                    type="email"
                    error={errEmail}
                    value={email} 
                    onChange={(e, data) => {setEmail(data.value); setErrEmail(false)}}
                    placeholder="Email Address"
                />
                <Form.Input
                    label="Password"
                    type="password"
                    error={errPassword}
                    value={password} 
                    onChange={(e, data) => {setPassword(data.value); setErrPassword(false)}}
                    placeholder="Password"
                />
                <Message
                error
                list={errorMsgs}
                />
                <div className=" mb-4">
                    <Link className="text-indigo-500" to="/recovery">Forgot Password?</Link>
                </div>
                <Button className="bg-indigo-500" type='submit' fluid>Submit</Button>
            </Form>
        )
    }
    const InvitedForm = () => {
        const [password, setPassword] = useState('')
        const [passwordC, setPasswordC] = useState('')
        const [errPassword, setErrPassword] = useState(false)
        const [errPasswordC, setErrPasswordC] = useState(false)
        const [consent, setConsent] = useState(false)
        const [errConsent, setErrConsent] = useState(false)
        const [errorMsgs, setErrorMsgs] = useState([])
        const [formLoading, setFormLoading] = useState(false)


        const handleSubmit = async (event) => {
            event.preventDefault()
            setErrorMsgs([])
            setFormLoading(true)
            setErrPassword(false)
            setErrPasswordC(false)

            let anyErrors = false
            if (!password) {
                setErrPassword("Password field must not be empty")
                anyErrors = true
            }
            if (!passwordC) {
                setErrPasswordC('Password field must not be empty')
                anyErrors = true
            }
            if (password !== passwordC) {
                setErrPassword(current => current ? current : true)
                setErrPasswordC('The password you entered does not match')
                anyErrors = true
            }
            const [ verifyResult ] = verifyPassword(password)

            if (!verifyResult) {
                setErrPassword(current => current ? current : "Password is not strong enough")
                anyErrors = true
            }
            if (!consent) {
                setErrConsent("Please accept our terms and conditions and privacy policy")
                anyErrors = true
            }

            if (anyErrors) return setFormLoading(false)

            if (consent && password && passwordC) {
                const responseParams = { 
                    ChallengeName: invitedChallenge.ChallengeName, 
                    Session: invitedChallenge.Session,
                    Email: JSON.parse(invitedChallenge.ChallengeParameters.userAttributes).email,
                    NewPassword: password
                }
                respondToNewPasswordAuth(responseParams, (err, data) => {
                    setFormLoading(false)
                    if (err) {
                        // console.log([err])
                        setErrorMsgs([err.message])
                        return
                    }
                    // console.log(isAuthenticated());
                    navigate("/")
                })
            }
            
            setFormLoading(false)
        }
        return (
            <Form className="m-5" onSubmit={handleSubmit} error={errorMsgs.length != 0} success={!!message} loading={formLoading}>
                <Message
                success
                content={message}
                />
                <Form.Input 
                    autoComplete="new-password"
                    type='password'
                    error={errPassword} 
                    label={
                        <label>Password <span className='text-red-500'>*</span>
                        <Popup
                            trigger={<Icon name='question' size='small' circular className="ms-4"/>}
                            position='bottom left'>
                        Password Requirements:
                        <List bulleted>
                            <List.Item>Min 15 Characters</List.Item>
                            <List.Item>At least 1 Number</List.Item>
                            <List.Item>At least 1 lowercase letter</List.Item>
                            <List.Item>At least 1 uppercase letter</List.Item>
                            <List.Item>At least 1 special character</List.Item>
                        </List>
                        </Popup>
                        </label>
                    }
                    placeholder='Password' 
                    value={password} 
                    onChange={(e, data) => {setPassword(data.value); setErrPassword(false)}}/>
                <Form.Input 
                    autoComplete="new-password"
                    type='password'
                    error={errPasswordC} 
                    label={<label>Confirm Password <span className='text-red-500'>*</span></label>}
                    placeholder='Confirm Password' 
                    value={passwordC} 
                    onChange={(e, data) => {setPasswordC(data.value); setErrPasswordC(false)}}/>
                <Form.Checkbox 
                    tabIndex="0"
                    error={errConsent}
                    label={<label>I agree to the <Link target="_blank" to="/terms-of-use" className="font-bold underline text-indigo-500">Terms and Conditions<Icon name="external alternate"/></Link> and <Link target="_blank" to="https://www.ihis.com.sg/Privacy_Policy/" className="font-bold underline text-indigo-500">Privacy Policy<Icon name="external alternate"/></Link> <span className='text-red-500'>*</span></label>}
                    checked={consent} 
                    onChange={(e, data) => {setConsent(data.checked); setErrConsent(false)}}/>
                <Message
                error
                list={errorMsgs}
                />
                <Button className="bg-indigo-500" type='submit' fluid>Set Password</Button>
            </Form>
        )
    }
	return (
        <>
			<div className="max-w-7xl mx-auto px-4 sm:px-16 pb-20 pt-8 min-h-[calc(100vh-245px)]">
                <div className="lg:max-w-xl max-w-md mx-auto">
                    <Card className="bg-gray-200 shadow-md mx-auto" fluid>
                        <Card.Content>
                            <Card.Header className="text-xl text-center m-5">{ invitedChallenge ? "Set A New Password" : "Sign in" }</Card.Header>
                            { invitedChallenge ?
                            <InvitedForm></InvitedForm>:
                            <SignInForm></SignInForm>
                            }
                        </Card.Content>
                    </Card>
                    { !invitedChallenge &&
                    <>
                        <Divider horizontal>Or</Divider>
                        <p className="text-center">Dont have an account? <Link className="text-indigo-500" to="/register">Sign up now.</Link></p> 
                    </>
                    }
                </div>
            </div>
		</>
	)
}
