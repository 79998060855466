import React, {useState, useEffect} from "react"
// semantic-ui
import { Header, Message, Card, Form, Checkbox, Button, Divider, Popup, List, Icon } from "semantic-ui-react"

//Component
import { useNavigate, useLocation, Link } from 'react-router-dom'
import PasswordRequirementsPopup from "components/PasswordRequirementsPopup"
import PasswordRequirements from 'components/PasswordRequirements'
import { forgotPassword, confirmForgotPassword, verifyPassword } from 'services/self'

export default function ForgetPassword() {   
    const navigate = useNavigate()
    const [sentCode, setSentCode] = useState(false)
    const [email, setEmail] = useState('')
    const [errEmail, setErrEmail] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordC, setPasswordC] = useState('')
    const [errPassword, setErrPassword] = useState(false)
    const [errPasswordC, setErrPasswordC] = useState(false)
    const [code, setCode] = useState('')
    const [errCode, setErrCode] = useState(false)
    const [errorMsgs, setErrorMsgs] = useState([])
    const [formLoading, setFormLoading] = useState(false)

    const handleSubmitCode = async (event) => {
        event.preventDefault()
        setErrorMsgs([])
        setFormLoading(true)
        setErrCode(false)
        setErrPassword(false)
        setErrPasswordC(false)

        let anyErrors = false

        if (!code) {
            setErrCode("Please fill your recovery code")
            anyErrors = true
        }
        if (!password) {
            setErrPassword("Password field must not be empty")
            anyErrors = true
        }
        if (!passwordC) {
            setErrPasswordC('Password field must not be empty')
            anyErrors = true
        }
        const [ verifyResult ] = verifyPassword(password)

        if (!verifyResult) {
            setErrPassword(current => current ? current : "Password is not strong enough")
            anyErrors = true
        }
        if (password !== passwordC) {
            setErrPassword(current => current ? current : true)
            setErrPasswordC('The password you entered does not match')
            anyErrors = true
        }

        if (anyErrors) return setFormLoading(false)

        if (errorMsgs.length === 0) {
            await confirmForgotPassword({ email, password, code }, (err, data) => {
                setFormLoading(false)

                if (err.message === 'Invalid verification code provided, please try again.') return setErrCode(err.message)
                if (err.message === 'Attempt limit exceeded, please try after some time.') return setErrorMsgs([err.message])
                if (err) return console.error(err.message)

                navigate("/login")
            })
        }
        
        setFormLoading(false)
    }

    const handleSubmitForgot = async (event) => {
        event.preventDefault()
        setErrorMsgs([])
        
        const validEmailRegex =
        /^[\w.!#$%&'*+/=?^`{|}~-]+@[^_\W]([a-z\d-]{0,61}[^_\W])?(\.[^_\W]([a-z\d-]{0,61}[^_\W])?)*$/i
        if (!email || !validEmailRegex.test(email)) return setErrEmail("Invalid email address")
        
        setFormLoading(true)
        await forgotPassword({ email }, (err, data) => {
            setFormLoading(false)
            if (err) {
                console.error(err.message);
                setErrorMsgs(['Something went wrong'])
                return
            }
            setSentCode(true)
        })
    }
	return (
        <>
			<div className="max-w-7xl mx-auto px-4 sm:px-16 pb-20 pt-8 min-h-[calc(100vh-245px)]">
                <div className="lg:max-w-xl max-w-md mx-auto">
                    <Card className="bg-gray-200 shadow-md mx-auto" fluid>
                        <Card.Content>
                        {sentCode ? 
                        <>
                            <Card.Header className="text-xl text-center m-5">Change Password</Card.Header>
                            <Form className="m-5" onSubmit={handleSubmitCode} error={errorMsgs.length != 0} success={true} loading={formLoading} autoComplete="off">
                                <Message
                                success
                                content={`A recovery code has been send to your email address that is registered with us. Please check your email for the recovery code`}
                                />
                                <Form.Input 
                                    autoComplete="one-time-code"
                                    type="text"
                                    error={errCode} 
                                    label={<label>Recovery Code <span className='text-red-500'>*</span></label>}
                                    placeholder='XXXXXX' 
                                    value={code} 
                                    onChange={(e, data) => {setCode(data.value); setErrCode(false)}}/>
                                <Form.Input 
                                    autoComplete="new-password"
                                    type='password'
                                    error={errPassword} 
                                    label={<PasswordRequirementsPopup content='New Password'/>}
                                    placeholder='Password' 
                                    value={password} 
                                    onChange={(e, data) => {setPassword(data.value); setErrPassword(false)}}/>
                                <PasswordRequirements password={password}/>
                                <Form.Input 
                                    autoComplete="new-password"
                                    type='password'
                                    error={errPasswordC} 
                                    label={<label>Confirm Password <span className='text-red-500'>*</span></label>}
                                    placeholder='Confirm Password' 
                                    value={passwordC} 
                                    onChange={(e, data) => {setPasswordC(data.value); setErrPasswordC(false)}}/>
                                <Message
                                error
                                list={errorMsgs}
                                />
                                <Button className="bg-indigo-500" type='submit' fluid disabled={!(code && password && passwordC)}>Change Password</Button>
                            </Form>
                        </>: 
                        <>
                            <Card.Header className="text-xl text-center m-5">Recover Account</Card.Header>
                            <Form className="m-5" onSubmit={handleSubmitForgot} error={errorMsgs.length != 0} loading={formLoading}>
                                <Form.Input 
                                    type="email"
                                    error={errEmail} 
                                    label={<label>Email Address <span className='text-red-500'>*</span></label>}
                                    placeholder='Email Address' 
                                    value={email} 
                                    onChange={(e, data) => {setEmail(data.value); setErrEmail(false)}}/>
                                <Message
                                error
                                list={errorMsgs}
                                />
                                <Button className="bg-indigo-500" type='submit' fluid disabled={!email}>Send code</Button>
                            </Form>
                        </>
                        }
                        </Card.Content>
                    </Card>
                    <Divider horizontal>Or</Divider>
                    <p className="text-center">Have an account? <Link className="text-indigo-500" to="/login">Login now.</Link></p>
                </div>
            </div>
		</>
	)
}
