// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import AWS from 'aws-sdk'
import * as AccountService from 'services/accounts'

// services
import { store } from 'services/state'
import { updateAllUserData } from 'services/api-catalog'
import { initApiGatewayClient, apiGatewayClient, cognitoDomain, cognitoIdentityPoolId, cognitoUserPoolId, cognitoClientId, cognitoRegion } from 'services/api'
import * as jwtDecode from 'jwt-decode'
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import {SRPClient, calculateSignature, getNowString} from 'amazon-user-pool-srp-client'
import Cookies from 'js-cookie'

const cognitoIdp = new AWS.CognitoIdentityServiceProvider();

const runCredentials = (data) => {
  let username = '';
  const id_token = data.AuthenticationResult.IdToken
  const access_token = data.AuthenticationResult.AccessToken
  if (id_token) { // we get both, we set both, but we only really care about the idToken
    username = jwtDecode(id_token)['cognito:username']
    if (username) {
      // sessionStorage.setItem("userId", username);
      Cookies.set('userId', username, {secure: true})
      store.userId = username;
      AccountService.getUserInfo(username).then((userInfo) => {
        store.FullName = userInfo?.result?.FullName;
        store.Organisation = userInfo?.result?.Organisation;
        store.email = userInfo?.result?.EmailAddress;
        store.Project = userInfo?.result?.Project;
      }).catch((e) => {
        console.error(e);
      });
    }
    // sessionStorage.setItem(cognitoUserPoolId, id_token)
    Cookies.set('cognitoUserPoolId', id_token, {secure:true})
    // setCookies(cognitoUserPoolId, id_token, {httpOnly: true, secure:true})

    store.idToken = id_token

    logoutTimer = setTimeout(logout, getRemainingSessionTime(id_token))

    setCredentials()

    // resolve(id_token)
  }

  if (access_token) {
    store.accessToken = access_token
  }
}

export async function initiateAuth({email, password}, callback) {
  const randomVal = process.env.REACT_APP_COGNITO_CLIENT_SECRET
  const secretHash = Base64.stringify(hmacSHA256(`${email.toLowerCase()}${cognitoClientId}`, randomVal));
  const userPoolId = cognitoUserPoolId.split('_')[1]
  const srp = new SRPClient(userPoolId)
  const SRP_A = srp.calculateA()

  try {
    cognitoIdp.initiateAuth({
      "AuthFlow": "USER_SRP_AUTH",
      "AuthParameters": { 
        "USERNAME": email.toLowerCase(),
        // "PASSWORD": password,
        "SECRET_HASH": secretHash,
        "CHALLENGE_NAME": 'SRP_A',
        "SRP_A": SRP_A
      },
      "ClientId": cognitoClientId,
    }, (err, data) => {
      if (err) {
        console.error(err)
        callback(err)
        return
      } 
      // SRP AUTH will always send challenge. So this If statement is redundant
      if (data.AuthenticationResult) {
        runCredentials(data)
        callback(err, data)
        return
      }
      var params = {
        "ChallengeName": data.ChallengeName,
        "ChallengeResponses": {
          "PASSWORD_CLAIM_SIGNATURE": calculateSignature(
            srp.getPasswordAuthenticationKey(
              data.ChallengeParameters.USER_ID_FOR_SRP,
              password,
              data.ChallengeParameters.SRP_B,
              data.ChallengeParameters.SALT
              ),
              userPoolId,
            data.ChallengeParameters.USER_ID_FOR_SRP,
            data.ChallengeParameters.SECRET_BLOCK, 
            getNowString(),
            ),
            "PASSWORD_CLAIM_SECRET_BLOCK": data.ChallengeParameters.SECRET_BLOCK,
            "TIMESTAMP": getNowString(),
            "USERNAME": data.ChallengeParameters.USER_ID_FOR_SRP,
            "SECRET_HASH": Base64.stringify(hmacSHA256(`${data.ChallengeParameters.USERNAME}${cognitoClientId}`, randomVal))
          },
          "ClientId": cognitoClientId,
      }
      cognitoIdp.respondToAuthChallenge(params, (err, data)=>{
        if (err) {
          console.error(err)
          callback(err)
          return
        } 
        
        if(data.ChallengeName === 'NEW_PASSWORD_REQUIRED'){
          callback(err, data)
          return
        }
        if(data.AuthenticationResult) {
          runCredentials(data)
          callback(err, data)
          return
        }

      })
    })
    // console.log(authResult);
    // console.log(errormsg);
    // return authResult
  } catch (error) {
    console.log(error)
  }
}
export function respondToNewPasswordAuth({ChallengeName, Session, Email, NewPassword}, callback) {
  const randomVal = process.env.REACT_APP_COGNITO_CLIENT_SECRET
  const secretHash = Base64.stringify(hmacSHA256(`${Email.toLowerCase()}${cognitoClientId}`, randomVal));
  // console.log(secretHash)

  try {
    cognitoIdp.respondToAuthChallenge({
      "ChallengeName": ChallengeName,
      "ClientId": cognitoClientId,
      "ChallengeResponses": {
        "NEW_PASSWORD": NewPassword,
        "USERNAME": Email, 
        "SECRET_HASH": secretHash
      },
      "Session": Session
    }, (err, data) => {
      runCredentials(data)
      callback(err, data)
    })
  } catch (error) {
    console.log(error)
  }
}

export async function register({name, email, password, organization, project}, callback) {
  const randomVal = process.env.REACT_APP_COGNITO_CLIENT_SECRET
  const secretHash = Base64.stringify(hmacSHA256(`${email.toLowerCase()}${cognitoClientId}`, randomVal));
  // console.log(secretHash)
  try {
    cognitoIdp.signUp({
      "Username": email.toLowerCase(),
      "Password": password,
      "SecretHash": secretHash,
      "ClientId": cognitoClientId,
      "UserAttributes": [ 
      { 
         "Name": "custom:Organisation",
         "Value": organization
      },
      { 
        "Name": "custom:project",
        "Value": project
      },
      { 
        "Name": "name",
        "Value": name
      },
   ],
    }, callback)
  } catch (error) {
    console.log(error)
  }
}

export function getPasswordRequirements( regex=false ) {
  const pwRequirements = [
    { name: "Minimum 12 characters" , regex: /.{12,}/},
    { name: "At least 1 number" , regex: /.*\d.*/},
    { name: "At least 1 lower case letter" , regex: /.*[a-z].*/},
    { name: "At least 1 upper case letter" , regex: /.*[A-Z].*/},
    { name: "At least 1 special character" , regex: /[`!@#$%^&*(){}\[\]:;<>,.?/~_+\-=\|\\]+/},
  ]
  if (regex) {
    return pwRequirements
  } else {
    return pwRequirements.map( requirement => requirement.name )
  }
}

export function verifyPassword(password) {
  const pwRequirements = getPasswordRequirements(true)

  const data = pwRequirements.map(requirement => {
    return { name: requirement.name, valid: requirement.regex.test(password) }
  })

  const result = data.every(r => r.valid === true )
  return [result, data]
}

export async function confirmSignUp({email, code}, callback) {
  const randomVal = process.env.REACT_APP_COGNITO_CLIENT_SECRET
  const secretHash = Base64.stringify(hmacSHA256(`${email.toLowerCase()}${cognitoClientId}`, randomVal));
  try {
    cognitoIdp.confirmSignUp({
      "ConfirmationCode": code,
      "Username": email.toLowerCase(),
      "SecretHash": secretHash,
      "ClientId": cognitoClientId
    }, callback)
  } catch (error) {
    console.log(error)
  }
}
export async function resendCode({email}, callback){
  const randomVal = process.env.REACT_APP_COGNITO_CLIENT_SECRET
  const secretHash = Base64.stringify(hmacSHA256(`${email.toLowerCase()}${cognitoClientId}`, randomVal));
  try{
    cognitoIdp.resendConfirmationCode({
      "ClientId": cognitoClientId,
      "Username": email.toLowerCase(),
      "SecretHash": secretHash
    }, callback)
  }catch(err){
    console.log(err)
  }
}

export async function forgotPassword({email}, callback) {
  const randomVal = process.env.REACT_APP_COGNITO_CLIENT_SECRET
  const secretHash = Base64.stringify(hmacSHA256(`${email.toLowerCase()}${cognitoClientId}`, randomVal));
  try {
    cognitoIdp.forgotPassword({
      ClientId: cognitoClientId,
      Username: email,
      SecretHash: secretHash,
    }, callback)
  } catch (error) {
    console.log(error)
  }
}

export async function confirmForgotPassword({email, password, code}, callback) {
  const randomVal = process.env.REACT_APP_COGNITO_CLIENT_SECRET
  const secretHash = Base64.stringify(hmacSHA256(`${email.toLowerCase()}${cognitoClientId}`, randomVal));
  try {
    cognitoIdp.confirmForgotPassword({
      "ClientId": cognitoClientId,
      "ConfirmationCode": code,
      "Password": password,
      "SecretHash": secretHash,
      "Username": email
   }, callback)
  } catch (error) {
    console.log(error)
  }
}

export async function changePassword({ token, oldpassword, newpassword }) {
  try {
    await cognitoIdp.changePassword({
      AccessToken: token,
      PreviousPassword: oldpassword,
      ProposedPassword: newpassword
    }).promise()

    alert("Success!")
  } catch (error) {
    if (error.name === 'InvalidPasswordException') {
      alert("Failed! Please make sure new password meets password policy!")
    }
    else if (error.name === 'LimitExceededException') {
      alert("Failed! Number of tries exceeded limits, please try again later")
    }
    else {
      alert("Failed!")
    }

  }
}



export function isAuthenticated() {
  return store.idToken
}

function getPreferredRole() {
  return jwtDecode(store.idToken)['cognito:preferred_role'] || ''
}

export function isRegistered() {
  if (!store.idToken) {
    return false
  }

  const role = getPreferredRole()
  return (
    role.includes('-CognitoAdminRole-') ||
    role.includes('-CognitoRegisteredRole-')
  )
}

export function isAdmin() {
  return store.idToken && getPreferredRole().includes('-CognitoAdminRole-')
}

let logoutTimer

function getRemainingSessionTime(idToken) {
  return jwtDecode(idToken).exp * 1000 - Date.now()
}

/**
 * On page load, look for an active cookie. If it exists and isn't expired, great, use it. Otherwise, clear everything and make sure we're not logged in.
 */
export function init() {
  // attempt to refresh credentials from active session

  let idToken
  let diff = 0

  try {
    // idToken = store.idToken;//sessionStorage.getItem(cognitoUserPoolId)
    // idToken = sessionStorage.getItem(cognitoUserPoolId)
    idToken = Cookies.get('cognitoUserPoolId')
    if (idToken) diff = getRemainingSessionTime(idToken)
    console.log(diff)
  } catch (error) {
    console.error(error)
  }

  // console.log(diff);
  if (diff > 0) {
    store.idToken = idToken
    store.userId = jwtDecode(idToken)['cognito:username']
    logoutTimer = setTimeout(logout, diff)
    setCredentials()
  } else {
    const params = {
      IdentityPoolId: cognitoIdentityPoolId,
    }
    AWS.config.credentials = new AWS.CognitoIdentityCredentials(params)
    AWS.config.credentials.refresh(error => {
      if (error) {
        console.error(error)
        window.location.reload()
      }
      initApiGatewayClient(AWS.config.credentials) // init a guest client (will get overwritten if we have creds)
    })
    logout()
  }
}

/**
 * Gets triggered by the callback from the cognito user pool. Pretty much all it does is grab and store the idToken.
 */
export function login(code) {

  const verifyToken = (code) => {
    const clientId = cognitoClientId;
    const randomVal = 'q2a9gs1bafffe1aa47cl7f820d474d9ltnf4oggsu6m45glva73';// Security risk??
    const authEndpoint = `${getLoginRedirectUrl()}`;

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "authorization_code");
    urlencoded.append("code", code);
    urlencoded.append("client_id", clientId);
    urlencoded.append("redirect_uri", authEndpoint);
    urlencoded.append("client_secret", randomVal);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return new Promise((resolve, reject) => {
      fetch(`${cognitoDomain}/token`, requestOptions)
        .then((response) => response.text())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  if (code != null) {
    let username = '';
    return new Promise((resolve, reject) => {
      verifyToken(code)
        .then((body) => {
          const { id_token, access_token } = JSON.parse(body);
          if (id_token) { // we get both, we set both, but we only really care about the idToken
            username = jwtDecode(id_token)['cognito:username']
            if (username) {
              // sessionStorage.setItem("userId", username);
              store.userId = username;
              AccountService.getUserInfo(username).then((userInfo) => {
                store.fullName = userInfo?.result?.FullName;
                store.organisation = userInfo?.result?.Organisation;
                store.email = userInfo?.result?.EmailAddress;
                store.project = userInfo?.result?.Project;
              }).catch((e) => {
                console.error(e);
              });
            }
            Cookies.set(cognitoUserPoolId, id_token, {secure: true});

            store.idToken = id_token

            logoutTimer = setTimeout(logout, getRemainingSessionTime(id_token))

            setCredentials()

            resolve(id_token)
          }

          if (access_token) {
            store.accessToken = access_token
          }
        }).catch((e) => {
          reject(e);
        });
    });
  }
}

export const getLoginRedirectUrl = () =>
  `${window.location.protocol}//${window.location.host}/index.html?action=login`
export const getLogoutRedirectUrl = () =>
  `${window.location.protocol}//${window.location.host}/index.html?action=logout`

function setCredentials() {
  const preferredRole = jwtDecode(store.idToken)['cognito:preferred_role']
  const params = {
    IdentityPoolId: cognitoIdentityPoolId,
    Logins: {
      [`cognito-idp.${cognitoRegion}.amazonaws.com/${cognitoUserPoolId}`]: store.idToken
    }
  }

  if (preferredRole) params.RoleArn = preferredRole

  AWS.config.credentials = new AWS.CognitoIdentityCredentials(params)

  return new Promise((resolve, reject) => {
    AWS.config.credentials.refresh(error => {
      if (error) {
        console.error(error)
        return reject(error)
      }

      initApiGatewayClient(AWS.config.credentials)
      store.user = { email: jwtDecode(store.idToken).email, project: jwtDecode(store.idToken)["custom:project"], organisation: jwtDecode(store.idToken)["custom:Organisation"] }
      AccountService.getUserInfo(jwtDecode(store.idToken)['cognito:username']).then((userInfo) => {
        store.fullName = userInfo?.result?.FullName;
        store.organisation = userInfo?.result?.Organisation;
        store.email = userInfo?.result?.EmailAddress;
        store.project = userInfo?.result?.Project;
      }).catch((e) => {
        console.error(e);
      });
      updateAllUserData()

      return apiGatewayClient()
        .then(apiGatewayClient => apiGatewayClient.post('/signin', {}, {}, {}))
    })
  })
}

export const getCognitoUrl = (type) => {
  const redirectUri = getLoginRedirectUrl()
  return `${cognitoDomain}/${type}?response_type=code&client_id=${cognitoClientId}&redirect_uri=${redirectUri}`
}

/**
 * Callback for the Cognito User Pool's logout just to make sure we clean up everything.
 */
export function logout() {
  clearTimeout(logoutTimer)
  logoutTimer = undefined
  if (store.idToken) {
    store.resetUserData()
    sessionStorage.clear()
    localStorage.clear()
    Cookies.remove('cognitoUserPoolId')
    Cookies.remove('userId')

    if (cognitoDomain) {
      // redirect to cognito to log out there, too
      const redirectUrl = getLogoutRedirectUrl()
      window.location = `${cognitoDomain}/logout?client_id=${cognitoClientId}&logout_uri=${redirectUrl}`
    }
  }
}
