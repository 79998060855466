//note: navbar links will follow the order in the list.

export const NavBarLinks = [
    {
        name: 'Home',
        url: '/'
    },
    {
        name: 'Documentation',
        url: '/#docs'
    },
    {
        name: 'FAQ',
        url: '/faq'
    },
    {
        name: 'More'
    },
    {
        name: 'Login / Sign up',
        url: '/login'
    },
    // {
    //     name: 'Register',
    //     url: '/register'
    // },
    {
        name: 'Admin Panel',
        index: 1,
        dropdown: [
            {
                name: 'Dashboard',
                url: '/admin',
                icon: 'dashboard'
            }, 
            {
                name: 'APIs',
                url: '/admin/apis',
                icon: 'file code'
            }, 
            {
                name: 'Pages',
                url: '/admin/pages/custom',
                icon: 'sitemap'
            }, 
            {
                name: 'Invalidations',
                url: '/admin/invalidations',
                icon: 'cloud'
            }, 
            {
                name: 'Users',
                url: '/admin/accounts',
                icon: 'users'
            }, 
            {
                name: 'Invites',
                url: '/admin/accounts/invites',
                icon: 'add user'
            },
            {
                name: 'Admins',
                url: '/admin/accounts/admins',
                icon: 'id badge'
            }, 
            {
                name: 'Audit Logs',
                url: '/admin/logs/audit',
                icon: 'tasks'
            }, 
            {
                name: 'Request Logs',
                url: '/admin/logs/request',
                icon: 'exchange'
            },
            {
                name: 'Images',
                url: '/admin/images',
                icon: 'images'
            }
        ]
    },
    {
        name: 'user',
        index: 2,
        dropdown: [
            {
                name: 'Dashboard',
                url: '/dashboard',
            },
            {
                name: 'Log Out',
                url: '/logout'
            }
        ]
    }


]