// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import React, {useState, useEffect} from 'react'
import { fragments, ShowHTML } from 'services/get-fragments'

// react-router

//utilities
import ReactMarkdown from 'react-markdown'
import frontmatter from 'front-matter'
import { marked } from 'marked'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

// semantic-ui
import { Header, Image, Card, List } from 'semantic-ui-react'

//component
import ApisMenu from 'components/HomePageApisMenu'

// mobx
import { observer } from 'mobx-react' 
// import listReactFiles from 'list-react-files'


export const Steps = observer(()=> {
  const [sectionList, setsectionList] = useState([]);

  const stepsFragment = fragments['steps-to-call-api']
  useEffect(()=>{
    if(stepsFragment){
      Object.keys(stepsFragment).filter(service=>service!='jsx')
    }
  }, [stepsFragment])
  return ( 
    <>
    {/* {console.log(sectionList)} */}
    <div className='max-w-7xl mx-auto px-16 pb-20'>
      <div className=''>
        <Header as='h1' className='my-12 text-blue-700 font-thin'>Steps to Call CCDP API(s)</Header>
        <div className="container p-6 text-[rgba(0,0,0,0.68)]">
            <div className='border-l-4 border-dotted border-indigo-600 block relative'>
              <div className='ml-10'>
                <span className='border-4 rounded-full px-2 ml-0 text-3xl border-indigo-600 absolute -left-[20px] bg-white text-indigo-600 text-center'>1</span>
                  <h5 className='text-3xl font-normal'>
                    <span className='font-bold'>Identify Request Route (Intranet or Internet)</span>
                    <p className='text-lg my-2'>Identify the server(s) and it's hosting environment that you are using to integrate with CCDP API(s).</p>
                    <p className='text-lg my-2'>These will help to determine whether the API call is going to be processed through Intranet or the Internet route.</p>
                  </h5>
                      <div>
                        <Card.Group className=''>
                            {stepsFragment && Object.keys(stepsFragment).map((x)=>
                                x != 'jsx' &&
                                  <div className='p-4 flex'>
                                    <Card fluid className='rounded-2xl shadow-[0_0_14px_rgba(0,44,218,0.2)'>
                                      <Card.Content className='p-8'>
                                        <Card.Header className='my-4 text-xl font-bold'>{x}. {stepsFragment[x].header} - <span className={`${stepsFragment[x].tag?.toLowerCase().includes('intranet') ? 'bg-green-600' : 'bg-blue-500'} rounded-md p-2 text-white font-bold`}>{stepsFragment[x].tag}</span></Card.Header>
                                        <Card.Description className='my-4'>
                                          <div className='no-tailwind'>
                                            <ShowHTML html={marked(stepsFragment[x].body, {
                                              headerPrefix: 'header-',
                                              silent: true
                                            })}/>
                                          </div>
                                          {/* <ReactMarkdown rehypePlugins={[rehypeRaw, remarkGfm]} className='mx-5 lg:text-xl text-md'>{stepsFragment[x].body}</ReactMarkdown> */}
                                        </Card.Description>
                                      </Card.Content>
                                    </Card>
                                  </div>
                            )}
                            {/* <div className='p-4 flex'>
                              <Card fluid className='rounded-2xl shadow-[0_0_14px_rgba(0,44,218,0.2)'>
                                <Card.Content className='p-8'>
                                  <Card.Header className='my-4 text-xl font-bold'>b. Healthcare Commercial Cloud (HCC) - <span className='bg-green-600 rounded-md p-2 text-white font-bold'>HCC Intranet Route</span></Card.Header>
                                  <Card.Description className='my-4'>
                                    <div className='list-disc text-lg' dangerouslySetInnerHTML={{__html: sectionList.HCC}}>
                                    </div>
                                  </Card.Description>
                                </Card.Content>
                              </Card>
                            </div>
                            <div className='p-4 flex'>
                              <Card fluid className='rounded-2xl shadow-[0_0_14px_rgba(0,44,218,0.2)'>
                                <Card.Content className='p-8'>
                                  <Card.Header className='my-4 text-xl font-bold'>c. Other Hosting Environment - <span className='bg-blue-500 rounded-md p-2 text-white font-bold'>Internet Route</span></Card.Header>
                                  <Card.Description className='my-4'>
                                    <div className='list-disc text-lg' dangerouslySetInnerHTML={{__html: sectionList.Internet}}>
                                    </div>
                                  </Card.Description>
                                </Card.Content>
                              </Card>
                            </div> */}
                        </Card.Group>
                      </div>
                      <hr className='my-8'/>
                <span className='border-4 rounded-full px-2 ml-0 text-3xl border-indigo-600 absolute -left-[20px] bg-white text-indigo-600 text-center'>2</span>
                  <h5 className='text-3xl font-normal my-2'>Get the Authorisation Value - <span className='bg-blue-500 rounded-md p-2 text-white font-bold text-xl'>Internet Route</span></h5>
                  <p>API calls via Internet route would require a valid access token. This token value needs to be sent in together with other information to complete the HTTP Header Parameters.</p>
                  <div className='p-4 flex'>
                    <Card fluid className='rounded-2xl shadow-[0_0_14px_rgba(0,44,218,0.2)'>
                      <Card.Content className='p-8'>
                        <Card.Header className='!text-lg'>
                          Generate a HMAC Signature
                        </Card.Header>
                        <Card.Description className='my-4'>
                          <div className='text-lg font-bold mb-4'>
                          The HMAC signature generated value will be the input to the <span className='underline'>"Authorisation"</span> field in the HTTP Header Parameters.<br/><br/>
                          Use the code fragment below to generate HMAC Signature
                          </div>
                          <div className='overflow-w-auto scroll-smooth font-mono text-gray-800'>
                            var baseString = apiKey + apiTime + clientMessageId;<br/>
                            var symmetricKey = "a secret key" ;<br/>
                            var checksum = GetHmacChecksum(symmetricKey, baseString); //generate hmac<br/>
                            var signature = GetSignature(privateKey, checksum); //sign hmac<br/>
                            <br />
                            internal string GetHmacChecksum(string symmetricKey, string basestring)<br/>
                            &#123;<br />
                            var messageBytes = Encoding.UTF8.GetBytes(baseString);<br/> 
                            var keyBytes = Encoding.UTF8.GetBytes(symmetricKey);<br/> 
                            using (var hmacsha256 = new HMACSHA256(keyBytes))<br/> 
                            &#123;<br/>
                            <div className='ml-10'>
                              byte[] messageHash = hmacsha256.ComputeHash(messageBytes);<br/>
                              var base64Token = Convert.ToBase64String(messageHash);<br/>  
                              return base64Token; <br/>
                            </div>
                            &#125;<br />
                            &#125;<br />
                            <br />
                            internal string GetSignature(string privateKey, string hmacChecksum)<br/>
                            &#123;<br/>
                            //using BouncyCastle implementation<br/><br/>
                            using var textReader = new StringReader(privateKey);<br/>
                            var keyParameter = (AsymmetricCipherKeyPair)new Org.BouncyCastle.OpenSsl.PemReader(textReader).ReadObject();<br/>
                            ISigner sig = SignerUtlities.GetSigner("SHA256withRSA");<br/>
                            sig.Init(true, keyParameter.Private);<br/>
                            var bytes = Encoding.UTF8.GetBytes(hmacChecksum);<br/>
                            sig.BlockUpdate(bytes, 0, bytes.Length);<br/>
                            byte[] signature = sig.GenerateSignature();<br/>
                            var signedString = Convert.ToBase64String(signature);<br/>
                            return signedString;<br/>
                            &#125;<br/>
                          </div>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </div>
                  <hr className='my-8'/>
              </div>
            </div>
        </div>
      </div>
    </div>
    </>
  )
          
})

export default Steps
