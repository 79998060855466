import { apiGatewayClientWithCredentials as getApiGatewayClient, apiGatewayClient } from 'services/api'

const url = ([first, ...rest], ...parts) =>
  first + parts.map((p, i) => encodeURIComponent(p) + rest[i]).join('')


const fetch = (method, target, params = {}) =>
  getApiGatewayClient()
    .then(apiGatewayClient => apiGatewayClient[method](target, {}, params, {}))
    .then(response => response.data)
    .catch(error => { throw error && (error.data || error) })

export const getAllImages = () => fetch('get', url`/images/all`)
export const getImages = () => fetch('get', url`/images/images`)
export const getSampleflows = () => fetch('get', url`/images/sampleflows`)
export const deleteImage = (imgURL) => fetch('delete', url`/images/${imgURL}`);