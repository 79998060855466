import React, {useEffect, useState} from 'react'
import ReactMarkdown from 'react-markdown'
import { fragments } from 'services/get-fragments'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import {Grid, Menu, Segment, Sidebar, Card, Icon, Accordion, Sticky} from 'semantic-ui-react'
import MenuLink from 'components/MenuLink'
import _ from 'lodash'
import { observer } from 'mobx-react'


export const FAQ = observer(() => {
    const [panels, setPanels] = useState([])
    
    const faqFragment = fragments.faq
    useEffect(()=>{
        if(faqFragment){
            setPanels(Object.keys(faqFragment).filter(service=>service!='jsx').map(service=>{
                    return faqFragment[service].map(values=>{
                            return {
                                key: `${service}-${values.question.replace(' ', '-')}`,
                                title: {
                                    children:(
                                        <>
                                            <span className='mx-5 font-extrabold lg:text-xl text-md'>
                                                {values.question}
                                                <span className='ml-5 float-right mr-5'><Icon name='dropdown'/></span>
                                            </span>
                                        </>
                                    )
                                },
                                content: {children:(
                                    <div className='no-tailwind'>
                                        <ReactMarkdown rehypePlugins={[rehypeRaw, remarkGfm]} className='mx-5 lg:text-xl text-md'>{values.answer}</ReactMarkdown>
                                    </div>
                                )}
                            }
                        })
            }))
        }
    }, [faqFragment])
    return (
        faqFragment?
        <div className='flex lg:pr-20 pr-0'>
            <Menu vertical className='lg:block hidden'>
                <Menu.Header className='text-center font-bold lg:text-xl text-md my-2'>Table of contents</Menu.Header>
                {faqFragment&&Object.keys(faqFragment).map(service=>{
                    if(service != 'jsx'){
                        return(
                            <Menu.Item 
                            name={service.replace(' ', '_')}
                            as={MenuLink}
                            to={'#'+service.replace(' ', '_')}
                            className='lg:text-lg text-sm'
                            >
                            </Menu.Item>
                        )
                    }
                })}
            </Menu>
            <Segment className='mt-0' basic>
                <Card.Group className='min-h-[65vh] grow flex mx-5 drop-shadow mb-5' itemsPerRow={1}>
                    <Card.Header className='font-bold lg:text-3xl text-xl ml-3 mt-2 mb-3'>Frequently Asked Questions</Card.Header>
                    {faqFragment && panels.length > 0 && Object.keys(faqFragment).map((service, index)=>{
                        if(service != 'jsx'){
                            return (
                                <Card vertical itemsPerRow={1}  id={service.replace(' ', '_')} className='lg:m-2 grow m-1'>
                                    <Card.Header className='font-bold lg:text-2xl text-lg lg:m-5 m-1'>
                                        {service}
                                    </Card.Header><hr className='lg:mx-5 mx-1'/>
                                    <Accordion
                                        defaultActiveIndex={panels[index-1]?_.range(panels[index-1].length):[0]}
                                        panels={panels[index-1]?panels[index-1]:[]}
                                        exclusive={false}
                                        fluid
                                        styled
                                    />
                                </Card>
                            )
                        }
                    })}
                </Card.Group>

            </Segment>
        </div>:<></>
  )
})

export default FAQ
