// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect } from 'react';
import { observer } from 'mobx-react'


// swagger-ui
import "swagger-ui-react/swagger-ui.css";

// semantic-ui
// import { Container, Header, Icon } from 'semantic-ui-react'

// services

import 'rapidoc'


// state
import MenuLink from 'components/MenuLink';
import { Icon } from 'semantic-ui-react';
import _ from 'lodash'
import { store } from 'services/state'



function getApisWithStages () {
    const apiList = [].concat(_.get(store, 'apiList.generic', []), _.get(store, 'apiList.apiGateway', [])).map(api => ({
      group: api.apiId || api.id,
      title: api.swagger.info.title,
      swagger: api.swagger
    }))
  
    return _.toPairs(_.groupBy(apiList, 'group'))
      .map(([group, apis]) => ({ group, apis, active: _.some(apis, 'active'), title: apis[0].title, swagger: apis[0].swagger}))
  }

export const Errors = observer(()=>{
  const swaggerList = getApisWithStages();
  
  //Call and filter swaggerObj before setting it once privateAccess has been set
  
  useEffect(()=>{
    let swaggerObj = swaggerList.filter(api=>api.title.includes("Value Sets"))[0].swagger
    // console.log(swaggerObj)
    if(swaggerObj != null){
      let docEl = document.getElementById('thedoc');//get rapidoc element
        docEl.loadSpec(swaggerObj)//load swagger into specs
    }
  }, [swaggerList])//render rapidoc element once swaggerObj has been set

  return (
    <>
      <MenuLink className='absolute right-0 bottom-14 p-3 rounded-full text-center' to='#overview'><Icon name='angle up' className='text-center m-0 bg-gray-400 p-2' circular></Icon></MenuLink>
      {/* <MenuLink className='absolute right-0 bottom-0 p-3 rounded-full text-center' to='#overview'><Icon name='bug' className='text-center m-0 bg-gray-400 p-2' circular></Icon></MenuLink> */}

      <rapi-doc
        style={{ height: "100vh", width: "100%" }}
        theme = "light"
        show-header = 'false'
        render-style="read"
        id="thedoc"
        info-description-headings-in-navbar="true"
        show-method-in-nav-bar="as-colored-text"
        api-key-location="header"
        fetch-credentials="include"
      >
        
      </rapi-doc>
    </>
  )
})

export default Errors
