import React, { Component } from 'react'
import PageWithSidebar from 'components/PageWithSidebar'
import { SideNav } from 'pages/Dashboard'

export class Dashboard extends Component
{
  render()
  {
    return (
      <PageWithSidebar sidebarContent={<SideNav />}>
        <this.props.child />
      </PageWithSidebar>
    )
  }
}