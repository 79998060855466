import React, {useState, useEffect} from 'react'

//react utils
import { Table, Button, Message, Placeholder, Header } from 'semantic-ui-react'

//components
import InvalidationButton from 'components/InvalidationButton'
import { apiGatewayClientWithCredentials } from 'services/api'

import _ from 'lodash'

const formatDate = isoDateString =>
new Intl.DateTimeFormat('en-SG', {
  dateStyle: 'medium',
  timeStyle: 'short'
}).format(new Date(isoDateString))

export const Invalidations = () => {
    const [invalidations, setInvalidations] = useState([])
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(true)
    const [date_today, setDate] = useState(new Date())
    // const date_today =new Date()

    const getInvalidations = () => {
        setDate(new Date())
        setLoading(true)
        apiGatewayClientWithCredentials()
        .then((app) => app.get('/invalidate', {}, {}, {}))
        .then((res) => {
            if (res.status === 200) {
                setInvalidations(res.data.result.Items)
                // console.log("Get /Invalidate")
                setLoading(false)
            }
        })
    }
    useEffect(()=>{
        getInvalidations()
    }, [])

    
    
    return(
        <>
            <div className='p-8 lg:px-20 px-12'>
                <Header as='h1'>Invalidations ({invalidations.length})</Header>
                <div className='mb-4 flex space-x-4'>
                    <div className="grow">
                        { !loading && invalidations.length > 0 && 
                        <Message className='m-0 px-6 py-[11px] text-base leading-4' size='mini' warning>
                            As of {`${formatDate(date_today)}`}, 
                            you have created {invalidations.filter(i => new Date(i.CreateTime) > new Date(date_today.getFullYear(), date_today.getMonth(), 1) ).length} invalidations 
                            this month for this distribution. You only have 1000 free invalidations <strong>PER ACCOUNT</strong> per month
                        </Message> }
                    </div>
                    <InvalidationButton callback={() => {getInvalidations(); setMessage('Successfully created Invalidation')}}></InvalidationButton>
                    <Button basic secondary content='Refresh' onClick={() => {getInvalidations()}}/>
                </div>
                { message && 
                <div className='mb-4'>
                    <Message success>{message}</Message>
                </div>
                }
                <div className=''>
                    <Table collapsing className='w-full'>
                        <Table.Header fullWidth>
                            <Table.Row>
                                <Table.HeaderCell>Id</Table.HeaderCell>
                                <Table.HeaderCell>Create Time</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            { !loading && (invalidations.length > 0  ? invalidations.map(i => 
                                <Table.Row positive={i.Status == 'Completed'} warning={i.Status != 'Completed'}>
                                    <Table.Cell>{i.Id}</Table.Cell>
                                    <Table.Cell>{formatDate(i.CreateTime)}</Table.Cell>
                                    <Table.Cell>{i.Status}</Table.Cell>
                                </Table.Row>
                            ) :
                            <Table.Row>
                                <Table.Cell colSpan='3' className='text-center'>
                                No images yet
                                </Table.Cell>
                            </Table.Row>) }
                            { loading && 
                            <Table.Row>
                                <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                                <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
                            </Table.Row>}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    )

}

export default Invalidations
