// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, {useState, useEffect} from 'react'
import { Link, Navigate } from 'react-router-dom'

import { Grid, Header, Popup, Loader, Message, List, Divider, Card, ListItem } from 'semantic-ui-react'


import Chart from 'chart.js'
import { fetchUsage, mapUsageByDate } from 'services/api-catalog'
import { toTitleCase } from 'services/misc'

import { observer } from 'mobx-react'

import { store } from 'services/state'

import _ from 'lodash'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'
import * as AccountService from 'services/accounts'
import Footer from 'components/Footer'

function getApisWithStages () {
  const apiList = [].concat(_.get(store, 'apiList.generic', []), _.get(store, 'apiList.apiGateway', [])).map(api => ({
    group: api.apiId || api.id,
    id: api.apiStage || api.id,
    title: api.swagger.info.title,
    route: `/apis/${api.id}` + (api.apiStage ? '/' + api.apiStage : ''),
    
    stage: api.apiStage,
    desc: api.swagger.tags ? api.swagger.tags[0].description: null,
    info: api.swagger.info ? api.swagger.info : null,
    options: api.swagger.paths? api.swagger.paths: null,
    common: api.swagger.info.common? api.swagger.info.common.length: 0
  }))

  return _.toPairs(_.groupBy(apiList, 'group'))
    .map(([group, apis]) => ({ group, apis, active: _.some(apis, 'active'), title: apis[0].title, desc: apis[0].desc, info: apis[0].info, options: apis[0].options, common: apis[0].common}))
}// the observer ?
//i dont rmb adding this part bruh i cant even ctrl z
const ViewAPIS = () => {
  const apiGroupList = getApisWithStages()
  const [swaggerList, setSwaggerList] = useState([]);
  const [privateAccess, setPrivateAccess] = useState();
  useEffect(()=>{
    AccountService.getSwaggerAccessForUser(store.userId).then(userInfo => {
      if(userInfo.result != null){
        setSwaggerList(userInfo.result);
      }//set swagger access when page has been loaded
    })
    AccountService.getPrivateAccessForUser(store.userId).then(accessInfo => {
      setPrivateAccess(accessInfo.result);
    })
  }, [])
  return (
    <>
      <Grid container>
        {/* <Grid.Row>
          <Grid.Column style={{ paddingTop: '40px' }}>
            <Header size='medium'>API Key</Header>
            {
              store.apiKey
                ? (
                  <code style={{
                    background: 'black',
                    border: '1px solid gray',
                    padding: '7px 8px',
                    color: 'lightgray',
                    borderRadius: '5px'
                  }}
                  >
                    {store.apiKey}
                  </code>
                )
                // Note: this should be the same size as the text
                : <Loader active inline size='tiny'>
                  {store.apiKeyFetchFailed ? <>
                    Please wait a minute and try refreshing. If this doesn't work, please contact
                    the admin for assistance.
                  </> : null}
                </Loader>
            }
          </Grid.Column>
        </Grid.Row> */}
        <Divider />
        {apiGroupList.filter(x=>swaggerList.includes(x.group)).length>0?
        <>
          <Grid.Row>
          <Grid.Column style={{ paddingTop: '10px' }}>
            <Header size='medium'>APIs Subscribed</Header>
              <Card.Group className='grid lg:grid-cols-2'>
                {apiGroupList.filter(x=>swaggerList.includes(x.group)).map(({ apis, title, group, active, desc, info,options, common },j) => (
                  <div className='p-4 flex'>
                    <Card fluid className='rounded-2xl shadow-[0_0_14px_rgba(0,44,218,0.2)]'>
                      <Card.Content className='p-8'>
                            <Link to={"/apis/"+group} className=' '>
                        <Card.Header className='my-4 text-xl font-bold'>{title} - {privateAccess?"Private": "Normal"}</Card.Header>
                            </Link>
                        <Card.Description className='my-4 overflow-y-auto h-[100px] scroll-smooth'>
                          <div className=''>
                            <List bulleted>
                              {privateAccess?
                                Object.keys(options).map(service=>{
                                  return(
                                    <ListItem>
                                      {options[service].summary? options[service].summary : service}
                                    </ListItem>
                                  )
                                })
                                :
                                Object.keys(options).filter(x=>info.private?!info.private.includes(x):true).map(service=>{
                                  return(
                                    <ListItem>
                                      {options[service].summary? options[service].summary : service}
                                    </ListItem>
                                  )
                                })
                              }
                            </List>
                          </div>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </div>
                ))}
              </Card.Group>
          </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column style={{ paddingTop: '10px' }}>
              <Header size='small'>To have access to other API services, send in a request.</Header>
              <iframe
                id="iframe"
                src="https://form.gov.sg/648ff0bf7012660012ca94d2"
                className='w-full h-screen'
                ></iframe>
            </Grid.Column>
          </Grid.Row>
        </>:
        <>
          <Grid.Row>
            <Grid.Column style={{ paddingTop: '10px' }}>
              <Header size='small'>You are not subscribed to any APIs. Send in a request to have access.</Header>
              <iframe
                id="iframe"
                src="https://form.gov.sg/648ff0bf7012660012ca94d2"
                className='w-full h-screen'
                ></iframe>
            </Grid.Column>
          </Grid.Row>
        </>
        }
      </Grid>
      <Footer/>
    </>
  )
}

export default ViewAPIS
