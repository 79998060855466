// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState } from 'react'

// swagger-ui
import "swagger-ui-react/swagger-ui.css";

// semantic-ui
import { Placeholder, Input, Button, Table, Popup, Icon, } from 'semantic-ui-react'

// components
import * as AuditService from 'services/auditing'
import * as ImageService from 'services/images'
import _ from 'lodash';
import * as misc from 'services/misc'
  
export const SampleFlows = ({loading, images, onDelete, disableDelete, toolbar}) => {
  const [files, setFiles] = useState({}) // Object of ALL folders and files
  const [currentFolder, setCurrentFolder] = useState({}) // Object of current folders and files
  const [copy, setCopy] = useState(false)
  const [subTitle, setSubTitle] = useState('')
  const [query, setQuery] = useState('')
  const domain = _.get(window,'config.fetchDomain')

  function handleRowClick(path){
    setQuery('')
    if(path == 'back' || path == 'refresh'){
      let tempFiles;
      let subtitle = '';
      let temp = subTitle.split('/').filter(Boolean)
      if(path == 'back'){
        temp.pop()
      }
      tempFiles = files
      if(temp.length > 0){
        temp.forEach(x=>{
          subtitle += x +'/'
          tempFiles = tempFiles[x.toLowerCase()]
        })
      }
      setCurrentFolder(tempFiles)
      // console.log(tempFiles)
      setSubTitle(subtitle)
    }else{
      if(path == ''){
        setSubTitle(misc.toTitleCase(path)+'/')
      }else{
        setSubTitle(subTitle+misc.toTitleCase(path)+'/')
      }
      setCurrentFolder(currentFolder[path])
    }
  }

  useEffect(() => {
    let temp = {} 
    images && images.forEach(image => {
      const paths = image.URL.split('/')
      paths.shift()
    })
    images && images.forEach(image=>{
      if(!Object.keys(temp).includes(misc.toTitleCase(image.URL.split('custom-content/')[1].split('/')[0]))){
        let s = image.URL.split('custom-content/')[1]
        temp = _.merge(createObject(s, image.UpdatedAt, temp), temp)
      }
    })
    setFiles(temp)
    // console.log(temp);
    // setCurrentFolder(temp)
    // setFiles(temp)
  }, [images])
  
  useEffect(()=>{
    handleRowClick('refresh')
  }, [files])

  function createObject(dir, date, temp, root){
    let obj = {}
    let i = dir.indexOf('/')
    let strParts = [dir.slice(0,i), dir.slice(i+1)]
    if(strParts[1].split('/').length > 1){
      obj[strParts[0]] = createObject(strParts[1], date, temp, strParts[0])
    }else if(strParts[1] && strParts[0]){
      if(strParts[1].split('/').length == 1){
        obj[strParts[0]] = {}
        obj[strParts[0]][strParts[1]] = date
        // console.log(_.flatten(temp, strParts[0]))
        // if(root=='sampleflows'&& !temp[root]){
        //   console.log('test')
        //   temp[root] = {}
        //   if(!temp[root][strParts[0]]){
        //     temp[root][strParts[0]]={}
        //   }
        // }
        // if(root=='sampleflows'&&temp[root][strParts[0]]){
        //   temp[root][strParts[0]].UpdatedAt = temp[root][strParts[0]].UpdatedAt? new Date(date)> new Date(temp[root][strParts[0]].UpdatedAt) ?date:temp[root][strParts[0]].UpdatedAt:date
        //   console.log(temp[root][strParts[0]].UpdatedAt)
        // }
      }else{
        return strParts[1]
      }
    }
    // if(temp[strParts[0]]){
    //   temp[strParts[0]].UpdatedAt = temp[strParts[0]].UpdatedAt? new Date(date)> new Date(temp[strParts[0]].UpdatedAt) ?date:temp[strParts[0]].UpdatedAt:date
    // }
    return obj
  }

  const getLatestDate = (folder) => {
    const flattenObj = (folder) => {
      let result = [];
      for (const i in folder) {
        if ((typeof folder[i]) === 'object' && !Array.isArray(folder[i])) {
          const temp = flattenObj(folder[i]);
          for (const j in temp) {
              result.push(new Date(temp[j]));
          }
        }
        else {
            result.push(new Date(folder[i]));
        }
      }
      return result
    };
    return formatDate(new Date(Math.max(...flattenObj(folder))).toISOString())
  }

  const DeleteButton = ({ image }) => {
    const onClickHandler = () => {
      ImageService.deleteImage(image)
      .then(() => {
        if (onDelete) onDelete()
        AuditService.createAuditLog(`Deleted Sample Flow: ${image}`)
      })
      .catch(error => console.error(error))
      .finally(() => {})
    }
    return <Button negative onClick={onClickHandler}>Confirm Delete</Button>
  }

  const copyToClipboard = (copy) => {
    navigator.clipboard.writeText(copy)
    setCopy(copy)
    setTimeout(() => {
      setCopy(false)
    }, 3000)
  }

  const formatDate = isoDateString =>
  new Intl.DateTimeFormat('en-SG', {
    dateStyle: 'medium',
    timeStyle: 'short'
  }).format(new Date(isoDateString))

  const queryRows = (query) => {
    return query ? Object.keys(currentFolder).filter(path => path.toLowerCase().includes(query.toLowerCase())) : Object.keys(currentFolder)
  }
  
  return (
    <>
      <div className='mb-4 space-x-4'>
        <Input placeholder='Search...' id='searchFilter' icon='search' iconPosition='left' value={query} onChange={(e)=>{setQuery(e.target.value)}}/>
        <>
          {toolbar}
        </>
      </div>
      <Table striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>/{subTitle}</Table.HeaderCell>
            <Table.HeaderCell collapsing>Last Modified</Table.HeaderCell>
            <Table.HeaderCell collapsing>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {loading ?
          <Table.Body>
            <Table.Row>
              <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
              <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
              <Table.Cell><Placeholder fluid>&nbsp;</Placeholder></Table.Cell>
            </Table.Row>
          </Table.Body> :
          <Table.Body>
            {!_.isEqual(currentFolder, files)&&
              <Table.Row onClick={()=>{handleRowClick('back')}} className='cursor-pointer'>
                <Table.Cell colSpan={3}><Icon name='folder'/>..</Table.Cell>
              </Table.Row>
            }
            {currentFolder&&queryRows(query).map(x=>{
              return !x.includes('.')?
                x!='UpdatedAt'&& <Table.Row onClick={()=>{handleRowClick(x)}}  className='cursor-pointer'>
                  <Table.Cell><Icon name='folder' />{x}</Table.Cell>
                  <Table.Cell collapsing colSpan={1}>{currentFolder[x]&&getLatestDate(currentFolder[x])}</Table.Cell>
                  <Table.Cell/>
                </Table.Row>:
                <Table.Row selectable>
                  <Table.Cell selectable><a href={`${domain}/custom-content/${subTitle.toLowerCase()}${x}`} target='_blank'><Icon name='image' />{x}</a></Table.Cell>
                  <Table.Cell collapsing>{formatDate(currentFolder[x])}</Table.Cell>
                  <Table.Cell collapsing textAlign='center'>
                  <>
                      <Popup content='Copied' position='top center' open={copy === `<img style='object-fit:contain; max-width:100%; max-height:100%;' src='${domain}/custom-content/${subTitle.toLowerCase()}${x}'/>`} trigger={
                        <Button 
                          onClick={() => {
                            copyToClipboard(`<img style='object-fit:contain; max-width:100%; max-height:100%;' src='${domain}/custom-content/${subTitle.toLowerCase()}${x}'/>`)
                          }}
                          color='black'
                          icon='copy'>
                        </Button>
                      } />
                      {disableDelete ? 
                        null
                        :<Popup
                        trigger={
                          <Button negative icon='trash alternate'></Button>
                        }
                        content={<DeleteButton image={`/${subTitle.toLowerCase()}${x}`}></DeleteButton>}
                        on='click'
                        position='top center'
                        />
                      }
                  </>
                  </Table.Cell>
                </Table.Row>
              
            })}
          </Table.Body>
          }
      </Table>
  </>
  )
}

export default SampleFlows
